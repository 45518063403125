import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useState
} from 'react';
import {
  PermissionStatus,
  PushNotificationSchema,
  PushNotifications,
  Token
} from '@capacitor/push-notifications';
import { toast } from 'react-toastify';
import { DetailsToast } from 'src/components/toasts/details-toast';
import { CloudTokenState, useCloudToken } from 'src/hooks/use-cloud-token';

const registerNotifications = async (current?: PermissionStatus) => {
  if (current === undefined)
    current = await PushNotifications.checkPermissions();
  if (current.receive === 'prompt')
    current = await PushNotifications.requestPermissions();
  if (current.receive === 'granted') {
    // register for notifications
    await PushNotifications.register();
    // try to enable foreground notifications
    await PushNotifications.createChannel({
      id: 'fcm_default_channel',
      name: 'Default Notifications',
      importance: 5,
      visibility: 1,
      lights: true,
      vibration: true
    });
  }

  return current;
};

const notify = async (title: string, body: string) => {
  toast(
    <DetailsToast
      title={title}
      secondary={body}
    />
  );
};

type State = CloudTokenState & {
  type: 'capacitor';
  permitted: boolean;
  handleRequest?: () => Promise<any>;
  handleLogout?: () => Promise<any>;
  notify: (title: string, body: string) => Promise<any>;
};

const initialState: State = {
  type: 'capacitor',
  initialized: false,
  requested: false,
  permitted: false,
  failed: false,
  notify
};

export interface NotificationsContextType extends State {}

export const NotificationsContext = createContext<NotificationsContextType>({
  ...initialState
});

interface NotificationsProviderProps {}

export const NotificationsProvider: FC<
  PropsWithChildren<NotificationsProviderProps>
> = (props) => {
  const { children } = props;

  // store the current permission state
  const [permission, setPermission] = useState<PermissionStatus>();

  const handleNotification = useCallback(
    (notification: PushNotificationSchema) => {
      toast(
        <DetailsToast
          title={notification.title}
          secondary={notification.body}
        />,
        { position: 'bottom-center' }
      );
    },
    []
  );

  const handleRegister = useCallback(async () => {
    return new Promise<Token>((resolve, reject) => {
      // wait for token to be available
      PushNotifications.addListener('registration', resolve);
      PushNotifications.addListener('registrationError', reject);

      registerNotifications()
        .then((permission) => setPermission(permission))
        .catch(console.log);
    }).then((token) => {
      // reset token listeners
      PushNotifications.removeAllListeners();
      PushNotifications.addListener(
        'pushNotificationReceived',
        handleNotification
      );
      return token.value;
    });
  }, [handleNotification]);

  const handleUnregister = useCallback(async () => {
    await PushNotifications.unregister();
  }, []);

  const { state, handleRequest, handleLogout } = useCloudToken({
    onRegister: handleRegister,
    onUnregister: handleUnregister
  });

  return (
    <NotificationsContext.Provider
      value={{
        type: 'capacitor',
        ...state,
        permitted: permission?.receive === 'granted',
        handleRequest,
        handleLogout,
        notify
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
