import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';

interface GuestGuardProps {
  children: ReactNode;
}

export const GuestGuard: FC<GuestGuardProps> = (props) => {
  const { children } = props;
  const { isAuthenticated } = useAuth();
  const router = useRouter();
  const [checked, setChecked] = useState<boolean>(false);

  const check = useCallback(
    () => {
      if (isAuthenticated) {
        router.replace(paths.index);
      } else {
        setChecked(true);
      }
    },
    // TODO: there is no use to add dependencies here,
    // if the effect calling this is executed only once
    [isAuthenticated, router]
  );

  useEffect(
    () => check(),
    // only check on mount, other state changes will redirect manually
    // TODO: this might not be correct
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // waiting for authentication
  if (!checked) return null;

  // user is not authenticated
  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};
