import { type FC, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useGetApiAssociationInfoQuery } from 'src/api/deinverband-api';

interface SeoProps {
  title?: string;
}

export const Seo: FC<SeoProps> = (props) => {
  const { title } = props;

  const { data } = useGetApiAssociationInfoQuery();

  const fullTitle = useMemo(() => {
    const name = data?.name ?? 'innung.app';
    // append application name to title
    return title ? title + ' | ' + name : name;
  }, [title, data?.name]);

  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string
};
