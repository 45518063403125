export function getFullName(user: {
  firstName?: string;
  lastName?: string;
}): string;
export function getFullName(
  user:
    | {
        firstName?: string;
        lastName?: string;
      }
    | undefined,
  fallback?: boolean
): string | undefined;
export function getFullName(
  user:
    | {
        firstName?: string;
        lastName?: string;
      }
    | undefined,
  fallback = true
): string | undefined {
  if (!user) return undefined;

  const { firstName, lastName } = user;

  return firstName?.length && lastName?.length
    ? `${firstName} ${lastName}`
    : lastName?.length
    ? lastName
    : firstName?.length
    ? firstName
    : fallback
    ? 'Unbekannt'
    : undefined;
}
