export const paths = {
  index: '/',
  post: '/post',
  results: '/results',
  calendar: '/calendar',
  documents: '/documents',
  chat: '/chat',
  wiki: '/wiki',
  account: '/account',
  company: {
    user: '/company',
    admin: '/company/edit',
    employee: '/company/member'
  },
  content: {
    create: '/content/create',
    edit: '/content/edit',
    drafts: '/content/drafts',
    planned: '/content/planned'
  },
  admin: {
    manage: {
      index: '/admin/manage',
      group: '/admin/manage/group',
      company: '/admin/manage/company',
      member: '/admin/manage/member',
      list: '/admin/manage/list'
    },
    settings: {
      index: '/admin/settings',
      signature: '/admin/settings/signature',
      template: '/admin/settings/template'
    }
  },
  auth: {
    firebase: {
      login: '/auth/login',
      recover: '/auth/recover',
      reset: '/auth/reset',
      signup: '/auth/signup'
    }
  },
  terms: '/terms',
  privacy: '/privacy',
  401: '/401',
  404: '/404',
  500: '/500'
};
