import { emptyOpenPlzApi as api } from './empty-api';
export const addTagTypes = ['AT', 'CH', 'DE'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getAtDistrictsByKeyLocalities: build.query<
        GetAtDistrictsByKeyLocalitiesApiResponse,
        GetAtDistrictsByKeyLocalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/at/Districts/${queryArg.key}/Localities`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['AT']
      }),
      getAtDistrictsByKeyMunicipalities: build.query<
        GetAtDistrictsByKeyMunicipalitiesApiResponse,
        GetAtDistrictsByKeyMunicipalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/at/Districts/${queryArg.key}/Municipalities`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['AT']
      }),
      getAtFederalProvinces: build.query<
        GetAtFederalProvincesApiResponse,
        GetAtFederalProvincesApiArg
      >({
        query: () => ({ url: `/at/FederalProvinces` }),
        providesTags: ['AT']
      }),
      getAtFederalProvincesByKeyDistricts: build.query<
        GetAtFederalProvincesByKeyDistrictsApiResponse,
        GetAtFederalProvincesByKeyDistrictsApiArg
      >({
        query: (queryArg) => ({
          url: `/at/FederalProvinces/${queryArg.key}/Districts`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['AT']
      }),
      getAtFederalProvincesByKeyLocalities: build.query<
        GetAtFederalProvincesByKeyLocalitiesApiResponse,
        GetAtFederalProvincesByKeyLocalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/at/FederalProvinces/${queryArg.key}/Localities`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['AT']
      }),
      getAtFederalProvincesByKeyMunicipalities: build.query<
        GetAtFederalProvincesByKeyMunicipalitiesApiResponse,
        GetAtFederalProvincesByKeyMunicipalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/at/FederalProvinces/${queryArg.key}/Municipalities`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['AT']
      }),
      getAtLocalities: build.query<
        GetAtLocalitiesApiResponse,
        GetAtLocalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/at/Localities`,
          params: {
            postalCode: queryArg.postalCode,
            name: queryArg.name,
            page: queryArg.page,
            pageSize: queryArg.pageSize
          }
        }),
        providesTags: ['AT']
      }),
      getAtStreets: build.query<GetAtStreetsApiResponse, GetAtStreetsApiArg>({
        query: (queryArg) => ({
          url: `/at/Streets`,
          params: {
            name: queryArg.name,
            postalCode: queryArg.postalCode,
            locality: queryArg.locality,
            page: queryArg.page,
            pageSize: queryArg.pageSize
          }
        }),
        providesTags: ['AT']
      }),
      getChCantons: build.query<GetChCantonsApiResponse, GetChCantonsApiArg>({
        query: () => ({ url: `/ch/Cantons` }),
        providesTags: ['CH']
      }),
      getChCantonsByKeyCommunes: build.query<
        GetChCantonsByKeyCommunesApiResponse,
        GetChCantonsByKeyCommunesApiArg
      >({
        query: (queryArg) => ({
          url: `/ch/Cantons/${queryArg.key}/Communes`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['CH']
      }),
      getChCantonsByKeyDistricts: build.query<
        GetChCantonsByKeyDistrictsApiResponse,
        GetChCantonsByKeyDistrictsApiArg
      >({
        query: (queryArg) => ({
          url: `/ch/Cantons/${queryArg.key}/Districts`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['CH']
      }),
      getChCantonsByKeyLocalities: build.query<
        GetChCantonsByKeyLocalitiesApiResponse,
        GetChCantonsByKeyLocalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/ch/Cantons/${queryArg.key}/Localities`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['CH']
      }),
      getChDistrictsByKeyCommunes: build.query<
        GetChDistrictsByKeyCommunesApiResponse,
        GetChDistrictsByKeyCommunesApiArg
      >({
        query: (queryArg) => ({
          url: `/ch/Districts/${queryArg.key}/Communes`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['CH']
      }),
      getChDistrictsByKeyLocalities: build.query<
        GetChDistrictsByKeyLocalitiesApiResponse,
        GetChDistrictsByKeyLocalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/ch/Districts/${queryArg.key}/Localities`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['CH']
      }),
      getChLocalities: build.query<
        GetChLocalitiesApiResponse,
        GetChLocalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/ch/Localities`,
          params: {
            postalCode: queryArg.postalCode,
            name: queryArg.name,
            page: queryArg.page,
            pageSize: queryArg.pageSize
          }
        }),
        providesTags: ['CH']
      }),
      getChStreets: build.query<GetChStreetsApiResponse, GetChStreetsApiArg>({
        query: (queryArg) => ({
          url: `/ch/Streets`,
          params: {
            name: queryArg.name,
            postalCode: queryArg.postalCode,
            locality: queryArg.locality,
            page: queryArg.page,
            pageSize: queryArg.pageSize
          }
        }),
        providesTags: ['CH']
      }),
      getDeDistrictsByKeyLocalities: build.query<
        GetDeDistrictsByKeyLocalitiesApiResponse,
        GetDeDistrictsByKeyLocalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/de/Districts/${queryArg.key}/Localities`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['DE']
      }),
      getDeDistrictsByKeyMunicipalAssociations: build.query<
        GetDeDistrictsByKeyMunicipalAssociationsApiResponse,
        GetDeDistrictsByKeyMunicipalAssociationsApiArg
      >({
        query: (queryArg) => ({
          url: `/de/Districts/${queryArg.key}/MunicipalAssociations`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['DE']
      }),
      getDeDistrictsByKeyMunicipalities: build.query<
        GetDeDistrictsByKeyMunicipalitiesApiResponse,
        GetDeDistrictsByKeyMunicipalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/de/Districts/${queryArg.key}/Municipalities`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['DE']
      }),
      getDeFederalStates: build.query<
        GetDeFederalStatesApiResponse,
        GetDeFederalStatesApiArg
      >({
        query: () => ({ url: `/de/FederalStates` }),
        providesTags: ['DE']
      }),
      getDeFederalStatesByKeyDistricts: build.query<
        GetDeFederalStatesByKeyDistrictsApiResponse,
        GetDeFederalStatesByKeyDistrictsApiArg
      >({
        query: (queryArg) => ({
          url: `/de/FederalStates/${queryArg.key}/Districts`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['DE']
      }),
      getDeFederalStatesByKeyGovernmentRegions: build.query<
        GetDeFederalStatesByKeyGovernmentRegionsApiResponse,
        GetDeFederalStatesByKeyGovernmentRegionsApiArg
      >({
        query: (queryArg) => ({
          url: `/de/FederalStates/${queryArg.key}/GovernmentRegions`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['DE']
      }),
      getDeFederalStatesByKeyLocalities: build.query<
        GetDeFederalStatesByKeyLocalitiesApiResponse,
        GetDeFederalStatesByKeyLocalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/de/FederalStates/${queryArg.key}/Localities`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['DE']
      }),
      getDeFederalStatesByKeyMunicipalAssociations: build.query<
        GetDeFederalStatesByKeyMunicipalAssociationsApiResponse,
        GetDeFederalStatesByKeyMunicipalAssociationsApiArg
      >({
        query: (queryArg) => ({
          url: `/de/FederalStates/${queryArg.key}/MunicipalAssociations`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['DE']
      }),
      getDeFederalStatesByKeyMunicipalities: build.query<
        GetDeFederalStatesByKeyMunicipalitiesApiResponse,
        GetDeFederalStatesByKeyMunicipalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/de/FederalStates/${queryArg.key}/Municipalities`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['DE']
      }),
      getDeGovernmentRegionsByKeyDistricts: build.query<
        GetDeGovernmentRegionsByKeyDistrictsApiResponse,
        GetDeGovernmentRegionsByKeyDistrictsApiArg
      >({
        query: (queryArg) => ({
          url: `/de/GovernmentRegions/${queryArg.key}/Districts`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['DE']
      }),
      getDeGovernmentRegionsByKeyLocalities: build.query<
        GetDeGovernmentRegionsByKeyLocalitiesApiResponse,
        GetDeGovernmentRegionsByKeyLocalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/de/GovernmentRegions/${queryArg.key}/Localities`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['DE']
      }),
      getDeGovernmentRegionsByKeyMunicipalAssociations: build.query<
        GetDeGovernmentRegionsByKeyMunicipalAssociationsApiResponse,
        GetDeGovernmentRegionsByKeyMunicipalAssociationsApiArg
      >({
        query: (queryArg) => ({
          url: `/de/GovernmentRegions/${queryArg.key}/MunicipalAssociations`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['DE']
      }),
      getDeGovernmentRegionsByKeyMunicipalities: build.query<
        GetDeGovernmentRegionsByKeyMunicipalitiesApiResponse,
        GetDeGovernmentRegionsByKeyMunicipalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/de/GovernmentRegions/${queryArg.key}/Municipalities`,
          params: { page: queryArg.page, pageSize: queryArg.pageSize }
        }),
        providesTags: ['DE']
      }),
      getDeLocalities: build.query<
        GetDeLocalitiesApiResponse,
        GetDeLocalitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/de/Localities`,
          params: {
            postalCode: queryArg.postalCode,
            name: queryArg.name,
            page: queryArg.page,
            pageSize: queryArg.pageSize
          }
        }),
        providesTags: ['DE']
      }),
      getDeStreets: build.query<GetDeStreetsApiResponse, GetDeStreetsApiArg>({
        query: (queryArg) => ({
          url: `/de/Streets`,
          params: {
            name: queryArg.name,
            postalCode: queryArg.postalCode,
            locality: queryArg.locality,
            page: queryArg.page,
            pageSize: queryArg.pageSize
          }
        }),
        providesTags: ['DE']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as openPlzApi };
export type GetAtDistrictsByKeyLocalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiAtLocalityResponseRead[];
export type GetAtDistrictsByKeyLocalitiesApiArg = {
  /** Regional key of the district */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetAtDistrictsByKeyMunicipalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiAtMunicipalityResponseRead[];
export type GetAtDistrictsByKeyMunicipalitiesApiArg = {
  /** Key of the district */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetAtFederalProvincesApiResponse =
  /** status 200 Success */ OpenPlzApiAtFederalProvinceResponseRead[];
export type GetAtFederalProvincesApiArg = void;
export type GetAtFederalProvincesByKeyDistrictsApiResponse =
  /** status 200 Success */ OpenPlzApiAtDistrictResponseRead[];
export type GetAtFederalProvincesByKeyDistrictsApiArg = {
  /** Key of the federal province */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetAtFederalProvincesByKeyLocalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiAtLocalityResponseRead[];
export type GetAtFederalProvincesByKeyLocalitiesApiArg = {
  /** Regional key of the federal province */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetAtFederalProvincesByKeyMunicipalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiAtMunicipalityResponseRead[];
export type GetAtFederalProvincesByKeyMunicipalitiesApiArg = {
  /** Key of the federal province */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetAtLocalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiAtLocalityResponseRead[];
export type GetAtLocalitiesApiArg = {
  /** Postal code or regular expression */
  postalCode?: string;
  /** Name or regular expression */
  name?: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetAtStreetsApiResponse =
  /** status 200 Success */ OpenPlzApiAtStreetResponseRead[];
export type GetAtStreetsApiArg = {
  /** Name or regular expression */
  name?: string;
  /** Postal code or regular expression */
  postalCode?: string;
  /** Locality or regular expression */
  locality?: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetChCantonsApiResponse =
  /** status 200 Success */ OpenPlzApiChCantonResponseRead[];
export type GetChCantonsApiArg = void;
export type GetChCantonsByKeyCommunesApiResponse =
  /** status 200 Success */ OpenPlzApiChCommuneResponseRead[];
export type GetChCantonsByKeyCommunesApiArg = {
  /** Key of the canton */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetChCantonsByKeyDistrictsApiResponse =
  /** status 200 Success */ OpenPlzApiChDistrictResponseRead[];
export type GetChCantonsByKeyDistrictsApiArg = {
  /** Key of the canton */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetChCantonsByKeyLocalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiChLocalityResponseRead[];
export type GetChCantonsByKeyLocalitiesApiArg = {
  /** Regional key of the canton */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetChDistrictsByKeyCommunesApiResponse =
  /** status 200 Success */ OpenPlzApiChCommuneResponseRead[];
export type GetChDistrictsByKeyCommunesApiArg = {
  /** Key of the district */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetChDistrictsByKeyLocalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiChLocalityResponseRead[];
export type GetChDistrictsByKeyLocalitiesApiArg = {
  /** Regional key of the district */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetChLocalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiChLocalityResponseRead[];
export type GetChLocalitiesApiArg = {
  /** Postal code or regular expression */
  postalCode?: string;
  /** Name or regular expression */
  name?: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetChStreetsApiResponse =
  /** status 200 Success */ OpenPlzApiChStreetResponseRead[];
export type GetChStreetsApiArg = {
  /** Name or regular expression */
  name?: string;
  /** Postal code or regular expression */
  postalCode?: string;
  /** Locality or regular expression */
  locality?: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeDistrictsByKeyLocalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiDeLocalityResponseRead[];
export type GetDeDistrictsByKeyLocalitiesApiArg = {
  /** Regional key of the district */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeDistrictsByKeyMunicipalAssociationsApiResponse =
  /** status 200 Success */ OpenPlzApiDeMunicipalAssociationResponseRead[];
export type GetDeDistrictsByKeyMunicipalAssociationsApiArg = {
  /** Regional key of the district */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeDistrictsByKeyMunicipalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiDeMunicipalityResponseRead[];
export type GetDeDistrictsByKeyMunicipalitiesApiArg = {
  /** Regional key of the district */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeFederalStatesApiResponse =
  /** status 200 Success */ OpenPlzApiDeFederalStateResponseRead[];
export type GetDeFederalStatesApiArg = void;
export type GetDeFederalStatesByKeyDistrictsApiResponse =
  /** status 200 Success */ OpenPlzApiDeDistrictResponseRead[];
export type GetDeFederalStatesByKeyDistrictsApiArg = {
  /** Regional key of the federal state */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeFederalStatesByKeyGovernmentRegionsApiResponse =
  /** status 200 Success */ OpenPlzApiDeGovernmentRegionResponseRead[];
export type GetDeFederalStatesByKeyGovernmentRegionsApiArg = {
  /** Regional key of the federal state */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeFederalStatesByKeyLocalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiDeLocalityResponseRead[];
export type GetDeFederalStatesByKeyLocalitiesApiArg = {
  /** Regional key of the federal state */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeFederalStatesByKeyMunicipalAssociationsApiResponse =
  /** status 200 Success */ OpenPlzApiDeMunicipalAssociationResponseRead[];
export type GetDeFederalStatesByKeyMunicipalAssociationsApiArg = {
  /** Regional key of the federal state */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeFederalStatesByKeyMunicipalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiDeMunicipalityResponseRead[];
export type GetDeFederalStatesByKeyMunicipalitiesApiArg = {
  /** Regional key of the federal state */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeGovernmentRegionsByKeyDistrictsApiResponse =
  /** status 200 Success */ OpenPlzApiDeDistrictResponseRead[];
export type GetDeGovernmentRegionsByKeyDistrictsApiArg = {
  /** Regional key of the government region */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeGovernmentRegionsByKeyLocalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiDeLocalityResponseRead[];
export type GetDeGovernmentRegionsByKeyLocalitiesApiArg = {
  /** Regional key of the government region */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeGovernmentRegionsByKeyMunicipalAssociationsApiResponse =
  /** status 200 Success */ OpenPlzApiDeMunicipalAssociationResponseRead[];
export type GetDeGovernmentRegionsByKeyMunicipalAssociationsApiArg = {
  /** Regional key of the government region */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeGovernmentRegionsByKeyMunicipalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiDeMunicipalityResponseRead[];
export type GetDeGovernmentRegionsByKeyMunicipalitiesApiArg = {
  /** Regional key of the government region */
  key: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeLocalitiesApiResponse =
  /** status 200 Success */ OpenPlzApiDeLocalityResponseRead[];
export type GetDeLocalitiesApiArg = {
  /** Postal code or regular expression */
  postalCode?: string;
  /** Name or regular expression */
  name?: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type GetDeStreetsApiResponse =
  /** status 200 Success */ OpenPlzApiDeStreetResponseRead[];
export type GetDeStreetsApiArg = {
  /** Name or regular expression */
  name?: string;
  /** Postal code or regular expression */
  postalCode?: string;
  /** Locality or regular expression */
  locality?: string;
  /** Page number (starting with 1) */
  page?: number;
  /** Page size (maximum 50) */
  pageSize?: number;
};
export type OpenPlzApiAtDistrictSummary = {};
export type OpenPlzApiAtDistrictSummaryRead = {
  code: string;
  key: string;
  name: string;
};
export type OpenPlzApiAtFederalProvinceSummary = {};
export type OpenPlzApiAtFederalProvinceSummaryRead = {
  key: string;
  name: string;
};
export type OpenPlzApiAtMunicipalityStatus =
  | 'None'
  | 'TownWithCharter'
  | 'UrbanMunicipality'
  | 'MarketTown';
export type OpenPlzApiAtMunicipalityStatusRead =
  | 'None'
  | 'TownWithCharter'
  | 'UrbanMunicipality'
  | 'MarketTown';
export type OpenPlzApiAtMunicipalitySummary = {
  status: OpenPlzApiAtMunicipalityStatus;
};
export type OpenPlzApiAtMunicipalitySummaryRead = {
  code: string;
  key: string;
  name: string;
  status: OpenPlzApiAtMunicipalityStatusRead;
};
export type OpenPlzApiAtLocalityResponse = {
  district: OpenPlzApiAtDistrictSummary;
  federalProvince: OpenPlzApiAtFederalProvinceSummary;
  municipality: OpenPlzApiAtMunicipalitySummary;
};
export type OpenPlzApiAtLocalityResponseRead = {
  district: OpenPlzApiAtDistrictSummaryRead;
  federalProvince: OpenPlzApiAtFederalProvinceSummaryRead;
  key: string;
  municipality: OpenPlzApiAtMunicipalitySummaryRead;
  name: string;
  postalCode: string;
};
export type OpenPlzApiAtMunicipalityResponse = {
  district: OpenPlzApiAtDistrictSummary;
  federalProvince: OpenPlzApiAtFederalProvinceSummary;
  status: OpenPlzApiAtMunicipalityStatus;
};
export type OpenPlzApiAtMunicipalityResponseRead = {
  code: string;
  district: OpenPlzApiAtDistrictSummaryRead;
  federalProvince: OpenPlzApiAtFederalProvinceSummaryRead;
  key: string;
  multiplePostalCodes: boolean;
  name: string;
  postalCode: string;
  status: OpenPlzApiAtMunicipalityStatusRead;
};
export type OpenPlzApiAtFederalProvinceResponse = {};
export type OpenPlzApiAtFederalProvinceResponseRead = {
  key: string;
  name: string;
};
export type OpenPlzApiAtDistrictResponse = {
  federalProvince: OpenPlzApiAtFederalProvinceSummary;
};
export type OpenPlzApiAtDistrictResponseRead = {
  code: string;
  federalProvince: OpenPlzApiAtFederalProvinceSummaryRead;
  key: string;
  name: string;
};
export type OpenPlzApiAtStreetResponse = {
  district: OpenPlzApiAtDistrictSummary;
  federalProvince: OpenPlzApiAtFederalProvinceSummary;
  municipality: OpenPlzApiAtMunicipalitySummary;
};
export type OpenPlzApiAtStreetResponseRead = {
  district: OpenPlzApiAtDistrictSummaryRead;
  federalProvince: OpenPlzApiAtFederalProvinceSummaryRead;
  key: string;
  locality: string;
  municipality: OpenPlzApiAtMunicipalitySummaryRead;
  name: string;
  postalCode: string;
};
export type OpenPlzApiChCantonResponse = {};
export type OpenPlzApiChCantonResponseRead = {
  code: string;
  key: string;
  name: string;
};
export type OpenPlzApiChCantonSummary = {};
export type OpenPlzApiChCantonSummaryRead = {
  code: string;
  key: string;
  name: string;
};
export type OpenPlzApiChDistrictSummary = {};
export type OpenPlzApiChDistrictSummaryRead = {
  key: string;
  name: string;
};
export type OpenPlzApiChCommuneResponse = {
  canton: OpenPlzApiChCantonSummary;
  district: OpenPlzApiChDistrictSummary;
};
export type OpenPlzApiChCommuneResponseRead = {
  canton: OpenPlzApiChCantonSummaryRead;
  district: OpenPlzApiChDistrictSummaryRead;
  key: string;
  name: string;
  shortName: string;
};
export type OpenPlzApiChDistrictResponse = {
  canton: OpenPlzApiChCantonSummary;
};
export type OpenPlzApiChDistrictResponseRead = {
  canton: OpenPlzApiChCantonSummaryRead;
  key: string;
  name: string;
};
export type OpenPlzApiChCommuneSummary = {};
export type OpenPlzApiChCommuneSummaryRead = {
  key: string;
  name: string;
  shortName: string;
};
export type OpenPlzApiChLocalityResponse = {
  canton: OpenPlzApiChCantonSummary;
  commune: OpenPlzApiChCommuneSummary;
  district: OpenPlzApiChDistrictSummary;
};
export type OpenPlzApiChLocalityResponseRead = {
  canton: OpenPlzApiChCantonSummaryRead;
  commune: OpenPlzApiChCommuneSummaryRead;
  district: OpenPlzApiChDistrictSummaryRead;
  name: string;
  postalCode: string;
};
export type OpenPlzApiChStreetStatus = 'None' | 'Planned' | 'Real' | 'Outdated';
export type OpenPlzApiChStreetStatusRead =
  | 'None'
  | 'Planned'
  | 'Real'
  | 'Outdated';
export type OpenPlzApiChStreetResponse = {
  canton: OpenPlzApiChCantonSummary;
  commune: OpenPlzApiChCommuneSummary;
  district: OpenPlzApiChDistrictSummary;
  status: OpenPlzApiChStreetStatus;
};
export type OpenPlzApiChStreetResponseRead = {
  canton: OpenPlzApiChCantonSummaryRead;
  commune: OpenPlzApiChCommuneSummaryRead;
  district: OpenPlzApiChDistrictSummaryRead;
  key: string;
  locality: string;
  name: string;
  postalCode: string;
  status: OpenPlzApiChStreetStatusRead;
};
export type OpenPlzApiDeDistrictType =
  | 'None'
  | 'Kreisfreie_Stadt'
  | 'Stadtkreis'
  | 'Kreis'
  | 'Landkreis'
  | 'Regionalverband';
export type OpenPlzApiDeDistrictTypeRead =
  | 'None'
  | 'Kreisfreie_Stadt'
  | 'Stadtkreis'
  | 'Kreis'
  | 'Landkreis'
  | 'Regionalverband';
export type OpenPlzApiDeDistrictSummary = {
  type: OpenPlzApiDeDistrictType;
};
export type OpenPlzApiDeDistrictSummaryRead = {
  key: string;
  name: string;
  type: OpenPlzApiDeDistrictTypeRead;
};
export type OpenPlzApiDeFederalStateSummary = {};
export type OpenPlzApiDeFederalStateSummaryRead = {
  key: string;
  name: string;
};
export type OpenPlzApiDeMunicipalityType =
  | 'None'
  | 'Markt'
  | 'Kreisfreie_Stadt'
  | 'Stadtkreis'
  | 'Stadt'
  | 'Kreisangeh\u00F6rige_Gemeinde'
  | 'Gemeindefreies_Gebiet_Bewohnt'
  | 'Gemeindefreies_Gebiet_Unbewohnt'
  | 'Gro\u00DFe_Kreisstadt';
export type OpenPlzApiDeMunicipalityTypeRead =
  | 'None'
  | 'Markt'
  | 'Kreisfreie_Stadt'
  | 'Stadtkreis'
  | 'Stadt'
  | 'Kreisangeh\u00F6rige_Gemeinde'
  | 'Gemeindefreies_Gebiet_Bewohnt'
  | 'Gemeindefreies_Gebiet_Unbewohnt'
  | 'Gro\u00DFe_Kreisstadt';
export type OpenPlzApiDeMunicipalitySummary = {
  type: OpenPlzApiDeMunicipalityType;
};
export type OpenPlzApiDeMunicipalitySummaryRead = {
  key: string;
  name: string;
  type: OpenPlzApiDeMunicipalityTypeRead;
};
export type OpenPlzApiDeLocalityResponse = {
  district: OpenPlzApiDeDistrictSummary;
  federalState: OpenPlzApiDeFederalStateSummary;
  municipality: OpenPlzApiDeMunicipalitySummary;
};
export type OpenPlzApiDeLocalityResponseRead = {
  district: OpenPlzApiDeDistrictSummaryRead;
  federalState: OpenPlzApiDeFederalStateSummaryRead;
  municipality: OpenPlzApiDeMunicipalitySummaryRead;
  name: string;
  postalCode: string;
};
export type OpenPlzApiDeMunicipalAssociationType =
  | 'None'
  | 'Verbandsfreie_Gemeinde'
  | 'Amt'
  | 'Samtgemeinde'
  | 'Verbandsgemeinde'
  | 'Verwaltungsgemeinschaft'
  | 'Kirchspielslandgemeinde'
  | 'Verwaltungsverband'
  | 'VG_Traegermodell'
  | 'Erf\u00FCllende_Gemeinde';
export type OpenPlzApiDeMunicipalAssociationTypeRead =
  | 'None'
  | 'Verbandsfreie_Gemeinde'
  | 'Amt'
  | 'Samtgemeinde'
  | 'Verbandsgemeinde'
  | 'Verwaltungsgemeinschaft'
  | 'Kirchspielslandgemeinde'
  | 'Verwaltungsverband'
  | 'VG_Traegermodell'
  | 'Erf\u00FCllende_Gemeinde';
export type OpenPlzApiDeMunicipalAssociationResponse = {
  district: OpenPlzApiDeDistrictSummary;
  federalState: OpenPlzApiDeFederalStateSummary;
  type: OpenPlzApiDeMunicipalAssociationType;
};
export type OpenPlzApiDeMunicipalAssociationResponseRead = {
  administrativeHeadquarters: string;
  district: OpenPlzApiDeDistrictSummaryRead;
  federalState: OpenPlzApiDeFederalStateSummaryRead;
  key: string;
  name: string;
  type: OpenPlzApiDeMunicipalAssociationTypeRead;
};
export type OpenPlzApiDeMunicipalAssociationSummary = {
  type: OpenPlzApiDeMunicipalAssociationType;
};
export type OpenPlzApiDeMunicipalAssociationSummaryRead = {
  key: string;
  name: string;
  type: OpenPlzApiDeMunicipalAssociationTypeRead;
};
export type OpenPlzApiDeGovernmentRegionSummary = {};
export type OpenPlzApiDeGovernmentRegionSummaryRead = {
  key: string;
  name: string;
};
export type OpenPlzApiDeMunicipalityResponse = {
  association?: OpenPlzApiDeMunicipalAssociationSummary;
  district: OpenPlzApiDeDistrictSummary;
  federalState: OpenPlzApiDeFederalStateSummary;
  governmentRegion: OpenPlzApiDeGovernmentRegionSummary;
  type: OpenPlzApiDeMunicipalityType;
};
export type OpenPlzApiDeMunicipalityResponseRead = {
  association?: OpenPlzApiDeMunicipalAssociationSummaryRead;
  district: OpenPlzApiDeDistrictSummaryRead;
  federalState: OpenPlzApiDeFederalStateSummaryRead;
  governmentRegion: OpenPlzApiDeGovernmentRegionSummaryRead;
  key: string;
  multiplePostalCodes: boolean;
  name: string;
  postalCode: string;
  type: OpenPlzApiDeMunicipalityTypeRead;
};
export type OpenPlzApiDeFederalStateResponse = {};
export type OpenPlzApiDeFederalStateResponseRead = {
  key: string;
  name: string;
  seatOfGovernment: string;
};
export type OpenPlzApiDeDistrictResponse = {
  federalState: OpenPlzApiDeFederalStateSummary;
  governmentRegion?: OpenPlzApiDeGovernmentRegionSummary;
  type: OpenPlzApiDeDistrictType;
};
export type OpenPlzApiDeDistrictResponseRead = {
  administrativeHeadquarters: string;
  federalState: OpenPlzApiDeFederalStateSummaryRead;
  governmentRegion?: OpenPlzApiDeGovernmentRegionSummaryRead;
  key: string;
  name: string;
  type: OpenPlzApiDeDistrictTypeRead;
};
export type OpenPlzApiDeGovernmentRegionResponse = {
  federalState: OpenPlzApiDeFederalStateSummary;
};
export type OpenPlzApiDeGovernmentRegionResponseRead = {
  administrativeHeadquarters: string;
  federalState: OpenPlzApiDeFederalStateSummaryRead;
  key: string;
  name: string;
};
export type OpenPlzApiDeStreetResponse = {
  district: OpenPlzApiDeDistrictSummary;
  federalState: OpenPlzApiDeFederalStateSummary;
  municipality: OpenPlzApiDeMunicipalitySummary;
};
export type OpenPlzApiDeStreetResponseRead = {
  district: OpenPlzApiDeDistrictSummaryRead;
  federalState: OpenPlzApiDeFederalStateSummaryRead;
  locality: string;
  municipality: OpenPlzApiDeMunicipalitySummaryRead;
  name: string;
  postalCode: string;
};
export const {
  useGetAtDistrictsByKeyLocalitiesQuery,
  useGetAtDistrictsByKeyMunicipalitiesQuery,
  useGetAtFederalProvincesQuery,
  useGetAtFederalProvincesByKeyDistrictsQuery,
  useGetAtFederalProvincesByKeyLocalitiesQuery,
  useGetAtFederalProvincesByKeyMunicipalitiesQuery,
  useGetAtLocalitiesQuery,
  useGetAtStreetsQuery,
  useGetChCantonsQuery,
  useGetChCantonsByKeyCommunesQuery,
  useGetChCantonsByKeyDistrictsQuery,
  useGetChCantonsByKeyLocalitiesQuery,
  useGetChDistrictsByKeyCommunesQuery,
  useGetChDistrictsByKeyLocalitiesQuery,
  useGetChLocalitiesQuery,
  useGetChStreetsQuery,
  useGetDeDistrictsByKeyLocalitiesQuery,
  useGetDeDistrictsByKeyMunicipalAssociationsQuery,
  useGetDeDistrictsByKeyMunicipalitiesQuery,
  useGetDeFederalStatesQuery,
  useGetDeFederalStatesByKeyDistrictsQuery,
  useGetDeFederalStatesByKeyGovernmentRegionsQuery,
  useGetDeFederalStatesByKeyLocalitiesQuery,
  useGetDeFederalStatesByKeyMunicipalAssociationsQuery,
  useGetDeFederalStatesByKeyMunicipalitiesQuery,
  useGetDeGovernmentRegionsByKeyDistrictsQuery,
  useGetDeGovernmentRegionsByKeyLocalitiesQuery,
  useGetDeGovernmentRegionsByKeyMunicipalAssociationsQuery,
  useGetDeGovernmentRegionsByKeyMunicipalitiesQuery,
  useGetDeLocalitiesQuery,
  useGetDeStreetsQuery
} = injectedRtkApi;
