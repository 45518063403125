export const gtmConfig = {
  containerId: import.meta.env.VITE_GTM_CONTAINER_ID
};

export const googleConfig = {
  apiKey: import.meta.env.VITE_GOOGLE_API_KEY
};

export const getStreamConfig = {
  apiKey: import.meta.env.VITE_GETSTREAM_API_KEY
};

export const appConfig = {
  appVersion: import.meta.env.VITE_APP_VERSION,
  baseUrl: import.meta.env.VITE_BASE_URL,
  devTools: import.meta.env.VITE_ENABLE_REDUX_DEV_TOOLS
};
