import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { withAuthGuard } from 'src/hocs/with-auth-guard';
import { useSections } from './config';
import { VerticalLayout } from './vertical-layout';

interface LayoutProps {
  direction?: 'vertical' | 'horizontal';
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
  const { direction, ...other } = props;
  const sections = useSections();
  /*
  if (direction === 'horizontal')
    return (
      <HorizontalLayout
        navColor="discreet"
        sections={sections}
        {...other}
      />
    );
*/
  // use vertical layout by default
  return (
    <VerticalLayout
      navColor="discreet"
      sections={sections}
      {...other}
    />
  );
});

Layout.propTypes = {
  children: PropTypes.node
};
