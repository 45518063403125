import { CapacitorHttp, HttpResponseType } from '@capacitor/core';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { getStreamConfig } from 'src/config';
import { firebaseGetAuth } from 'src/libs/firebase';

const defaultHeaders = {
  Accept: '*/*',
  'Content-Type': 'application/json'
};

/**
 * RTK base query which uses capacitor HTTP to abstract fetch.
 *
 * This allows to use native request APIs on non-web platforms,
 * and therefore enables cross-origin requests.
 */
export const capacitorBaseQuery =
  (
    {
      baseUrl,
      enableFirebaseAuth,
      enableGetStreamAuth
    }: {
      baseUrl: string;
      enableFirebaseAuth?: boolean;
      enableGetStreamAuth?: boolean;
    } = {
      baseUrl: '',
      enableFirebaseAuth: false,
      enableGetStreamAuth: false
    }
  ): BaseQueryFn<
    {
      url: string;
      method?: string;
      body?: any;
      params?: { [key: string]: any };
      headers?: { [key: string]: string };
      token?: string;
    },
    unknown,
    unknown,
    { responseType?: HttpResponseType } | undefined
  > =>
  async (
    { url, method, body, params, headers = {}, token },
    api,
    extraOptions
  ) => {
    if (enableFirebaseAuth) {
      const auth = firebaseGetAuth();
      const apiKey = await auth.currentUser?.getIdToken();
      // attach the  authentication token
      if (apiKey) headers = { ...headers, Authorization: `Bearer ${apiKey}` };
    }

    if (enableGetStreamAuth) {
      // inject the API key
      params = { ...params, api_key: getStreamConfig.apiKey };
      // attach the authentication token
      if (token)
        headers = {
          ...headers,
          Authorization: token,
          'Stream-Auth-Type': 'jwt'
        };
    }

    const result = await CapacitorHttp.request({
      url: baseUrl + url,
      method,
      data: body,
      params:
        params &&
        Object.fromEntries(
          // remove `undefined` fields
          Object.entries(params).filter(([key, value]) => value !== undefined)
        ),
      headers: { ...defaultHeaders, ...headers },
      responseType: extraOptions?.responseType
    });

    if (result.status >= 200 && result.status < 400)
      return { ...result, meta: result };
    else return { error: result };
  };
