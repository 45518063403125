import React, { FC, PropsWithChildren, useCallback } from 'react';
import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarCloseReason,
  SnackbarProps
} from '@mui/material';

type SimpleSnackbarProps = {
  severity?: AlertColor;
  onClose?: () => void;
  disableClickAway?: boolean;
} & Omit<SnackbarProps, 'onClose' | 'action' | 'children'>;

export const SimpleSnackbar: FC<PropsWithChildren<SimpleSnackbarProps>> = (
  props
) => {
  const {
    severity,
    onClose,
    disableClickAway,
    autoHideDuration = 5000,
    anchorOrigin = { horizontal: 'center', vertical: 'top' },
    children,
    ...other
  } = props;

  const handleClose = useCallback(
    (event: any, reason: SnackbarCloseReason) => {
      if (reason === 'clickaway' && disableClickAway) return;
      // forward to default close handler
      onClose?.();
    },
    [onClose, disableClickAway]
  );

  return (
    <Snackbar
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      {...other}
    >
      <Alert
        severity={severity}
        onClose={onClose}
      >
        {children}
      </Alert>
    </Snackbar>
  );
};
