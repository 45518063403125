import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { matchRoutes } from 'react-router';
import { createRoutesFromChildren, useLocation, useNavigationType } from 'react-router-dom';
import versionInfo from '../utils/version';

Sentry.init({
  dsn: 'https://fb8260cc7990f4df70a666fc6366e9e4@o4507073789362176.ingest.de.sentry.io/4507604737589328',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      showBranding: false,
      colorScheme: 'system',
      buttonLabel: 'Fehler melden',
      submitButtonLabel: 'Fehlermeldung absenden',
      cancelButtonLabel: 'Abbrechen',
      formTitle: 'Fehler melden',
      nameLabel: 'Name',
      namePlaceholder: 'Ihr Name',
      emailLabel: 'E-Mail',
      emailPlaceholder: 'ihre.email-adresse@beispiel.de',
      messageLabel: 'Beschreibung',
      messagePlaceholder: 'Was haben Sie erwartet und was ist stattdessen passiert? Was führte dazu?',
      successMessageText: 'Vielen Dank für Ihre Fehlermeldung. Wir werden uns darum kümmern.',
      isRequiredLabel: '(Pflichtfeld)',
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^\//, 'localhost'],
  // Session Replay
  replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Called for message and error events
  environment: import.meta.env.MODE,
  beforeSend(event) {
    // Modify or drop the event here
    if (event.user) {
      // Don't send user's personal data
      delete event.user.email;
      delete event.user.username;
      delete event.user.ip_address;
    }
    return event;
  },
});

Sentry.setTag('appVersion', versionInfo.version);
Sentry.setTag('commitHash', versionInfo.commitHash);
Sentry.setTag('environment', import.meta.env.MODE);
