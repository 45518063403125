import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SearchState } from 'src/hooks/use-search';

interface State {
  searchStates: {
    [key: string]: SearchState<any>;
  };
}

const initialState: State = {
  searchStates: {}
};

export const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    initializeSearchState(
      state,
      action: PayloadAction<{
        id: string;
        initial: SearchState<any>;
      }>
    ) {
      state.searchStates[action.payload.id] = action.payload.initial;
    },
    updateSearchState(
      state,
      action: PayloadAction<{
        id: string;
        update: Partial<SearchState<any>>;
      }>
    ) {
      const current = state.searchStates[action.payload.id];
      // apply partial update
      state.searchStates[action.payload.id] = {
        ...current,
        ...action.payload.update
      };
    }
  }
});

export const { reducer } = slice;
