import { createApi } from '@reduxjs/toolkit/query/react';
import { capacitorBaseQuery } from './capacitor-adapter';

export interface GetStreamAuth {
  uid: string;
  token: string;
}

export interface Message {
  text?: string;
}

export interface Channel {
  id?: string;
  name?: string;
  last_message_at?: string;
}

export interface ChannelWithMessages {
  channel: Channel;
  messages: Message[];
}

/**
 * GetStream API to avoid websocket connection
 *
 * Once the websocket connection is established, the user will count as active.
 * The number of active users must be kept low.
 */
export const getStreamApi = createApi({
  reducerPath: 'getStreamApi',
  baseQuery: capacitorBaseQuery({
    baseUrl: 'https://chat.stream-io-api.com',
    enableGetStreamAuth: true
  }),
  endpoints: (build) => ({
    getChannels: build.query<ChannelWithMessages[], { auth: GetStreamAuth }>({
      query: ({ auth }) => ({
        url: '/channels',
        method: 'POST',
        body: {
          filter_conditions: { members: { $in: [auth.uid] } },
          sort: [{ field: 'last_message_at', direction: -1 }]
        },
        token: auth.token
      }),
      transformResponse: (response: { channels: ChannelWithMessages[] }) =>
        response.channels
    })
  })
});

export const { useGetChannelsQuery } = getStreamApi;
