import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'src/components/loader';
import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { Issuer } from 'src/utils/auth';

const loginPaths: Record<Issuer, string> = {
  [Issuer.Firebase]: paths.auth.firebase.login
};

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const { isAuthenticated, issuer } = useAuth();
  const [checked, setChecked] = useState(false);

  const check = useCallback(
    () => {
      if (!isAuthenticated) {
        const searchParams = new URLSearchParams({
          returnTo: window.location.href
        }).toString();
        const href = loginPaths[issuer] + `?${searchParams}`;
        router.replace(href);
      } else {
        setChecked(true);
      }
    },
    // TODO: there is no use to add dependencies here,
    // if the effect calling this is executed only once
    [isAuthenticated, issuer, router]
  );

  useEffect(
    () => check(),
    // only check on mount, other state changes will redirect manually
    // TODO: this might not be correct
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // waiting for authentication
  if (!checked) return <Loader />;

  // user is authenticated
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};
