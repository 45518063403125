import type { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useGetApiAssociationInfoQuery } from 'src/api/deinverband-api';
import { InlineIcon } from './inline-icon';

export const DemoBanner: FC = () => {
  const theme = useTheme();

  const { data } = useGetApiAssociationInfoQuery();

  if (data?.demoMode)
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
          justifyContent: 'center',
          display: 'flex',
          // overlap menu
          zIndex: 9999
        }}
      >
        <Typography variant="overline">
          <InlineIcon icon="fa6-solid:helmet-safety" />
          Demo Mode
        </Typography>
      </Box>
    );

  return null;
};
