import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Layout as AuthLayout } from 'src/layouts/auth/classic-layout';
import Error401Page from 'src/pages/401';
import Error404Page from 'src/pages/404';
import Error500Page from 'src/pages/500';
import { adminRoutes } from './admin';
import { authRoutes } from './auth';
import { userRoutes } from './user';

// eslint-disable-next-line react-refresh/only-export-components
const TermsOfServicePage = lazy(() => import('src/pages/auth/firebase/terms'));
// eslint-disable-next-line react-refresh/only-export-components
const PrivacyPolicyPage = lazy(() => import('src/pages/auth/firebase/privacy'));

export const routes: RouteObject[] = [
  ...authRoutes,
  ...userRoutes,
  ...adminRoutes,
  {
    path: 'terms',
    element: (
      <AuthLayout>
        <TermsOfServicePage />
      </AuthLayout>
    )
  },
  {
    path: 'privacy',
    element: (
      <AuthLayout>
        <PrivacyPolicyPage />
      </AuthLayout>
    )
  },
  {
    path: '401',
    element: <Error401Page />
  },
  {
    path: '404',
    element: <Error404Page />
  },
  {
    path: '500',
    element: <Error500Page />
  },
  {
    path: '*',
    element: <Error404Page />
  }
];
