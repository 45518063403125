import { emptyDeinVerbandApi as api } from './empty-api';
export const addTagTypes = [
  'Access Attribute Resource',
  'Association Resource',
  'Chat Resource',
  'Company Resource',
  'Craft Resource',
  'Document Resource',
  'Email Signature Resource',
  'Email Template Resource',
  'Event Resource',
  'Fruit Resource',
  'Guild Resource',
  'Example Resource',
  'Image Resource',
  'User List Resource',
  'Policy Resource',
  'Post Resource',
  'Role Resource',
  'Abac Cache Resource',
  'Create Association Resource',
  'Sync Chat Users Resource',
  'Change Company Resource',
  'Email Send Queue Resource',
  'Import Posts Resource',
  'Import Posts From S 3 Resource',
  'Mailgun Event Poller Resource',
  'Meilisearch Setup',
  'Planned Posts Resource',
  'Upload Finished Resource',
  'Task Resource',
  'Change User Resource',
  'Token Secured Resource',
  'Survey Resource',
  'Tag Resource',
  'Text Generator Resource',
  'User Resource',
  'Version Resource'
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getApiAccessAttributes: build.query<
        GetApiAccessAttributesApiResponse,
        GetApiAccessAttributesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/access-attributes`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Access Attribute Resource']
      }),
      postApiAccessAttributes: build.mutation<
        PostApiAccessAttributesApiResponse,
        PostApiAccessAttributesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/access-attributes`,
          method: 'POST',
          body: queryArg.createAccessAttribute
        }),
        invalidatesTags: ['Access Attribute Resource']
      }),
      getApiAccessAttributesMatchingAttributeValuesByTenantId: build.query<
        GetApiAccessAttributesMatchingAttributeValuesByTenantIdApiResponse,
        GetApiAccessAttributesMatchingAttributeValuesByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/access-attributes/matching-attribute-values/${queryArg.tenantId}`,
          params: {
            action: queryArg.action,
            resourceType: queryArg.resourceType
          }
        }),
        providesTags: ['Access Attribute Resource']
      }),
      getApiAccessAttributesMatchingAttributeValuesByTenantIdAndId: build.query<
        GetApiAccessAttributesMatchingAttributeValuesByTenantIdAndIdApiResponse,
        GetApiAccessAttributesMatchingAttributeValuesByTenantIdAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/access-attributes/matching-attribute-values/${queryArg.tenantId}/${queryArg.id}`,
          params: {
            action: queryArg.action,
            resourceType: queryArg.resourceType
          }
        }),
        providesTags: ['Access Attribute Resource']
      }),
      postApiAccessAttributesValues: build.mutation<
        PostApiAccessAttributesValuesApiResponse,
        PostApiAccessAttributesValuesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/access-attributes/values`,
          method: 'POST',
          body: queryArg.createAttributeValueByName
        }),
        invalidatesTags: ['Access Attribute Resource']
      }),
      getApiAccessAttributesById: build.query<
        GetApiAccessAttributesByIdApiResponse,
        GetApiAccessAttributesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/access-attributes/${queryArg.id}` }),
        providesTags: ['Access Attribute Resource']
      }),
      deleteApiAccessAttributesById: build.mutation<
        DeleteApiAccessAttributesByIdApiResponse,
        DeleteApiAccessAttributesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/access-attributes/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Access Attribute Resource']
      }),
      patchApiAccessAttributesById: build.mutation<
        PatchApiAccessAttributesByIdApiResponse,
        PatchApiAccessAttributesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/access-attributes/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateAccessAttribute
        }),
        invalidatesTags: ['Access Attribute Resource']
      }),
      getApiAccessAttributesByIdValues: build.query<
        GetApiAccessAttributesByIdValuesApiResponse,
        GetApiAccessAttributesByIdValuesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/access-attributes/${queryArg.id}/values`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Access Attribute Resource']
      }),
      getApiAccessAttributesByIdValuesAndValueId: build.query<
        GetApiAccessAttributesByIdValuesAndValueIdApiResponse,
        GetApiAccessAttributesByIdValuesAndValueIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/access-attributes/${queryArg.id}/values/${queryArg.valueId}`
        }),
        providesTags: ['Access Attribute Resource']
      }),
      deleteApiAccessAttributesByIdValuesAndValueId: build.mutation<
        DeleteApiAccessAttributesByIdValuesAndValueIdApiResponse,
        DeleteApiAccessAttributesByIdValuesAndValueIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/access-attributes/${queryArg.id}/values/${queryArg.valueId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Access Attribute Resource']
      }),
      patchApiAccessAttributesByIdValuesAndValueId: build.mutation<
        PatchApiAccessAttributesByIdValuesAndValueIdApiResponse,
        PatchApiAccessAttributesByIdValuesAndValueIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/access-attributes/${queryArg.id}/values/${queryArg.valueId}`,
          method: 'PATCH',
          body: queryArg.updateAccessAttributeValue
        }),
        invalidatesTags: ['Access Attribute Resource']
      }),
      getApiAssociation: build.query<
        GetApiAssociationApiResponse,
        GetApiAssociationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/association`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['Association Resource']
      }),
      postApiAssociation: build.mutation<
        PostApiAssociationApiResponse,
        PostApiAssociationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/association`,
          method: 'POST',
          body: queryArg.createAssociation
        }),
        invalidatesTags: ['Association Resource']
      }),
      getApiAssociationAdminAccessAttributeValue: build.query<
        GetApiAssociationAdminAccessAttributeValueApiResponse,
        GetApiAssociationAdminAccessAttributeValueApiArg
      >({
        query: () => ({ url: `/api/association/admin-access-attribute-value` }),
        providesTags: ['Association Resource']
      }),
      getApiAssociationInfo: build.query<
        GetApiAssociationInfoApiResponse,
        GetApiAssociationInfoApiArg
      >({
        query: () => ({ url: `/api/association/info` }),
        providesTags: ['Association Resource']
      }),
      getApiChatDevices: build.query<
        GetApiChatDevicesApiResponse,
        GetApiChatDevicesApiArg
      >({
        query: () => ({ url: `/api/chat/devices` }),
        providesTags: ['Chat Resource']
      }),
      getApiChatToken: build.query<
        GetApiChatTokenApiResponse,
        GetApiChatTokenApiArg
      >({
        query: () => ({ url: `/api/chat/token` }),
        providesTags: ['Chat Resource']
      }),
      getApiCompanies: build.query<
        GetApiCompaniesApiResponse,
        GetApiCompaniesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies`,
          params: {
            guildId: queryArg.guildId,
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Company Resource']
      }),
      postApiCompanies: build.mutation<
        PostApiCompaniesApiResponse,
        PostApiCompaniesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies`,
          method: 'POST',
          body: queryArg.createCompany
        }),
        invalidatesTags: ['Company Resource']
      }),
      getApiCompaniesByExternalIdByExternalId: build.query<
        GetApiCompaniesByExternalIdByExternalIdApiResponse,
        GetApiCompaniesByExternalIdByExternalIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies/by-external-id/${queryArg.externalId}`
        }),
        providesTags: ['Company Resource']
      }),
      getApiCompaniesMy: build.query<
        GetApiCompaniesMyApiResponse,
        GetApiCompaniesMyApiArg
      >({
        query: () => ({ url: `/api/companies/my` }),
        providesTags: ['Company Resource']
      }),
      getApiCompaniesMyUsers: build.query<
        GetApiCompaniesMyUsersApiResponse,
        GetApiCompaniesMyUsersApiArg
      >({
        query: () => ({ url: `/api/companies/my/users` }),
        providesTags: ['Company Resource']
      }),
      getApiCompaniesUpdateRequests: build.query<
        GetApiCompaniesUpdateRequestsApiResponse,
        GetApiCompaniesUpdateRequestsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies/update-requests`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['Company Resource']
      }),
      getApiCompaniesUpdateRequestsByExternalIdAndHash: build.query<
        GetApiCompaniesUpdateRequestsByExternalIdAndHashApiResponse,
        GetApiCompaniesUpdateRequestsByExternalIdAndHashApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies/update-requests/${queryArg.externalId}/${queryArg.hash}`
        }),
        providesTags: ['Company Resource']
      }),
      postApiCompaniesUpdateRequestsById: build.mutation<
        PostApiCompaniesUpdateRequestsByIdApiResponse,
        PostApiCompaniesUpdateRequestsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies/update-requests/${queryArg.id}`,
          method: 'POST',
          body: queryArg.externalCompanyChangeResponse
        }),
        invalidatesTags: ['Company Resource']
      }),
      getApiCompaniesById: build.query<
        GetApiCompaniesByIdApiResponse,
        GetApiCompaniesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/companies/${queryArg.id}` }),
        providesTags: ['Company Resource']
      }),
      deleteApiCompaniesById: build.mutation<
        DeleteApiCompaniesByIdApiResponse,
        DeleteApiCompaniesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies/${queryArg.id}`,
          method: 'DELETE',
          params: {
            commit: queryArg.commit,
            externalId: queryArg.externalId,
            hash: queryArg.hash
          }
        }),
        invalidatesTags: ['Company Resource']
      }),
      patchApiCompaniesById: build.mutation<
        PatchApiCompaniesByIdApiResponse,
        PatchApiCompaniesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateCompany
        }),
        invalidatesTags: ['Company Resource']
      }),
      getApiCompaniesByIdAccessAttributeValues: build.query<
        GetApiCompaniesByIdAccessAttributeValuesApiResponse,
        GetApiCompaniesByIdAccessAttributeValuesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies/${queryArg.id}/access-attribute-values`
        }),
        providesTags: ['Company Resource']
      }),
      postApiCompaniesByIdSetAdmin: build.mutation<
        PostApiCompaniesByIdSetAdminApiResponse,
        PostApiCompaniesByIdSetAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies/${queryArg.id}/set-admin`,
          method: 'POST',
          body: queryArg.setCompanyAdminRequest
        }),
        invalidatesTags: ['Company Resource']
      }),
      postApiCompaniesByIdSetContentAccessAndUserId: build.mutation<
        PostApiCompaniesByIdSetContentAccessAndUserIdApiResponse,
        PostApiCompaniesByIdSetContentAccessAndUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies/${queryArg.id}/set-content-access/${queryArg.userId}`,
          method: 'POST',
          body: queryArg.setUserContentAccessRequest
        }),
        invalidatesTags: ['Company Resource']
      }),
      getApiCompaniesByIdUsers: build.query<
        GetApiCompaniesByIdUsersApiResponse,
        GetApiCompaniesByIdUsersApiArg
      >({
        query: (queryArg) => ({ url: `/api/companies/${queryArg.id}/users` }),
        providesTags: ['Company Resource']
      }),
      getApiCraft: build.query<GetApiCraftApiResponse, GetApiCraftApiArg>({
        query: (queryArg) => ({
          url: `/api/craft`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['Craft Resource']
      }),
      postApiCraft: build.mutation<PostApiCraftApiResponse, PostApiCraftApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/craft`,
            method: 'POST',
            body: queryArg.createCraft
          }),
          invalidatesTags: ['Craft Resource']
        }
      ),
      getApiCraftById: build.query<
        GetApiCraftByIdApiResponse,
        GetApiCraftByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/craft/${queryArg.id}` }),
        providesTags: ['Craft Resource']
      }),
      patchApiCraftById: build.mutation<
        PatchApiCraftByIdApiResponse,
        PatchApiCraftByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/craft/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.craft
        }),
        invalidatesTags: ['Craft Resource']
      }),
      getApiDocumentCategories: build.query<
        GetApiDocumentCategoriesApiResponse,
        GetApiDocumentCategoriesApiArg
      >({
        query: () => ({ url: `/api/document/categories` }),
        providesTags: ['Document Resource']
      }),
      postApiDocumentCategories: build.mutation<
        PostApiDocumentCategoriesApiResponse,
        PostApiDocumentCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/categories`,
          method: 'POST',
          body: queryArg.createOrUpdateDocumentCategory
        }),
        invalidatesTags: ['Document Resource']
      }),
      patchApiDocumentCategoriesById: build.mutation<
        PatchApiDocumentCategoriesByIdApiResponse,
        PatchApiDocumentCategoriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/categories/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.createOrUpdateDocumentCategory
        }),
        invalidatesTags: ['Document Resource']
      }),
      putApiDocumentFilesById: build.mutation<
        PutApiDocumentFilesByIdApiResponse,
        PutApiDocumentFilesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/files/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.updateDocument
        }),
        invalidatesTags: ['Document Resource']
      }),
      deleteApiDocumentFilesById: build.mutation<
        DeleteApiDocumentFilesByIdApiResponse,
        DeleteApiDocumentFilesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/files/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Document Resource']
      }),
      getApiDocumentFilesByIdAccess: build.query<
        GetApiDocumentFilesByIdAccessApiResponse,
        GetApiDocumentFilesByIdAccessApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/files/${queryArg.id}/access`
        }),
        providesTags: ['Document Resource']
      }),
      getApiDocumentFilesByIdDownload: build.mutation<
        GetApiDocumentFilesByIdDownloadApiResponse,
        GetApiDocumentFilesByIdDownloadApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/files/${queryArg.id}/download`,
          method: 'GET'
        }),
        invalidatesTags: ['Document Resource']
      }),
      getApiDocumentFilesByIdReplace: build.mutation<
        GetApiDocumentFilesByIdReplaceApiResponse,
        GetApiDocumentFilesByIdReplaceApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/files/${queryArg.id}/replace`,
          method: 'GET'
        }),
        invalidatesTags: ['Document Resource']
      }),
      getApiDocumentFolders: build.query<
        GetApiDocumentFoldersApiResponse,
        GetApiDocumentFoldersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Document Resource']
      }),
      postApiDocumentFolders: build.mutation<
        PostApiDocumentFoldersApiResponse,
        PostApiDocumentFoldersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders`,
          method: 'POST',
          body: queryArg.createFolder
        }),
        invalidatesTags: ['Document Resource']
      }),
      putApiDocumentFoldersById: build.mutation<
        PutApiDocumentFoldersByIdApiResponse,
        PutApiDocumentFoldersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.updateFolder
        }),
        invalidatesTags: ['Document Resource']
      }),
      deleteApiDocumentFoldersById: build.mutation<
        DeleteApiDocumentFoldersByIdApiResponse,
        DeleteApiDocumentFoldersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Document Resource']
      }),
      getApiDocumentFoldersByIdAccess: build.query<
        GetApiDocumentFoldersByIdAccessApiResponse,
        GetApiDocumentFoldersByIdAccessApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders/${queryArg.id}/access`
        }),
        providesTags: ['Document Resource']
      }),
      getApiDocumentFoldersByIdContents: build.query<
        GetApiDocumentFoldersByIdContentsApiResponse,
        GetApiDocumentFoldersByIdContentsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders/${queryArg.id}/contents`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Document Resource']
      }),
      postApiDocumentFoldersByIdFiles: build.mutation<
        PostApiDocumentFoldersByIdFilesApiResponse,
        PostApiDocumentFoldersByIdFilesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders/${queryArg.id}/files`,
          method: 'POST',
          body: queryArg.createDocument
        }),
        invalidatesTags: ['Document Resource']
      }),
      postApiDocumentFoldersByIdFolders: build.mutation<
        PostApiDocumentFoldersByIdFoldersApiResponse,
        PostApiDocumentFoldersByIdFoldersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders/${queryArg.id}/folders`,
          method: 'POST',
          body: queryArg.createFolder
        }),
        invalidatesTags: ['Document Resource']
      }),
      getApiDocumentFoldersByIdParents: build.query<
        GetApiDocumentFoldersByIdParentsApiResponse,
        GetApiDocumentFoldersByIdParentsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders/${queryArg.id}/parents`
        }),
        providesTags: ['Document Resource']
      }),
      getApiEmailSignatures: build.query<
        GetApiEmailSignaturesApiResponse,
        GetApiEmailSignaturesApiArg
      >({
        query: () => ({ url: `/api/email-signatures` }),
        providesTags: ['Email Signature Resource']
      }),
      postApiEmailSignatures: build.mutation<
        PostApiEmailSignaturesApiResponse,
        PostApiEmailSignaturesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/email-signatures`,
          method: 'POST',
          body: queryArg.createEmailSignature
        }),
        invalidatesTags: ['Email Signature Resource']
      }),
      getApiEmailSignaturesById: build.query<
        GetApiEmailSignaturesByIdApiResponse,
        GetApiEmailSignaturesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/email-signatures/${queryArg.id}` }),
        providesTags: ['Email Signature Resource']
      }),
      deleteApiEmailSignaturesById: build.mutation<
        DeleteApiEmailSignaturesByIdApiResponse,
        DeleteApiEmailSignaturesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/email-signatures/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Email Signature Resource']
      }),
      patchApiEmailSignaturesById: build.mutation<
        PatchApiEmailSignaturesByIdApiResponse,
        PatchApiEmailSignaturesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/email-signatures/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateEmailSignature
        }),
        invalidatesTags: ['Email Signature Resource']
      }),
      getApiEmailTemplates: build.query<
        GetApiEmailTemplatesApiResponse,
        GetApiEmailTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/email-templates`,
          params: { type: queryArg['type'] }
        }),
        providesTags: ['Email Template Resource']
      }),
      postApiEmailTemplates: build.mutation<
        PostApiEmailTemplatesApiResponse,
        PostApiEmailTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/email-templates`,
          method: 'POST',
          body: queryArg.createEmailTemplate
        }),
        invalidatesTags: ['Email Template Resource']
      }),
      getApiEmailTemplatesById: build.query<
        GetApiEmailTemplatesByIdApiResponse,
        GetApiEmailTemplatesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/email-templates/${queryArg.id}` }),
        providesTags: ['Email Template Resource']
      }),
      patchApiEmailTemplatesById: build.mutation<
        PatchApiEmailTemplatesByIdApiResponse,
        PatchApiEmailTemplatesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/email-templates/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateEmailTemplate
        }),
        invalidatesTags: ['Email Template Resource']
      }),
      getApiEvents: build.query<GetApiEventsApiResponse, GetApiEventsApiArg>({
        query: (queryArg) => ({
          url: `/api/events`,
          params: {
            from: queryArg['from'],
            page: queryArg.page,
            size: queryArg.size,
            to: queryArg.to
          }
        }),
        providesTags: ['Event Resource']
      }),
      postApiEventsAttend: build.mutation<
        PostApiEventsAttendApiResponse,
        PostApiEventsAttendApiArg
      >({
        query: (queryArg) => ({
          url: `/api/events/attend`,
          method: 'POST',
          body: queryArg.attendWithToken
        }),
        invalidatesTags: ['Event Resource']
      }),
      getApiEventsAttendeeStatusAttribute: build.query<
        GetApiEventsAttendeeStatusAttributeApiResponse,
        GetApiEventsAttendeeStatusAttributeApiArg
      >({
        query: () => ({ url: `/api/events/attendee-status-attribute` }),
        providesTags: ['Event Resource']
      }),
      getApiEventsTypes: build.query<
        GetApiEventsTypesApiResponse,
        GetApiEventsTypesApiArg
      >({
        query: () => ({ url: `/api/events/types` }),
        providesTags: ['Event Resource']
      }),
      getApiEventsByIdAttendees: build.query<
        GetApiEventsByIdAttendeesApiResponse,
        GetApiEventsByIdAttendeesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/events/${queryArg.id}/attendees`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Event Resource']
      }),
      postApiEventsByIdAttendees: build.mutation<
        PostApiEventsByIdAttendeesApiResponse,
        PostApiEventsByIdAttendeesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/events/${queryArg.id}/attendees`,
          method: 'POST',
          body: queryArg.attendRequest
        }),
        invalidatesTags: ['Event Resource']
      }),
      getApiEventsByIdAttendeesExcel: build.query<
        GetApiEventsByIdAttendeesExcelApiResponse,
        GetApiEventsByIdAttendeesExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/api/events/${queryArg.id}/attendees_excel`,
          params: { status: queryArg.status }
        }),
        providesTags: ['Event Resource']
      }),
      getApiEventsByIdIcs: build.query<
        GetApiEventsByIdIcsApiResponse,
        GetApiEventsByIdIcsApiArg
      >({
        query: (queryArg) => ({ url: `/api/events/${queryArg.id}/ics` }),
        providesTags: ['Event Resource']
      }),
      getApiFruits: build.query<GetApiFruitsApiResponse, GetApiFruitsApiArg>({
        query: () => ({ url: `/api/fruits` }),
        providesTags: ['Fruit Resource']
      }),
      getApiGuild: build.query<GetApiGuildApiResponse, GetApiGuildApiArg>({
        query: (queryArg) => ({
          url: `/api/guild`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size
          }
        }),
        providesTags: ['Guild Resource']
      }),
      postApiGuild: build.mutation<PostApiGuildApiResponse, PostApiGuildApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/guild`,
            method: 'POST',
            body: queryArg.createGuild
          }),
          invalidatesTags: ['Guild Resource']
        }
      ),
      getApiGuildAccessAttributeValue: build.query<
        GetApiGuildAccessAttributeValueApiResponse,
        GetApiGuildAccessAttributeValueApiArg
      >({
        query: (queryArg) => ({
          url: `/api/guild/access-attribute-value`,
          params: { guildId: queryArg.guildId, roleId: queryArg.roleId }
        }),
        providesTags: ['Guild Resource']
      }),
      getApiGuildMembershipTypes: build.query<
        GetApiGuildMembershipTypesApiResponse,
        GetApiGuildMembershipTypesApiArg
      >({
        query: () => ({ url: `/api/guild/membership_types` }),
        providesTags: ['Guild Resource']
      }),
      postApiGuildMembershipTypes: build.mutation<
        PostApiGuildMembershipTypesApiResponse,
        PostApiGuildMembershipTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/guild/membership_types`,
          method: 'POST',
          body: queryArg.createMembershipType
        }),
        invalidatesTags: ['Guild Resource']
      }),
      getApiGuildMembershipTypesById: build.query<
        GetApiGuildMembershipTypesByIdApiResponse,
        GetApiGuildMembershipTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/guild/membership_types/${queryArg.id}`
        }),
        providesTags: ['Guild Resource']
      }),
      deleteApiGuildMembershipTypesById: build.mutation<
        DeleteApiGuildMembershipTypesByIdApiResponse,
        DeleteApiGuildMembershipTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/guild/membership_types/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Guild Resource']
      }),
      patchApiGuildMembershipTypesById: build.mutation<
        PatchApiGuildMembershipTypesByIdApiResponse,
        PatchApiGuildMembershipTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/guild/membership_types/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.createMembershipType
        }),
        invalidatesTags: ['Guild Resource']
      }),
      getApiGuildById: build.query<
        GetApiGuildByIdApiResponse,
        GetApiGuildByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/guild/${queryArg.id}` }),
        providesTags: ['Guild Resource']
      }),
      deleteApiGuildById: build.mutation<
        DeleteApiGuildByIdApiResponse,
        DeleteApiGuildByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/guild/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Guild Resource']
      }),
      patchApiGuildById: build.mutation<
        PatchApiGuildByIdApiResponse,
        PatchApiGuildByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/guild/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateGuild
        }),
        invalidatesTags: ['Guild Resource']
      }),
      getApiGuildByIdCompanies: build.query<
        GetApiGuildByIdCompaniesApiResponse,
        GetApiGuildByIdCompaniesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/guild/${queryArg.id}/companies`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Guild Resource']
      }),
      getApiGuildByIdMembers: build.query<
        GetApiGuildByIdMembersApiResponse,
        GetApiGuildByIdMembersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/guild/${queryArg.id}/members`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Guild Resource']
      }),
      getApiHello: build.query<GetApiHelloApiResponse, GetApiHelloApiArg>({
        query: () => ({ url: `/api/hello` }),
        providesTags: ['Example Resource']
      }),
      postApiImages: build.mutation<
        PostApiImagesApiResponse,
        PostApiImagesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/images`,
          method: 'POST',
          body: queryArg.createImage
        }),
        invalidatesTags: ['Image Resource']
      }),
      getApiLists: build.query<GetApiListsApiResponse, GetApiListsApiArg>({
        query: (queryArg) => ({
          url: `/api/lists`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size
          }
        }),
        providesTags: ['User List Resource']
      }),
      postApiLists: build.mutation<PostApiListsApiResponse, PostApiListsApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/lists`,
            method: 'POST',
            body: queryArg.createUserList
          }),
          invalidatesTags: ['User List Resource']
        }
      ),
      getApiListsById: build.query<
        GetApiListsByIdApiResponse,
        GetApiListsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/lists/${queryArg.id}` }),
        providesTags: ['User List Resource']
      }),
      deleteApiListsById: build.mutation<
        DeleteApiListsByIdApiResponse,
        DeleteApiListsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/lists/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['User List Resource']
      }),
      patchApiListsById: build.mutation<
        PatchApiListsByIdApiResponse,
        PatchApiListsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/lists/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateUserListInfo
        }),
        invalidatesTags: ['User List Resource']
      }),
      getApiListsByIdChatUsers: build.query<
        GetApiListsByIdChatUsersApiResponse,
        GetApiListsByIdChatUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/lists/${queryArg.id}/chat_users`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['User List Resource']
      }),
      getApiListsByIdUsers: build.query<
        GetApiListsByIdUsersApiResponse,
        GetApiListsByIdUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/lists/${queryArg.id}/users`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['User List Resource']
      }),
      getApiPolicy: build.query<GetApiPolicyApiResponse, GetApiPolicyApiArg>({
        query: (queryArg) => ({
          url: `/api/policy`,
          params: {
            action: queryArg.action,
            page: queryArg.page,
            resourceType: queryArg.resourceType,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Policy Resource']
      }),
      postApiPolicy: build.mutation<
        PostApiPolicyApiResponse,
        PostApiPolicyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/policy`,
          method: 'POST',
          body: queryArg.createPolicy
        }),
        invalidatesTags: ['Policy Resource']
      }),
      getApiPolicyById: build.query<
        GetApiPolicyByIdApiResponse,
        GetApiPolicyByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/policy/${queryArg.id}` }),
        providesTags: ['Policy Resource']
      }),
      deleteApiPolicyById: build.mutation<
        DeleteApiPolicyByIdApiResponse,
        DeleteApiPolicyByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/policy/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Policy Resource']
      }),
      patchApiPolicyById: build.mutation<
        PatchApiPolicyByIdApiResponse,
        PatchApiPolicyByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/policy/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updatePolicy
        }),
        invalidatesTags: ['Policy Resource']
      }),
      getApiPosts: build.query<GetApiPostsApiResponse, GetApiPostsApiArg>({
        query: (queryArg) => ({
          url: `/api/posts`,
          params: {
            onlyRoot: queryArg.onlyRoot,
            page: queryArg.page,
            parent: queryArg.parent,
            size: queryArg.size,
            status: queryArg.status,
            type: queryArg['type']
          }
        }),
        providesTags: ['Post Resource']
      }),
      postApiPosts: build.mutation<PostApiPostsApiResponse, PostApiPostsApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/posts`,
            method: 'POST',
            body: queryArg.createPost
          }),
          invalidatesTags: ['Post Resource']
        }
      ),
      getApiPostsByAttributeValueByAttributeValueId: build.query<
        GetApiPostsByAttributeValueByAttributeValueIdApiResponse,
        GetApiPostsByAttributeValueByAttributeValueIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/by-attribute-value/${queryArg.attributeValueId}`
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsDrafts: build.query<
        GetApiPostsDraftsApiResponse,
        GetApiPostsDraftsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/drafts`,
          params: {
            page: queryArg.page,
            parent: queryArg.parent,
            size: queryArg.size,
            type: queryArg['type']
          }
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsDraftsRoot: build.query<
        GetApiPostsDraftsRootApiResponse,
        GetApiPostsDraftsRootApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/drafts/root`,
          params: {
            page: queryArg.page,
            parent: queryArg.parent,
            size: queryArg.size,
            type: queryArg['type']
          }
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsDraftsById: build.query<
        GetApiPostsDraftsByIdApiResponse,
        GetApiPostsDraftsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/posts/drafts/${queryArg.id}` }),
        providesTags: ['Post Resource']
      }),
      patchApiPostsDraftsById: build.mutation<
        PatchApiPostsDraftsByIdApiResponse,
        PatchApiPostsDraftsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/drafts/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updatePost
        }),
        invalidatesTags: ['Post Resource']
      }),
      getApiPostsPlanned: build.query<
        GetApiPostsPlannedApiResponse,
        GetApiPostsPlannedApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/planned`,
          params: {
            page: queryArg.page,
            parent: queryArg.parent,
            size: queryArg.size
          }
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsPlannedById: build.query<
        GetApiPostsPlannedByIdApiResponse,
        GetApiPostsPlannedByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/posts/planned/${queryArg.id}` }),
        providesTags: ['Post Resource']
      }),
      getApiPostsRoot: build.query<
        GetApiPostsRootApiResponse,
        GetApiPostsRootApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/root`,
          params: {
            page: queryArg.page,
            parent: queryArg.parent,
            size: queryArg.size,
            type: queryArg['type']
          }
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsSearch: build.query<
        GetApiPostsSearchApiResponse,
        GetApiPostsSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/search`,
          params: {
            page: queryArg.page,
            q: queryArg.q,
            size: queryArg.size,
            type: queryArg['type']
          }
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsSummaryById: build.query<
        GetApiPostsSummaryByIdApiResponse,
        GetApiPostsSummaryByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/posts/summary/${queryArg.id}` }),
        providesTags: ['Post Resource']
      }),
      getApiPostsById: build.query<
        GetApiPostsByIdApiResponse,
        GetApiPostsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/posts/${queryArg.id}` }),
        providesTags: ['Post Resource']
      }),
      deleteApiPostsById: build.mutation<
        DeleteApiPostsByIdApiResponse,
        DeleteApiPostsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Post Resource']
      }),
      patchApiPostsById: build.mutation<
        PatchApiPostsByIdApiResponse,
        PatchApiPostsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updatePost
        }),
        invalidatesTags: ['Post Resource']
      }),
      getApiPostsByIdAttachments: build.query<
        GetApiPostsByIdAttachmentsApiResponse,
        GetApiPostsByIdAttachmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/attachments`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsByIdBreadcrumbs: build.query<
        GetApiPostsByIdBreadcrumbsApiResponse,
        GetApiPostsByIdBreadcrumbsApiArg
      >({
        query: (queryArg) => ({ url: `/api/posts/${queryArg.id}/breadcrumbs` }),
        providesTags: ['Post Resource']
      }),
      getApiPostsByIdComments: build.query<
        GetApiPostsByIdCommentsApiResponse,
        GetApiPostsByIdCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/comments`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['Post Resource']
      }),
      postApiPostsByIdComments: build.mutation<
        PostApiPostsByIdCommentsApiResponse,
        PostApiPostsByIdCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/comments`,
          method: 'POST',
          body: queryArg.createComment
        }),
        invalidatesTags: ['Post Resource']
      }),
      getApiPostsByIdEmailSignature: build.query<
        GetApiPostsByIdEmailSignatureApiResponse,
        GetApiPostsByIdEmailSignatureApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/email-signature`
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsByIdEmailTemplate: build.query<
        GetApiPostsByIdEmailTemplateApiResponse,
        GetApiPostsByIdEmailTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/email-template`
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsByIdMatchingAccessAttributeValueIds: build.query<
        GetApiPostsByIdMatchingAccessAttributeValueIdsApiResponse,
        GetApiPostsByIdMatchingAccessAttributeValueIdsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/matching-access-attribute-value-ids`,
          params: { action: queryArg.action }
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsByIdParents: build.query<
        GetApiPostsByIdParentsApiResponse,
        GetApiPostsByIdParentsApiArg
      >({
        query: (queryArg) => ({ url: `/api/posts/${queryArg.id}/parents` }),
        providesTags: ['Post Resource']
      }),
      postApiPostsByIdPublish: build.mutation<
        PostApiPostsByIdPublishApiResponse,
        PostApiPostsByIdPublishApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/publish`,
          method: 'POST'
        }),
        invalidatesTags: ['Post Resource']
      }),
      getApiPostsByIdRecipients: build.query<
        GetApiPostsByIdRecipientsApiResponse,
        GetApiPostsByIdRecipientsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/recipients`,
          params: {
            emailFilter: queryArg.emailFilter,
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsByIdRecipientsSummary: build.query<
        GetApiPostsByIdRecipientsSummaryApiResponse,
        GetApiPostsByIdRecipientsSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/recipients/summary`
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsByIdRecipientsExcel: build.query<
        GetApiPostsByIdRecipientsExcelApiResponse,
        GetApiPostsByIdRecipientsExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/recipients_excel`,
          params: { emailFilter: queryArg.emailFilter }
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsByIdResendAndNotificationId: build.mutation<
        GetApiPostsByIdResendAndNotificationIdApiResponse,
        GetApiPostsByIdResendAndNotificationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/resend/${queryArg.notificationId}`,
          method: 'GET'
        }),
        invalidatesTags: ['Post Resource']
      }),
      postApiPostsByIdVote: build.mutation<
        PostApiPostsByIdVoteApiResponse,
        PostApiPostsByIdVoteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/vote`,
          method: 'POST',
          body: queryArg.vote
        }),
        invalidatesTags: ['Post Resource']
      }),
      getApiRoles: build.query<GetApiRolesApiResponse, GetApiRolesApiArg>({
        query: (queryArg) => ({
          url: `/api/roles`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size
          }
        }),
        providesTags: ['Role Resource']
      }),
      postApiRoles: build.mutation<PostApiRolesApiResponse, PostApiRolesApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/roles`,
            method: 'POST',
            body: queryArg.createRole
          }),
          invalidatesTags: ['Role Resource']
        }
      ),
      getApiRolesByRoleId: build.query<
        GetApiRolesByRoleIdApiResponse,
        GetApiRolesByRoleIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/roles/${queryArg.roleId}` }),
        providesTags: ['Role Resource']
      }),
      patchApiRolesByRoleId: build.mutation<
        PatchApiRolesByRoleIdApiResponse,
        PatchApiRolesByRoleIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/roles/${queryArg.roleId}`,
          method: 'PATCH',
          body: queryArg.createRole
        }),
        invalidatesTags: ['Role Resource']
      }),
      getApiSchedulerAbacCacheDocumentsByTenantId: build.query<
        GetApiSchedulerAbacCacheDocumentsByTenantIdApiResponse,
        GetApiSchedulerAbacCacheDocumentsByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/abac_cache/documents/${queryArg.tenantId}`
        }),
        providesTags: ['Abac Cache Resource']
      }),
      getApiSchedulerAbacCacheFoldersByTenantId: build.query<
        GetApiSchedulerAbacCacheFoldersByTenantIdApiResponse,
        GetApiSchedulerAbacCacheFoldersByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/abac_cache/folders/${queryArg.tenantId}`
        }),
        providesTags: ['Abac Cache Resource']
      }),
      getApiSchedulerAbacCachePostsByTenantId: build.query<
        GetApiSchedulerAbacCachePostsByTenantIdApiResponse,
        GetApiSchedulerAbacCachePostsByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/abac_cache/posts/${queryArg.tenantId}`
        }),
        providesTags: ['Abac Cache Resource']
      }),
      postApiSchedulerAssociation: build.mutation<
        PostApiSchedulerAssociationApiResponse,
        PostApiSchedulerAssociationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/association`,
          method: 'POST',
          body: queryArg.createAssociation
        }),
        invalidatesTags: ['Create Association Resource']
      }),
      postApiSchedulerChatSyncByTenantId: build.mutation<
        PostApiSchedulerChatSyncByTenantIdApiResponse,
        PostApiSchedulerChatSyncByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/chat/sync/${queryArg.tenantId}`,
          method: 'POST'
        }),
        invalidatesTags: ['Sync Chat Users Resource']
      }),
      postApiSchedulerCompaniesUpdateRequestsByTenantId: build.mutation<
        PostApiSchedulerCompaniesUpdateRequestsByTenantIdApiResponse,
        PostApiSchedulerCompaniesUpdateRequestsByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/companies/update-requests/${queryArg.tenantId}`,
          method: 'POST',
          body: queryArg.externalCompanyChangeRequest
        }),
        invalidatesTags: ['Change Company Resource']
      }),
      getApiSchedulerEmailSender: build.query<
        GetApiSchedulerEmailSenderApiResponse,
        GetApiSchedulerEmailSenderApiArg
      >({
        query: () => ({ url: `/api/scheduler/email/sender` }),
        providesTags: ['Email Send Queue Resource']
      }),
      postApiSchedulerImportDocuments: build.mutation<
        PostApiSchedulerImportDocumentsApiResponse,
        PostApiSchedulerImportDocumentsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/import/documents`,
          method: 'POST',
          body: queryArg.importDocumentsDto
        }),
        invalidatesTags: ['Import Posts Resource']
      }),
      postApiSchedulerImportPosts: build.mutation<
        PostApiSchedulerImportPostsApiResponse,
        PostApiSchedulerImportPostsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/import/posts`,
          method: 'POST',
          body: queryArg.importDto
        }),
        invalidatesTags: ['Import Posts Resource']
      }),
      getApiSchedulerImportAwsPosts: build.query<
        GetApiSchedulerImportAwsPostsApiResponse,
        GetApiSchedulerImportAwsPostsApiArg
      >({
        query: () => ({ url: `/api/scheduler/import_aws/posts` }),
        providesTags: ['Import Posts From S 3 Resource']
      }),
      getApiSchedulerMailgunEvents: build.query<
        GetApiSchedulerMailgunEventsApiResponse,
        GetApiSchedulerMailgunEventsApiArg
      >({
        query: () => ({ url: `/api/scheduler/mailgun/events` }),
        providesTags: ['Mailgun Event Poller Resource']
      }),
      getApiSchedulerMailgunEventsPollByTenantId: build.query<
        GetApiSchedulerMailgunEventsPollByTenantIdApiResponse,
        GetApiSchedulerMailgunEventsPollByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/mailgun/events/poll/${queryArg.tenantId}`
        }),
        providesTags: ['Mailgun Event Poller Resource']
      }),
      postApiSchedulerMeilisearchCompaniesByTenantId: build.mutation<
        PostApiSchedulerMeilisearchCompaniesByTenantIdApiResponse,
        PostApiSchedulerMeilisearchCompaniesByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/meilisearch/companies/${queryArg.tenantId}`,
          method: 'POST'
        }),
        invalidatesTags: ['Meilisearch Setup']
      }),
      postApiSchedulerMeilisearchPostTypesByTenantId: build.mutation<
        PostApiSchedulerMeilisearchPostTypesByTenantIdApiResponse,
        PostApiSchedulerMeilisearchPostTypesByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/meilisearch/post_types/${queryArg.tenantId}`,
          method: 'POST'
        }),
        invalidatesTags: ['Meilisearch Setup']
      }),
      postApiSchedulerMeilisearchPostsByTenantId: build.mutation<
        PostApiSchedulerMeilisearchPostsByTenantIdApiResponse,
        PostApiSchedulerMeilisearchPostsByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/meilisearch/posts/${queryArg.tenantId}`,
          method: 'POST'
        }),
        invalidatesTags: ['Meilisearch Setup']
      }),
      postApiSchedulerMeilisearchSetup: build.mutation<
        PostApiSchedulerMeilisearchSetupApiResponse,
        PostApiSchedulerMeilisearchSetupApiArg
      >({
        query: () => ({
          url: `/api/scheduler/meilisearch/setup`,
          method: 'POST'
        }),
        invalidatesTags: ['Meilisearch Setup']
      }),
      getApiSchedulerPlannedPostsPublish: build.query<
        GetApiSchedulerPlannedPostsPublishApiResponse,
        GetApiSchedulerPlannedPostsPublishApiArg
      >({
        query: () => ({ url: `/api/scheduler/planned_posts/publish` }),
        providesTags: ['Planned Posts Resource']
      }),
      getApiSchedulerPlannedPostsPublishByTenantIdAndPostId: build.query<
        GetApiSchedulerPlannedPostsPublishByTenantIdAndPostIdApiResponse,
        GetApiSchedulerPlannedPostsPublishByTenantIdAndPostIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/planned_posts/publish/${queryArg.tenantId}/${queryArg.postId}`
        }),
        providesTags: ['Planned Posts Resource']
      }),
      postApiSchedulerStorageDocumentUpload: build.mutation<
        PostApiSchedulerStorageDocumentUploadApiResponse,
        PostApiSchedulerStorageDocumentUploadApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/storage/document-upload`,
          method: 'POST',
          body: queryArg.messageWrapper
        }),
        invalidatesTags: ['Upload Finished Resource']
      }),
      getApiSchedulerTasksTest: build.query<
        GetApiSchedulerTasksTestApiResponse,
        GetApiSchedulerTasksTestApiArg
      >({
        query: () => ({ url: `/api/scheduler/tasks/test` }),
        providesTags: ['Task Resource']
      }),
      postApiSchedulerTasksTest: build.mutation<
        PostApiSchedulerTasksTestApiResponse,
        PostApiSchedulerTasksTestApiArg
      >({
        query: () => ({ url: `/api/scheduler/tasks/test`, method: 'POST' }),
        invalidatesTags: ['Task Resource']
      }),
      postApiSchedulerUsersUpdateRequestsByTenantId: build.mutation<
        PostApiSchedulerUsersUpdateRequestsByTenantIdApiResponse,
        PostApiSchedulerUsersUpdateRequestsByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/scheduler/users/update-requests/${queryArg.tenantId}`,
          method: 'POST',
          body: queryArg.externalUserChangeRequest
        }),
        invalidatesTags: ['Change User Resource']
      }),
      getApiSecuredAdminOnly: build.query<
        GetApiSecuredAdminOnlyApiResponse,
        GetApiSecuredAdminOnlyApiArg
      >({
        query: () => ({ url: `/api/secured/admin-only` }),
        providesTags: ['Token Secured Resource']
      }),
      getApiSecuredPermitAll: build.query<
        GetApiSecuredPermitAllApiResponse,
        GetApiSecuredPermitAllApiArg
      >({
        query: () => ({ url: `/api/secured/permit-all` }),
        providesTags: ['Token Secured Resource']
      }),
      postApiSurveys: build.mutation<
        PostApiSurveysApiResponse,
        PostApiSurveysApiArg
      >({
        query: (queryArg) => ({
          url: `/api/surveys`,
          method: 'POST',
          body: queryArg.createSurvey
        }),
        invalidatesTags: ['Survey Resource']
      }),
      getApiSurveysByTokenByToken: build.query<
        GetApiSurveysByTokenByTokenApiResponse,
        GetApiSurveysByTokenByTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/api/surveys/by-token/${queryArg.token}`
        }),
        providesTags: ['Survey Resource']
      }),
      postApiSurveysRespond: build.mutation<
        PostApiSurveysRespondApiResponse,
        PostApiSurveysRespondApiArg
      >({
        query: (queryArg) => ({
          url: `/api/surveys/respond`,
          method: 'POST',
          body: queryArg.createOrUpdateSurveyResponseWithToken
        }),
        invalidatesTags: ['Survey Resource']
      }),
      getApiSurveysTemplates: build.query<
        GetApiSurveysTemplatesApiResponse,
        GetApiSurveysTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/surveys/templates`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Survey Resource']
      }),
      getApiSurveysById: build.query<
        GetApiSurveysByIdApiResponse,
        GetApiSurveysByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/surveys/${queryArg.id}` }),
        providesTags: ['Survey Resource']
      }),
      deleteApiSurveysById: build.mutation<
        DeleteApiSurveysByIdApiResponse,
        DeleteApiSurveysByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/surveys/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Survey Resource']
      }),
      patchApiSurveysById: build.mutation<
        PatchApiSurveysByIdApiResponse,
        PatchApiSurveysByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/surveys/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateSurvey
        }),
        invalidatesTags: ['Survey Resource']
      }),
      getApiSurveysByIdAllResponses: build.query<
        GetApiSurveysByIdAllResponsesApiResponse,
        GetApiSurveysByIdAllResponsesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/surveys/${queryArg.id}/all-responses`,
          params: { postId: queryArg.postId, search: queryArg.search }
        }),
        providesTags: ['Survey Resource']
      }),
      getApiSurveysByIdResponse: build.query<
        GetApiSurveysByIdResponseApiResponse,
        GetApiSurveysByIdResponseApiArg
      >({
        query: (queryArg) => ({ url: `/api/surveys/${queryArg.id}/response` }),
        providesTags: ['Survey Resource']
      }),
      postApiSurveysByIdResponse: build.mutation<
        PostApiSurveysByIdResponseApiResponse,
        PostApiSurveysByIdResponseApiArg
      >({
        query: (queryArg) => ({
          url: `/api/surveys/${queryArg.id}/response`,
          method: 'POST',
          body: queryArg.createSurveyResponse
        }),
        invalidatesTags: ['Survey Resource']
      }),
      patchApiSurveysByIdResponse: build.mutation<
        PatchApiSurveysByIdResponseApiResponse,
        PatchApiSurveysByIdResponseApiArg
      >({
        query: (queryArg) => ({
          url: `/api/surveys/${queryArg.id}/response`,
          method: 'PATCH',
          body: queryArg.createSurveyResponse
        }),
        invalidatesTags: ['Survey Resource']
      }),
      getApiSurveysByIdResponseByUserAndUserId: build.query<
        GetApiSurveysByIdResponseByUserAndUserIdApiResponse,
        GetApiSurveysByIdResponseByUserAndUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/surveys/${queryArg.id}/response-by-user/${queryArg.userId}`
        }),
        providesTags: ['Survey Resource']
      }),
      patchApiSurveysByIdResponseAndResponseId: build.mutation<
        PatchApiSurveysByIdResponseAndResponseIdApiResponse,
        PatchApiSurveysByIdResponseAndResponseIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/surveys/${queryArg.id}/response/${queryArg.responseId}`,
          method: 'PATCH',
          body: queryArg.createSurveyResponse
        }),
        invalidatesTags: ['Survey Resource']
      }),
      getApiSurveysByIdResponses: build.query<
        GetApiSurveysByIdResponsesApiResponse,
        GetApiSurveysByIdResponsesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/surveys/${queryArg.id}/responses`,
          params: {
            page: queryArg.page,
            postId: queryArg.postId,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Survey Resource']
      }),
      getApiTags: build.query<GetApiTagsApiResponse, GetApiTagsApiArg>({
        query: () => ({ url: `/api/tags` }),
        providesTags: ['Tag Resource']
      }),
      postApiTags: build.mutation<PostApiTagsApiResponse, PostApiTagsApiArg>({
        query: (queryArg) => ({
          url: `/api/tags`,
          method: 'POST',
          body: queryArg.tag
        }),
        invalidatesTags: ['Tag Resource']
      }),
      deleteApiTagsById: build.mutation<
        DeleteApiTagsByIdApiResponse,
        DeleteApiTagsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tags/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Tag Resource']
      }),
      patchApiTagsById: build.mutation<
        PatchApiTagsByIdApiResponse,
        PatchApiTagsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tags/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.tag
        }),
        invalidatesTags: ['Tag Resource']
      }),
      postApiTextGeneratorFeedMessage: build.mutation<
        PostApiTextGeneratorFeedMessageApiResponse,
        PostApiTextGeneratorFeedMessageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/text-generator/feed-message`,
          method: 'POST',
          body: queryArg.generateText
        }),
        invalidatesTags: ['Text Generator Resource']
      }),
      postApiTextGeneratorTeaser: build.mutation<
        PostApiTextGeneratorTeaserApiResponse,
        PostApiTextGeneratorTeaserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/text-generator/teaser`,
          method: 'POST',
          body: queryArg.generateText
        }),
        invalidatesTags: ['Text Generator Resource']
      }),
      getApiUsers: build.query<GetApiUsersApiResponse, GetApiUsersApiArg>({
        query: (queryArg) => ({
          url: `/api/users`,
          params: {
            filter: queryArg.filter,
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['User Resource']
      }),
      postApiUsers: build.mutation<PostApiUsersApiResponse, PostApiUsersApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/users`,
            method: 'POST',
            body: queryArg.createUser
          }),
          invalidatesTags: ['User Resource']
        }
      ),
      getApiUsersMe: build.query<GetApiUsersMeApiResponse, GetApiUsersMeApiArg>(
        {
          query: () => ({ url: `/api/users/me` }),
          providesTags: ['User Resource']
        }
      ),
      patchApiUsersMe: build.mutation<
        PatchApiUsersMeApiResponse,
        PatchApiUsersMeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/me`,
          method: 'PATCH',
          body: queryArg.updateUser
        }),
        invalidatesTags: ['User Resource']
      }),
      getApiUsersMeChatToken: build.query<
        GetApiUsersMeChatTokenApiResponse,
        GetApiUsersMeChatTokenApiArg
      >({
        query: () => ({ url: `/api/users/me/chat_token` }),
        providesTags: ['User Resource']
      }),
      postApiUsersMeCloudMessageToken: build.mutation<
        PostApiUsersMeCloudMessageTokenApiResponse,
        PostApiUsersMeCloudMessageTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/me/cloud_message_token`,
          method: 'POST',
          body: queryArg.setCloudMessageToken
        }),
        invalidatesTags: ['User Resource']
      }),
      postApiUsersMeResetPassword: build.mutation<
        PostApiUsersMeResetPasswordApiResponse,
        PostApiUsersMeResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/me/reset_password`,
          method: 'POST',
          body: queryArg.resetPasswordRequest
        }),
        invalidatesTags: ['User Resource']
      }),
      getApiUsersMeTagNotificationSettings: build.query<
        GetApiUsersMeTagNotificationSettingsApiResponse,
        GetApiUsersMeTagNotificationSettingsApiArg
      >({
        query: () => ({ url: `/api/users/me/tag_notification_settings` }),
        providesTags: ['User Resource']
      }),
      patchApiUsersMeTagNotificationSettings: build.mutation<
        PatchApiUsersMeTagNotificationSettingsApiResponse,
        PatchApiUsersMeTagNotificationSettingsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/me/tag_notification_settings`,
          method: 'PATCH',
          body: queryArg.userTagNotificationUpdate
        }),
        invalidatesTags: ['User Resource']
      }),
      postApiUsersRecoverPassword: build.mutation<
        PostApiUsersRecoverPasswordApiResponse,
        PostApiUsersRecoverPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/recover_password`,
          method: 'POST',
          body: queryArg.recoverPasswordRequest
        }),
        invalidatesTags: ['User Resource']
      }),
      postApiUsersSignInWithEmailLink: build.mutation<
        PostApiUsersSignInWithEmailLinkApiResponse,
        PostApiUsersSignInWithEmailLinkApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/sign_in_with_email_link`,
          method: 'POST',
          body: queryArg.signInWithEmailLink
        }),
        invalidatesTags: ['User Resource']
      }),
      getApiUsersTenant: build.query<
        GetApiUsersTenantApiResponse,
        GetApiUsersTenantApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/tenant`,
          params: { email: queryArg.email }
        }),
        providesTags: ['User Resource']
      }),
      getApiUsersUnsubscribeByToken: build.mutation<
        GetApiUsersUnsubscribeByTokenApiResponse,
        GetApiUsersUnsubscribeByTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/unsubscribe/${queryArg.token}`,
          method: 'GET'
        }),
        invalidatesTags: ['User Resource']
      }),
      getApiUsersUpdateRequests: build.query<
        GetApiUsersUpdateRequestsApiResponse,
        GetApiUsersUpdateRequestsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/update-requests`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['User Resource']
      }),
      postApiUsersUpdateRequestsById: build.mutation<
        PostApiUsersUpdateRequestsByIdApiResponse,
        PostApiUsersUpdateRequestsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/update-requests/${queryArg.id}`,
          method: 'POST',
          body: queryArg.externalUserChangeResponse
        }),
        invalidatesTags: ['User Resource']
      }),
      getApiUsersById: build.query<
        GetApiUsersByIdApiResponse,
        GetApiUsersByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}` }),
        providesTags: ['User Resource']
      }),
      deleteApiUsersById: build.mutation<
        DeleteApiUsersByIdApiResponse,
        DeleteApiUsersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['User Resource']
      }),
      patchApiUsersById: build.mutation<
        PatchApiUsersByIdApiResponse,
        PatchApiUsersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateUser
        }),
        invalidatesTags: ['User Resource']
      }),
      postApiUsersByIdResetPassword: build.mutation<
        PostApiUsersByIdResetPasswordApiResponse,
        PostApiUsersByIdResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/reset_password`,
          method: 'POST',
          body: queryArg.resetPasswordRequest
        }),
        invalidatesTags: ['User Resource']
      }),
      getApiUsersByIdTagNotificationSettings: build.query<
        GetApiUsersByIdTagNotificationSettingsApiResponse,
        GetApiUsersByIdTagNotificationSettingsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/tag_notification_settings`
        }),
        providesTags: ['User Resource']
      }),
      patchApiUsersByIdTagNotificationSettings: build.mutation<
        PatchApiUsersByIdTagNotificationSettingsApiResponse,
        PatchApiUsersByIdTagNotificationSettingsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/tag_notification_settings`,
          method: 'PATCH',
          body: queryArg.userTagNotificationUpdate
        }),
        invalidatesTags: ['User Resource']
      }),
      getApiVersion: build.query<GetApiVersionApiResponse, GetApiVersionApiArg>(
        {
          query: () => ({ url: `/api/version` }),
          providesTags: ['Version Resource']
        }
      )
    }),
    overrideExisting: false
  });
export { injectedRtkApi as deinVerbandApi };
export type GetApiAccessAttributesApiResponse =
  /** status 200 OK */ PaginatedResponseAccessAttribute;
export type GetApiAccessAttributesApiArg = {
  page?: number | null;
  size?: number | null;
  sortKey: AccessAttributeSortKey & any;
  sortOrder: SortOrder & any;
};
export type PostApiAccessAttributesApiResponse = unknown;
export type PostApiAccessAttributesApiArg = {
  createAccessAttribute: CreateAccessAttribute;
};
export type GetApiAccessAttributesMatchingAttributeValuesByTenantIdApiResponse =
  /** status 200 OK */ MatchingAttributes[];
export type GetApiAccessAttributesMatchingAttributeValuesByTenantIdApiArg = {
  tenantId: string;
  action: string;
  resourceType: ResourceType;
};
export type GetApiAccessAttributesMatchingAttributeValuesByTenantIdAndIdApiResponse =
  /** status 200 OK */ number[];
export type GetApiAccessAttributesMatchingAttributeValuesByTenantIdAndIdApiArg =
  {
    id: number;
    tenantId: string;
    action: string;
    resourceType: ResourceType;
  };
export type PostApiAccessAttributesValuesApiResponse = unknown;
export type PostApiAccessAttributesValuesApiArg = {
  createAttributeValueByName: CreateAttributeValueByName;
};
export type GetApiAccessAttributesByIdApiResponse =
  /** status 200 OK */ AccessAttribute;
export type GetApiAccessAttributesByIdApiArg = {
  id: number;
};
export type DeleteApiAccessAttributesByIdApiResponse = unknown;
export type DeleteApiAccessAttributesByIdApiArg = {
  id: number;
};
export type PatchApiAccessAttributesByIdApiResponse = unknown;
export type PatchApiAccessAttributesByIdApiArg = {
  id: number;
  updateAccessAttribute: UpdateAccessAttribute;
};
export type GetApiAccessAttributesByIdValuesApiResponse =
  /** status 200 OK */ PaginatedResponseAccessAttributeValue;
export type GetApiAccessAttributesByIdValuesApiArg = {
  id: number;
  page?: number | null;
  size?: number | null;
  sortKey: AccessAttributeSortKey & any;
  sortOrder: SortOrder & any;
};
export type GetApiAccessAttributesByIdValuesAndValueIdApiResponse =
  /** status 200 OK */ AccessAttributeValue;
export type GetApiAccessAttributesByIdValuesAndValueIdApiArg = {
  id: number;
  valueId: number;
};
export type DeleteApiAccessAttributesByIdValuesAndValueIdApiResponse = unknown;
export type DeleteApiAccessAttributesByIdValuesAndValueIdApiArg = {
  id: number;
  valueId: number;
};
export type PatchApiAccessAttributesByIdValuesAndValueIdApiResponse = unknown;
export type PatchApiAccessAttributesByIdValuesAndValueIdApiArg = {
  id: number;
  valueId: number;
  updateAccessAttributeValue: UpdateAccessAttributeValue;
};
export type GetApiAssociationApiResponse =
  /** status 200 OK */ PaginatedResponseAssociation;
export type GetApiAssociationApiArg = {
  page?: number | null;
  size?: number | null;
};
export type PostApiAssociationApiResponse = unknown;
export type PostApiAssociationApiArg = {
  createAssociation: CreateAssociation;
};
export type GetApiAssociationAdminAccessAttributeValueApiResponse =
  /** status 200 OK */ AccessAttributeValue;
export type GetApiAssociationAdminAccessAttributeValueApiArg = void;
export type GetApiAssociationInfoApiResponse =
  /** status 200 OK */ AssociationInfo;
export type GetApiAssociationInfoApiArg = void;
export type GetApiChatDevicesApiResponse = /** status 200 OK */ string[];
export type GetApiChatDevicesApiArg = void;
export type GetApiChatTokenApiResponse = /** status 200 OK */ string;
export type GetApiChatTokenApiArg = void;
export type GetApiCompaniesApiResponse =
  /** status 200 OK */ PaginatedResponseCompanySummary;
export type GetApiCompaniesApiArg = {
  guildId?: number | null;
  page?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: CompanySortKey & any;
  sortOrder: SortOrder & any;
};
export type PostApiCompaniesApiResponse = unknown;
export type PostApiCompaniesApiArg = {
  createCompany: CreateCompany;
};
export type GetApiCompaniesByExternalIdByExternalIdApiResponse =
  /** status 200 OK */ CompanyDetails;
export type GetApiCompaniesByExternalIdByExternalIdApiArg = {
  externalId: string;
};
export type GetApiCompaniesMyApiResponse = /** status 200 OK */ CompanyDetails;
export type GetApiCompaniesMyApiArg = void;
export type GetApiCompaniesMyUsersApiResponse =
  /** status 200 OK */ UserSummary[];
export type GetApiCompaniesMyUsersApiArg = void;
export type GetApiCompaniesUpdateRequestsApiResponse =
  /** status 200 OK */ PaginatedResponseExternalCompanyChangeRequest;
export type GetApiCompaniesUpdateRequestsApiArg = {
  page?: number | null;
  size?: number | null;
};
export type GetApiCompaniesUpdateRequestsByExternalIdAndHashApiResponse =
  /** status 200 OK */ ExternalCompanyChangeRequest;
export type GetApiCompaniesUpdateRequestsByExternalIdAndHashApiArg = {
  externalId: string;
  hash: string;
};
export type PostApiCompaniesUpdateRequestsByIdApiResponse = unknown;
export type PostApiCompaniesUpdateRequestsByIdApiArg = {
  id: number;
  externalCompanyChangeResponse: ExternalCompanyChangeResponse;
};
export type GetApiCompaniesByIdApiResponse =
  /** status 200 OK */ CompanyDetails;
export type GetApiCompaniesByIdApiArg = {
  id: number;
};
export type DeleteApiCompaniesByIdApiResponse = unknown;
export type DeleteApiCompaniesByIdApiArg = {
  id: number;
  commit?: boolean;
  externalId?: string | null;
  hash?: string | null;
};
export type PatchApiCompaniesByIdApiResponse = unknown;
export type PatchApiCompaniesByIdApiArg = {
  id: number;
  updateCompany: UpdateCompany;
};
export type GetApiCompaniesByIdAccessAttributeValuesApiResponse =
  /** status 200 OK */ AccessAttributeValue[];
export type GetApiCompaniesByIdAccessAttributeValuesApiArg = {
  id: number;
};
export type PostApiCompaniesByIdSetAdminApiResponse = unknown;
export type PostApiCompaniesByIdSetAdminApiArg = {
  id: number;
  setCompanyAdminRequest: SetCompanyAdminRequest;
};
export type PostApiCompaniesByIdSetContentAccessAndUserIdApiResponse = unknown;
export type PostApiCompaniesByIdSetContentAccessAndUserIdApiArg = {
  id: number;
  userId: number;
  setUserContentAccessRequest: SetUserContentAccessRequest;
};
export type GetApiCompaniesByIdUsersApiResponse =
  /** status 200 OK */ UserSummary[];
export type GetApiCompaniesByIdUsersApiArg = {
  id: number;
};
export type GetApiCraftApiResponse =
  /** status 200 OK */ PaginatedResponseCraft;
export type GetApiCraftApiArg = {
  page?: number | null;
  size?: number | null;
};
export type PostApiCraftApiResponse = unknown;
export type PostApiCraftApiArg = {
  createCraft: CreateCraft;
};
export type GetApiCraftByIdApiResponse = /** status 200 OK */ Craft1;
export type GetApiCraftByIdApiArg = {
  id: number;
};
export type PatchApiCraftByIdApiResponse = unknown;
export type PatchApiCraftByIdApiArg = {
  id: number;
  craft: Craft;
};
export type GetApiDocumentCategoriesApiResponse =
  /** status 200 OK */ DocumentCategory[];
export type GetApiDocumentCategoriesApiArg = void;
export type PostApiDocumentCategoriesApiResponse = unknown;
export type PostApiDocumentCategoriesApiArg = {
  createOrUpdateDocumentCategory: CreateOrUpdateDocumentCategory;
};
export type PatchApiDocumentCategoriesByIdApiResponse = unknown;
export type PatchApiDocumentCategoriesByIdApiArg = {
  id: number;
  createOrUpdateDocumentCategory: CreateOrUpdateDocumentCategory;
};
export type PutApiDocumentFilesByIdApiResponse = unknown;
export type PutApiDocumentFilesByIdApiArg = {
  id: number;
  updateDocument: UpdateDocument;
};
export type DeleteApiDocumentFilesByIdApiResponse = unknown;
export type DeleteApiDocumentFilesByIdApiArg = {
  id: number;
};
export type GetApiDocumentFilesByIdAccessApiResponse =
  /** status 200 OK */ AppliedAccessAttributes;
export type GetApiDocumentFilesByIdAccessApiArg = {
  id: number;
};
export type GetApiDocumentFilesByIdDownloadApiResponse =
  /** status 200 OK */ DownloadLink;
export type GetApiDocumentFilesByIdDownloadApiArg = {
  id: number;
};
export type GetApiDocumentFilesByIdReplaceApiResponse =
  /** status 200 OK */ CreateDocumentResponse;
export type GetApiDocumentFilesByIdReplaceApiArg = {
  id: number;
};
export type GetApiDocumentFoldersApiResponse =
  /** status 200 OK */ PaginatedResponseDocumentItem;
export type GetApiDocumentFoldersApiArg = {
  page?: number | null;
  size?: number | null;
  sortKey: DocumentSortKey & any;
  sortOrder: SortOrder & any;
};
export type PostApiDocumentFoldersApiResponse = unknown;
export type PostApiDocumentFoldersApiArg = {
  createFolder: CreateFolder;
};
export type PutApiDocumentFoldersByIdApiResponse = unknown;
export type PutApiDocumentFoldersByIdApiArg = {
  id: number;
  updateFolder: UpdateFolder;
};
export type DeleteApiDocumentFoldersByIdApiResponse = unknown;
export type DeleteApiDocumentFoldersByIdApiArg = {
  id: number;
};
export type GetApiDocumentFoldersByIdAccessApiResponse =
  /** status 200 OK */ AppliedAccessAttributes;
export type GetApiDocumentFoldersByIdAccessApiArg = {
  id: number;
};
export type GetApiDocumentFoldersByIdContentsApiResponse =
  /** status 200 OK */ PaginatedResponseDocumentItem;
export type GetApiDocumentFoldersByIdContentsApiArg = {
  id: number;
  page?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: DocumentSortKey & any;
  sortOrder: SortOrder & any;
};
export type PostApiDocumentFoldersByIdFilesApiResponse =
  /** status 200 OK */ CreateDocumentResponse;
export type PostApiDocumentFoldersByIdFilesApiArg = {
  id: number;
  createDocument: CreateDocument;
};
export type PostApiDocumentFoldersByIdFoldersApiResponse = unknown;
export type PostApiDocumentFoldersByIdFoldersApiArg = {
  id: number;
  createFolder: CreateFolder;
};
export type GetApiDocumentFoldersByIdParentsApiResponse =
  /** status 200 OK */ DocumentGroup[];
export type GetApiDocumentFoldersByIdParentsApiArg = {
  id: number;
};
export type GetApiEmailSignaturesApiResponse =
  /** status 200 OK */ EmailSignature[];
export type GetApiEmailSignaturesApiArg = void;
export type PostApiEmailSignaturesApiResponse = unknown;
export type PostApiEmailSignaturesApiArg = {
  createEmailSignature: CreateEmailSignature;
};
export type GetApiEmailSignaturesByIdApiResponse =
  /** status 200 OK */ EmailSignature;
export type GetApiEmailSignaturesByIdApiArg = {
  id: number;
};
export type DeleteApiEmailSignaturesByIdApiResponse = unknown;
export type DeleteApiEmailSignaturesByIdApiArg = {
  id: number;
};
export type PatchApiEmailSignaturesByIdApiResponse =
  /** status 200 OK */ EmailSignature;
export type PatchApiEmailSignaturesByIdApiArg = {
  id: number;
  updateEmailSignature: UpdateEmailSignature;
};
export type GetApiEmailTemplatesApiResponse =
  /** status 200 OK */ EmailTemplate[];
export type GetApiEmailTemplatesApiArg = {
  type?: string | null;
};
export type PostApiEmailTemplatesApiResponse = unknown;
export type PostApiEmailTemplatesApiArg = {
  createEmailTemplate: CreateEmailTemplate;
};
export type GetApiEmailTemplatesByIdApiResponse =
  /** status 200 OK */ EmailTemplate;
export type GetApiEmailTemplatesByIdApiArg = {
  id: number;
};
export type PatchApiEmailTemplatesByIdApiResponse = unknown;
export type PatchApiEmailTemplatesByIdApiArg = {
  id: number;
  updateEmailTemplate: UpdateEmailTemplate;
};
export type GetApiEventsApiResponse =
  /** status 200 OK */ PaginatedResponseEventSummary;
export type GetApiEventsApiArg = {
  from?: string | null;
  page?: number | null;
  size?: number | null;
  to?: string | null;
};
export type PostApiEventsAttendApiResponse =
  /** status 200 OK */ AttendResponse;
export type PostApiEventsAttendApiArg = {
  attendWithToken: AttendWithToken;
};
export type GetApiEventsAttendeeStatusAttributeApiResponse =
  /** status 200 OK */ AccessAttribute;
export type GetApiEventsAttendeeStatusAttributeApiArg = void;
export type GetApiEventsTypesApiResponse = /** status 200 OK */ EventType[];
export type GetApiEventsTypesApiArg = void;
export type GetApiEventsByIdAttendeesApiResponse =
  /** status 200 OK */ PaginatedResponseAttendResponse;
export type GetApiEventsByIdAttendeesApiArg = {
  id: number;
  page?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: UserSortKey & any;
  sortOrder: SortOrder & any;
};
export type PostApiEventsByIdAttendeesApiResponse = unknown;
export type PostApiEventsByIdAttendeesApiArg = {
  id: number;
  attendRequest: AttendRequest;
};
export type GetApiEventsByIdAttendeesExcelApiResponse = unknown;
export type GetApiEventsByIdAttendeesExcelApiArg = {
  id: number;
  status: AttendeeStatus[];
};
export type GetApiEventsByIdIcsApiResponse = unknown;
export type GetApiEventsByIdIcsApiArg = {
  id: number;
};
export type GetApiFruitsApiResponse = /** status 200 OK */ Fruit[];
export type GetApiFruitsApiArg = void;
export type GetApiGuildApiResponse =
  /** status 200 OK */ PaginatedResponseGuild;
export type GetApiGuildApiArg = {
  page?: number | null;
  search?: string | null;
  size?: number | null;
};
export type PostApiGuildApiResponse = unknown;
export type PostApiGuildApiArg = {
  createGuild: CreateGuild;
};
export type GetApiGuildAccessAttributeValueApiResponse =
  /** status 200 OK */ AccessAttributeValue;
export type GetApiGuildAccessAttributeValueApiArg = {
  guildId?: number;
  roleId?: number;
};
export type GetApiGuildMembershipTypesApiResponse =
  /** status 200 OK */ GuildMembershipType[];
export type GetApiGuildMembershipTypesApiArg = void;
export type PostApiGuildMembershipTypesApiResponse = unknown;
export type PostApiGuildMembershipTypesApiArg = {
  createMembershipType: CreateMembershipType;
};
export type GetApiGuildMembershipTypesByIdApiResponse =
  /** status 200 OK */ GuildMembershipType;
export type GetApiGuildMembershipTypesByIdApiArg = {
  id: number;
};
export type DeleteApiGuildMembershipTypesByIdApiResponse = unknown;
export type DeleteApiGuildMembershipTypesByIdApiArg = {
  id: number;
};
export type PatchApiGuildMembershipTypesByIdApiResponse = unknown;
export type PatchApiGuildMembershipTypesByIdApiArg = {
  id: number;
  createMembershipType: CreateMembershipType;
};
export type GetApiGuildByIdApiResponse = /** status 200 OK */ Guild;
export type GetApiGuildByIdApiArg = {
  id: number;
};
export type DeleteApiGuildByIdApiResponse = unknown;
export type DeleteApiGuildByIdApiArg = {
  id: number;
};
export type PatchApiGuildByIdApiResponse = unknown;
export type PatchApiGuildByIdApiArg = {
  id: number;
  updateGuild: UpdateGuild;
};
export type GetApiGuildByIdCompaniesApiResponse =
  /** status 200 OK */ PaginatedResponseCompanySummary;
export type GetApiGuildByIdCompaniesApiArg = {
  id: number;
  page?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: CompanySortKey & any;
  sortOrder: SortOrder & any;
};
export type GetApiGuildByIdMembersApiResponse =
  /** status 200 OK */ PaginatedResponseUserSummary;
export type GetApiGuildByIdMembersApiArg = {
  id: number;
  page?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: UserSortKey & any;
  sortOrder: SortOrder & any;
};
export type GetApiHelloApiResponse = /** status 200 OK */ Hello;
export type GetApiHelloApiArg = void;
export type PostApiImagesApiResponse = /** status 200 OK */ CreateImageResponse;
export type PostApiImagesApiArg = {
  createImage: CreateImage;
};
export type GetApiListsApiResponse =
  /** status 200 OK */ PaginatedResponseUserListInfo;
export type GetApiListsApiArg = {
  page?: number | null;
  search?: string | null;
  size?: number | null;
};
export type PostApiListsApiResponse = unknown;
export type PostApiListsApiArg = {
  createUserList: CreateUserList;
};
export type GetApiListsByIdApiResponse = /** status 200 OK */ UserListInfo;
export type GetApiListsByIdApiArg = {
  id: number;
};
export type DeleteApiListsByIdApiResponse = unknown;
export type DeleteApiListsByIdApiArg = {
  id: number;
};
export type PatchApiListsByIdApiResponse = unknown;
export type PatchApiListsByIdApiArg = {
  id: number;
  updateUserListInfo: UpdateUserListInfo;
};
export type GetApiListsByIdChatUsersApiResponse =
  /** status 200 OK */ PaginatedResponseEmailUser;
export type GetApiListsByIdChatUsersApiArg = {
  id: number;
  page?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: UserSortKey & any;
  sortOrder: SortOrder & any;
};
export type GetApiListsByIdUsersApiResponse =
  /** status 200 OK */ PaginatedResponseEmailUser;
export type GetApiListsByIdUsersApiArg = {
  id: number;
  page?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: UserSortKey & any;
  sortOrder: SortOrder & any;
};
export type GetApiPolicyApiResponse =
  /** status 200 OK */ PaginatedResponsePolicy;
export type GetApiPolicyApiArg = {
  action?: string | null;
  page?: number | null;
  resourceType?: ResourceType & (any | null);
  size?: number | null;
  sortKey: PolicySortKey & any;
  sortOrder: SortOrder & any;
};
export type PostApiPolicyApiResponse = unknown;
export type PostApiPolicyApiArg = {
  createPolicy: CreatePolicy;
};
export type GetApiPolicyByIdApiResponse = /** status 200 OK */ Policy;
export type GetApiPolicyByIdApiArg = {
  id: number;
};
export type DeleteApiPolicyByIdApiResponse = unknown;
export type DeleteApiPolicyByIdApiArg = {
  id: number;
};
export type PatchApiPolicyByIdApiResponse = unknown;
export type PatchApiPolicyByIdApiArg = {
  id: number;
  updatePolicy: UpdatePolicy;
};
export type GetApiPostsApiResponse =
  /** status 200 OK */ PaginatedResponsePostSummary;
export type GetApiPostsApiArg = {
  onlyRoot?: boolean;
  page?: number | null;
  parent?: number | null;
  size?: number | null;
  status: PostStatus[];
  type: PostType[];
};
export type PostApiPostsApiResponse = unknown;
export type PostApiPostsApiArg = {
  createPost: CreatePost;
};
export type GetApiPostsByAttributeValueByAttributeValueIdApiResponse =
  /** status 200 OK */ number[];
export type GetApiPostsByAttributeValueByAttributeValueIdApiArg = {
  attributeValueId: number;
};
export type GetApiPostsDraftsApiResponse =
  /** status 200 OK */ PaginatedResponsePostSummary;
export type GetApiPostsDraftsApiArg = {
  page?: number | null;
  parent?: number | null;
  size?: number | null;
  type: PostType[];
};
export type GetApiPostsDraftsRootApiResponse =
  /** status 200 OK */ PaginatedResponsePostSummary;
export type GetApiPostsDraftsRootApiArg = {
  page?: number | null;
  parent?: number | null;
  size?: number | null;
  type: PostType[];
};
export type GetApiPostsDraftsByIdApiResponse = /** status 200 OK */ PostDetails;
export type GetApiPostsDraftsByIdApiArg = {
  id: number;
};
export type PatchApiPostsDraftsByIdApiResponse = unknown;
export type PatchApiPostsDraftsByIdApiArg = {
  id: number;
  updatePost: UpdatePost;
};
export type GetApiPostsPlannedApiResponse =
  /** status 200 OK */ PaginatedResponsePostSummary;
export type GetApiPostsPlannedApiArg = {
  page?: number | null;
  parent?: number | null;
  size?: number | null;
};
export type GetApiPostsPlannedByIdApiResponse =
  /** status 200 OK */ PostDetails;
export type GetApiPostsPlannedByIdApiArg = {
  id: number;
};
export type GetApiPostsRootApiResponse =
  /** status 200 OK */ PaginatedResponsePostSummary;
export type GetApiPostsRootApiArg = {
  page?: number | null;
  parent?: number | null;
  size?: number | null;
  type: PostType[];
};
export type GetApiPostsSearchApiResponse =
  /** status 200 OK */ PaginatedResponsePostSearchRecord;
export type GetApiPostsSearchApiArg = {
  page?: number | null;
  q: string;
  size?: number | null;
  type: PostType[];
};
export type GetApiPostsSummaryByIdApiResponse =
  /** status 200 OK */ PostSummary;
export type GetApiPostsSummaryByIdApiArg = {
  id: number;
};
export type GetApiPostsByIdApiResponse = /** status 200 OK */ PostDetails;
export type GetApiPostsByIdApiArg = {
  id: number;
};
export type DeleteApiPostsByIdApiResponse = unknown;
export type DeleteApiPostsByIdApiArg = {
  id: number;
};
export type PatchApiPostsByIdApiResponse = unknown;
export type PatchApiPostsByIdApiArg = {
  id: number;
  updatePost: UpdatePost;
};
export type GetApiPostsByIdAttachmentsApiResponse =
  /** status 200 OK */ PaginatedResponsePostAttachment;
export type GetApiPostsByIdAttachmentsApiArg = {
  id: number;
  page?: number | null;
  size?: number | null;
};
export type GetApiPostsByIdBreadcrumbsApiResponse =
  /** status 200 OK */ PostNameAndId[];
export type GetApiPostsByIdBreadcrumbsApiArg = {
  id: number;
};
export type GetApiPostsByIdCommentsApiResponse =
  /** status 200 OK */ PaginatedResponsePostComment;
export type GetApiPostsByIdCommentsApiArg = {
  id: number;
  page?: number | null;
  size?: number | null;
};
export type PostApiPostsByIdCommentsApiResponse = unknown;
export type PostApiPostsByIdCommentsApiArg = {
  id: number;
  createComment: CreateComment;
};
export type GetApiPostsByIdEmailSignatureApiResponse =
  /** status 200 OK */ EmailSignature;
export type GetApiPostsByIdEmailSignatureApiArg = {
  id: number;
};
export type GetApiPostsByIdEmailTemplateApiResponse =
  /** status 200 OK */ EmailTemplate;
export type GetApiPostsByIdEmailTemplateApiArg = {
  id: number;
};
export type GetApiPostsByIdMatchingAccessAttributeValueIdsApiResponse =
  /** status 200 OK */ number[];
export type GetApiPostsByIdMatchingAccessAttributeValueIdsApiArg = {
  id: number;
  action: Action;
};
export type GetApiPostsByIdParentsApiResponse =
  /** status 200 OK */ PostNameAndId[];
export type GetApiPostsByIdParentsApiArg = {
  id: number;
};
export type PostApiPostsByIdPublishApiResponse = unknown;
export type PostApiPostsByIdPublishApiArg = {
  id: number;
};
export type GetApiPostsByIdRecipientsApiResponse =
  /** status 200 OK */ PaginatedResponsePostRecipient;
export type GetApiPostsByIdRecipientsApiArg = {
  id: number;
  emailFilter: UserEmailFilter & any;
  page?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: EmailUserSortKey & any;
  sortOrder: SortOrder & any;
};
export type GetApiPostsByIdRecipientsSummaryApiResponse =
  /** status 200 OK */ PostRecipientSummaries;
export type GetApiPostsByIdRecipientsSummaryApiArg = {
  id: number;
};
export type GetApiPostsByIdRecipientsExcelApiResponse = unknown;
export type GetApiPostsByIdRecipientsExcelApiArg = {
  id: number;
  emailFilter: UserEmailFilter & any;
};
export type GetApiPostsByIdResendAndNotificationIdApiResponse = unknown;
export type GetApiPostsByIdResendAndNotificationIdApiArg = {
  id: number;
  notificationId: number;
};
export type PostApiPostsByIdVoteApiResponse = unknown;
export type PostApiPostsByIdVoteApiArg = {
  id: number;
  vote: Vote;
};
export type GetApiRolesApiResponse = /** status 200 OK */ PaginatedResponseRole;
export type GetApiRolesApiArg = {
  page?: number | null;
  search?: string | null;
  size?: number | null;
};
export type PostApiRolesApiResponse = unknown;
export type PostApiRolesApiArg = {
  createRole: CreateRole;
};
export type GetApiRolesByRoleIdApiResponse = /** status 200 OK */ Role;
export type GetApiRolesByRoleIdApiArg = {
  roleId: number;
};
export type PatchApiRolesByRoleIdApiResponse = unknown;
export type PatchApiRolesByRoleIdApiArg = {
  roleId: number;
  createRole: CreateRole;
};
export type GetApiSchedulerAbacCacheDocumentsByTenantIdApiResponse = unknown;
export type GetApiSchedulerAbacCacheDocumentsByTenantIdApiArg = {
  tenantId: string;
};
export type GetApiSchedulerAbacCacheFoldersByTenantIdApiResponse = unknown;
export type GetApiSchedulerAbacCacheFoldersByTenantIdApiArg = {
  tenantId: string;
};
export type GetApiSchedulerAbacCachePostsByTenantIdApiResponse = unknown;
export type GetApiSchedulerAbacCachePostsByTenantIdApiArg = {
  tenantId: string;
};
export type PostApiSchedulerAssociationApiResponse = unknown;
export type PostApiSchedulerAssociationApiArg = {
  createAssociation: CreateAssociation;
};
export type PostApiSchedulerChatSyncByTenantIdApiResponse = unknown;
export type PostApiSchedulerChatSyncByTenantIdApiArg = {
  tenantId: string;
};
export type PostApiSchedulerCompaniesUpdateRequestsByTenantIdApiResponse =
  unknown;
export type PostApiSchedulerCompaniesUpdateRequestsByTenantIdApiArg = {
  tenantId: string;
  externalCompanyChangeRequest: ExternalCompanyChangeRequest;
};
export type GetApiSchedulerEmailSenderApiResponse = unknown;
export type GetApiSchedulerEmailSenderApiArg = void;
export type PostApiSchedulerImportDocumentsApiResponse = unknown;
export type PostApiSchedulerImportDocumentsApiArg = {
  importDocumentsDto: ImportDocumentsDto;
};
export type PostApiSchedulerImportPostsApiResponse = unknown;
export type PostApiSchedulerImportPostsApiArg = {
  importDto: ImportDto;
};
export type GetApiSchedulerImportAwsPostsApiResponse = unknown;
export type GetApiSchedulerImportAwsPostsApiArg = void;
export type GetApiSchedulerMailgunEventsApiResponse = unknown;
export type GetApiSchedulerMailgunEventsApiArg = void;
export type GetApiSchedulerMailgunEventsPollByTenantIdApiResponse = unknown;
export type GetApiSchedulerMailgunEventsPollByTenantIdApiArg = {
  tenantId: string;
};
export type PostApiSchedulerMeilisearchCompaniesByTenantIdApiResponse = unknown;
export type PostApiSchedulerMeilisearchCompaniesByTenantIdApiArg = {
  tenantId: string;
};
export type PostApiSchedulerMeilisearchPostTypesByTenantIdApiResponse = unknown;
export type PostApiSchedulerMeilisearchPostTypesByTenantIdApiArg = {
  tenantId: string;
};
export type PostApiSchedulerMeilisearchPostsByTenantIdApiResponse = unknown;
export type PostApiSchedulerMeilisearchPostsByTenantIdApiArg = {
  tenantId: string;
};
export type PostApiSchedulerMeilisearchSetupApiResponse = unknown;
export type PostApiSchedulerMeilisearchSetupApiArg = void;
export type GetApiSchedulerPlannedPostsPublishApiResponse = unknown;
export type GetApiSchedulerPlannedPostsPublishApiArg = void;
export type GetApiSchedulerPlannedPostsPublishByTenantIdAndPostIdApiResponse =
  unknown;
export type GetApiSchedulerPlannedPostsPublishByTenantIdAndPostIdApiArg = {
  postId: number;
  tenantId: string;
};
export type PostApiSchedulerStorageDocumentUploadApiResponse = unknown;
export type PostApiSchedulerStorageDocumentUploadApiArg = {
  messageWrapper: MessageWrapper;
};
export type GetApiSchedulerTasksTestApiResponse = unknown;
export type GetApiSchedulerTasksTestApiArg = void;
export type PostApiSchedulerTasksTestApiResponse = unknown;
export type PostApiSchedulerTasksTestApiArg = void;
export type PostApiSchedulerUsersUpdateRequestsByTenantIdApiResponse = unknown;
export type PostApiSchedulerUsersUpdateRequestsByTenantIdApiArg = {
  tenantId: string;
  externalUserChangeRequest: ExternalUserChangeRequest;
};
export type GetApiSecuredAdminOnlyApiResponse = unknown;
export type GetApiSecuredAdminOnlyApiArg = void;
export type GetApiSecuredPermitAllApiResponse = unknown;
export type GetApiSecuredPermitAllApiArg = void;
export type PostApiSurveysApiResponse = unknown;
export type PostApiSurveysApiArg = {
  createSurvey: CreateSurvey;
};
export type GetApiSurveysByTokenByTokenApiResponse =
  /** status 200 OK */ SurveyWithResponse;
export type GetApiSurveysByTokenByTokenApiArg = {
  token: string;
};
export type PostApiSurveysRespondApiResponse =
  /** status 200 OK */ SurveyWithResponse;
export type PostApiSurveysRespondApiArg = {
  createOrUpdateSurveyResponseWithToken: CreateOrUpdateSurveyResponseWithToken;
};
export type GetApiSurveysTemplatesApiResponse =
  /** status 200 OK */ PaginatedResponseSurvey;
export type GetApiSurveysTemplatesApiArg = {
  page?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: SurveySortKey & any;
  sortOrder: SortOrder & any;
};
export type GetApiSurveysByIdApiResponse = /** status 200 OK */ Survey;
export type GetApiSurveysByIdApiArg = {
  id: number;
};
export type DeleteApiSurveysByIdApiResponse = unknown;
export type DeleteApiSurveysByIdApiArg = {
  id: number;
};
export type PatchApiSurveysByIdApiResponse = unknown;
export type PatchApiSurveysByIdApiArg = {
  id: number;
  updateSurvey: UpdateSurvey;
};
export type GetApiSurveysByIdAllResponsesApiResponse =
  /** status 200 OK */ SurveyResponseList;
export type GetApiSurveysByIdAllResponsesApiArg = {
  id: number;
  postId?: number | null;
  search?: string | null;
};
export type GetApiSurveysByIdResponseApiResponse =
  /** status 200 OK */ SurveyResponse;
export type GetApiSurveysByIdResponseApiArg = {
  id: number;
};
export type PostApiSurveysByIdResponseApiResponse = unknown;
export type PostApiSurveysByIdResponseApiArg = {
  id: number;
  createSurveyResponse: CreateSurveyResponse;
};
export type PatchApiSurveysByIdResponseApiResponse = unknown;
export type PatchApiSurveysByIdResponseApiArg = {
  id: number;
  createSurveyResponse: CreateSurveyResponse;
};
export type GetApiSurveysByIdResponseByUserAndUserIdApiResponse =
  /** status 200 OK */ SurveyResponse;
export type GetApiSurveysByIdResponseByUserAndUserIdApiArg = {
  id: number;
  userId: number;
};
export type PatchApiSurveysByIdResponseAndResponseIdApiResponse = unknown;
export type PatchApiSurveysByIdResponseAndResponseIdApiArg = {
  id: number;
  responseId: number;
  createSurveyResponse: CreateSurveyResponse;
};
export type GetApiSurveysByIdResponsesApiResponse =
  /** status 200 OK */ PaginatedResponseSurveyResponseListItem;
export type GetApiSurveysByIdResponsesApiArg = {
  id: number;
  page?: number | null;
  postId?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: SurveyResponseSortKey & any;
  sortOrder: SortOrder & any;
};
export type GetApiTagsApiResponse = /** status 200 OK */ Tag[];
export type GetApiTagsApiArg = void;
export type PostApiTagsApiResponse = unknown;
export type PostApiTagsApiArg = {
  tag: Tag;
};
export type DeleteApiTagsByIdApiResponse = unknown;
export type DeleteApiTagsByIdApiArg = {
  id: number;
};
export type PatchApiTagsByIdApiResponse = unknown;
export type PatchApiTagsByIdApiArg = {
  id: number;
  tag: Tag;
};
export type PostApiTextGeneratorFeedMessageApiResponse =
  /** status 200 OK */ GenerateTextResponse;
export type PostApiTextGeneratorFeedMessageApiArg = {
  generateText: GenerateText;
};
export type PostApiTextGeneratorTeaserApiResponse =
  /** status 200 OK */ GenerateTextResponse;
export type PostApiTextGeneratorTeaserApiArg = {
  generateText: GenerateText;
};
export type GetApiUsersApiResponse =
  /** status 200 OK */ PaginatedResponseUserSummary;
export type GetApiUsersApiArg = {
  filter: UserFilter & any;
  page?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: UserSortKey & any;
  sortOrder: SortOrder & any;
};
export type PostApiUsersApiResponse = unknown;
export type PostApiUsersApiArg = {
  createUser: CreateUser;
};
export type GetApiUsersMeApiResponse = /** status 200 OK */ UserDetails;
export type GetApiUsersMeApiArg = void;
export type PatchApiUsersMeApiResponse = unknown;
export type PatchApiUsersMeApiArg = {
  updateUser: UpdateUser;
};
export type GetApiUsersMeChatTokenApiResponse = /** status 200 OK */ ChatToken;
export type GetApiUsersMeChatTokenApiArg = void;
export type PostApiUsersMeCloudMessageTokenApiResponse = unknown;
export type PostApiUsersMeCloudMessageTokenApiArg = {
  setCloudMessageToken: SetCloudMessageToken;
};
export type PostApiUsersMeResetPasswordApiResponse = unknown;
export type PostApiUsersMeResetPasswordApiArg = {
  resetPasswordRequest: ResetPasswordRequest;
};
export type GetApiUsersMeTagNotificationSettingsApiResponse =
  /** status 200 OK */ UserTagNotificationSettings;
export type GetApiUsersMeTagNotificationSettingsApiArg = void;
export type PatchApiUsersMeTagNotificationSettingsApiResponse = unknown;
export type PatchApiUsersMeTagNotificationSettingsApiArg = {
  userTagNotificationUpdate: UserTagNotificationUpdate;
};
export type PostApiUsersRecoverPasswordApiResponse = unknown;
export type PostApiUsersRecoverPasswordApiArg = {
  recoverPasswordRequest: RecoverPasswordRequest;
};
export type PostApiUsersSignInWithEmailLinkApiResponse = unknown;
export type PostApiUsersSignInWithEmailLinkApiArg = {
  signInWithEmailLink: SignInWithEmailLink;
};
export type GetApiUsersTenantApiResponse = /** status 200 OK */ TenantInfo[];
export type GetApiUsersTenantApiArg = {
  email: string;
};
export type GetApiUsersUnsubscribeByTokenApiResponse = unknown;
export type GetApiUsersUnsubscribeByTokenApiArg = {
  token: string;
};
export type GetApiUsersUpdateRequestsApiResponse =
  /** status 200 OK */ PaginatedResponseExternalUserChangeRequest;
export type GetApiUsersUpdateRequestsApiArg = {
  page?: number | null;
  size?: number | null;
};
export type PostApiUsersUpdateRequestsByIdApiResponse = unknown;
export type PostApiUsersUpdateRequestsByIdApiArg = {
  id: number;
  externalUserChangeResponse: ExternalUserChangeResponse;
};
export type GetApiUsersByIdApiResponse = /** status 200 OK */ UserDetails;
export type GetApiUsersByIdApiArg = {
  id: number;
};
export type DeleteApiUsersByIdApiResponse = unknown;
export type DeleteApiUsersByIdApiArg = {
  id: number;
};
export type PatchApiUsersByIdApiResponse = unknown;
export type PatchApiUsersByIdApiArg = {
  id: number;
  updateUser: UpdateUser;
};
export type PostApiUsersByIdResetPasswordApiResponse = unknown;
export type PostApiUsersByIdResetPasswordApiArg = {
  id: number;
  resetPasswordRequest: ResetPasswordRequest;
};
export type GetApiUsersByIdTagNotificationSettingsApiResponse =
  /** status 200 OK */ UserTagNotificationSettings;
export type GetApiUsersByIdTagNotificationSettingsApiArg = {
  id: number;
};
export type PatchApiUsersByIdTagNotificationSettingsApiResponse = unknown;
export type PatchApiUsersByIdTagNotificationSettingsApiArg = {
  id: number;
  userTagNotificationUpdate: UserTagNotificationUpdate;
};
export type GetApiVersionApiResponse = /** status 200 OK */ Version;
export type GetApiVersionApiArg = void;
export type Instant = string;
export type AccessAttribute = {
  id?: number;
  name: string;
  jsonSchema: string;
  createdAt: Instant;
};
export type PaginationMetadata = {
  page?: number;
  size?: number;
  totalItems?: number;
  totalPages?: number;
  next?: string | null;
  previous?: string | null;
};
export type PaginatedResponseAccessAttribute = {
  data: AccessAttribute[];
  meta: PaginationMetadata;
};
export type AccessAttributeSortKey = 'NAME' | 'CREATED_AT';
export type SortOrder = 'ASC' | 'DESC';
export type CreateAccessAttribute = {
  name: string;
  jsonSchema: string;
};
export type MatchingAttributes = {
  resourceId?: number;
  matchingAttributeValueIds: number[];
};
export type ResourceType =
  | 'DOCUMENT'
  | 'FOLDER'
  | 'POST'
  | 'GUILD'
  | 'COMPANY'
  | 'EVENT'
  | 'USER_LIST'
  | 'ASSOCIATION'
  | 'CREATE_RESOURCE'
  | 'USER'
  | 'TAG'
  | 'EMAIL_SIGNATURE'
  | 'CHAT_CHANNEL'
  | 'CATEGORY'
  | 'UNKNOWN'
  | 'ALL';
export type CreateAttributeValueByName = {
  attributeName: string;
  value: string;
};
export type OptionalNonNullableString = object;
export type UpdateAccessAttribute = {
  name?: OptionalNonNullableString | null;
  jsonSchema?: OptionalNonNullableString | null;
};
export type AccessAttributeValue = {
  id?: number;
  attributeId?: number;
  name?: string | null;
  value: string;
  createdAt: Instant;
};
export type PaginatedResponseAccessAttributeValue = {
  data: AccessAttributeValue[];
  meta: PaginationMetadata;
};
export type OptionalNullableString = object;
export type UpdateAccessAttributeValue = {
  name?: OptionalNullableString | null;
  value?: OptionalNonNullableString | null;
};
export type Association = {
  id?: number;
  tenantId: string;
  name: string;
  domain: string;
  address: string;
  zip: string;
  city: string;
};
export type PaginatedResponseAssociation = {
  data: Association[];
  meta: PaginationMetadata;
};
export type CreateAssociation = {
  name: string;
  shortName: string;
  address: string;
  zip: string;
  city: string;
  domain: string;
  adminEmail: string;
  adminPassword: string;
  adminFirstName: string;
  adminLastName: string;
  tenantId: string;
  districtCodes: string[];
};
export type AssociationInfo = {
  name: string;
  demoMode?: boolean;
  districtCodes: string[];
};
export type CompanySummary = {
  id?: number;
  name?: string | null;
  city?: string | null;
  postalCode?: string | null;
};
export type PaginatedResponseCompanySummary = {
  data: CompanySummary[];
  meta: PaginationMetadata;
};
export type CompanySortKey = 'NAME' | 'CITY' | 'POSTAL_CODE';
export type CreateGuildMembership = {
  guildId?: number;
  membershipId?: number;
};
export type ExternalGuildMembership = {
  guildId: string;
  membershipId: string;
};
export type ImportMetaData = {
  externalId: string;
  hash: string;
};
export type CreateCompany = {
  name: string;
  address: string;
  city: string;
  postalCode: string;
  phone?: string | null;
  secondaryPhone?: string | null;
  mobile?: string | null;
  fax?: string | null;
  email?: string | null;
  website?: string | null;
  logoUrl?: string | null;
  guildMemberships: CreateGuildMembership[];
  externalMemberships: ExternalGuildMembership[];
  commit?: boolean;
  importMetaData?: ImportMetaData | null;
};
export type Craft1 = {
  id?: number;
  name: string;
  description?: string | null;
  logo?: string | null;
  memberIcon?: string | null;
  headIcon?: string | null;
  viceHeadIcon?: string | null;
  apprenticeHeadIcon?: string | null;
};
export type Guild = {
  id?: number;
  name: string;
  logo?: string | null;
  parentGuildId?: number | null;
  crafts: Craft1[];
};
export type GuildMembershipType = {
  id?: number;
  name: string;
};
export type GuildWithMembership = {
  guild: Guild;
  membership: GuildMembershipType;
};
export type CompanyDetails = {
  id?: number;
  name?: string | null;
  address?: string | null;
  city?: string | null;
  postalCode?: string | null;
  phone?: string | null;
  secondaryPhone?: string | null;
  mobile?: string | null;
  fax?: string | null;
  email?: string | null;
  website?: string | null;
  logoUrl?: string | null;
  guilds: GuildWithMembership[];
};
export type AccessAttributeValueWithAttribute = {
  id?: number;
  attribute: AccessAttribute;
  name?: string | null;
  value: string;
  createdAt: Instant;
};
export type UserSummary = {
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  company?: CompanySummary | null;
  appliedAccessAttributes: AccessAttributeValueWithAttribute[];
  isExternal?: boolean;
  isCompanyUser?: boolean;
  external?: boolean;
  companyUser?: boolean;
};
export type EmailUser = {
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  companyName?: string | null;
  companyLocation?: string | null;
  isExternal?: boolean | null;
  isCompanyUser?: boolean | null;
  external?: boolean | null;
  companyUser?: boolean | null;
  emailValid?: boolean;
};
export type SerialKind = object;
export type SerialDescriptor = {
  serialName: string;
  kind: SerialKind;
  nullable?: boolean;
  inline?: boolean;
  elementsCount?: number;
  annotations: any[];
};
export type KSerializer = {
  descriptor: SerialDescriptor;
};
export type Reject = {
  $cachedSerializer?: KSerializer;
};
export type Accept = {
  $cachedSerializer?: KSerializer;
};
export type Undecided = {
  $cachedSerializer?: KSerializer;
};
export type Override = {
  value?: any;
};
export type Decision =
  | ({
      type: 'Reject';
    } & Reject)
  | ({
      type: 'Accept';
    } & Accept)
  | ({
      type: 'Undecided';
    } & Undecided)
  | ({
      type: 'Override';
    } & Override);
export type FieldUpdateRequestString = {
  old?: string | null;
  new?: string;
  decision: Decision;
};
export type Gender = 'FEMALE' | 'MALE' | 'DIVERSE' | 'UNKNOWN';
export type FieldUpdateRequestGender = {
  old?: Gender | null;
  new?: Gender;
  decision: Decision;
};
export type GroupRolesAttributeValue = {
  groupId?: number;
  roleId?: number;
  location?: string | null;
};
export type FieldUpdateRequestListGroupRolesAttributeValue = {
  old?: GroupRolesAttributeValue[] | null;
  new?: GroupRolesAttributeValue[];
  decision: Decision;
};
export type ExternalGroupRoles = {
  groupId: string;
  roleId: string;
  location?: string | null;
};
export type FieldUpdateRequestListExternalGroupRoles = {
  old?: ExternalGroupRoles[] | null;
  new?: ExternalGroupRoles[];
  decision: Decision;
};
export type ExternalUserUpdateRequest = {
  id?: number;
  user: EmailUser;
  firstName?: FieldUpdateRequestString | null;
  lastName?: FieldUpdateRequestString | null;
  gender?: FieldUpdateRequestGender | null;
  email?: FieldUpdateRequestString | null;
  phone?: FieldUpdateRequestString | null;
  mobile?: FieldUpdateRequestString | null;
  groupRoles?: FieldUpdateRequestListGroupRolesAttributeValue | null;
  externalGroupRoles?: FieldUpdateRequestListExternalGroupRoles | null;
  importMetaData: ImportMetaData;
};
export type ExternalUserDeleteRequest = {
  id?: number;
  user: EmailUser;
  importMetaData: ImportMetaData;
};
export type ExternalUserCreateRequest = {
  id?: number;
  companyId?: number | null;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  gender?: Gender | null;
  phone?: string | null;
  mobile?: string | null;
  groupRoles: GroupRolesAttributeValue[];
  externalGroupRoles: ExternalGroupRoles[];
  importMetaData?: ImportMetaData | null;
};
export type ExternalCompanyChangeRequest =
  | ({
      type: 'ExternalUserUpdateRequest';
    } & ExternalUserUpdateRequest)
  | ({
      type: 'ExternalUserDeleteRequest';
    } & ExternalUserDeleteRequest)
  | ({
      type: 'ExternalUserCreateRequest';
    } & ExternalUserCreateRequest);
export type PaginatedResponseExternalCompanyChangeRequest = {
  data: ExternalCompanyChangeRequest[];
  meta: PaginationMetadata;
};
export type ExternalCompanyChangeResponse = object;
export type UpdateCompany = {
  name?: string | null;
  address?: string | null;
  city?: string | null;
  postalCode?: string | null;
  phone?: string | null;
  secondaryPhone?: string | null;
  mobile?: string | null;
  fax?: string | null;
  email?: string | null;
  website?: string | null;
  logoUrl?: string | null;
  guildMemberships?: CreateGuildMembership[] | null;
  commit?: boolean;
  importMetaData?: ImportMetaData | null;
};
export type SetCompanyAdminRequest = {
  userId?: number;
  adminAccess?: boolean;
};
export type ContentCategoryAccess = {
  categoryId?: number;
  access?: boolean;
};
export type SetUserContentAccessRequest = {
  contentCategoryAccess: ContentCategoryAccess[];
};
export type PaginatedResponseCraft = {
  data: Craft1[];
  meta: PaginationMetadata;
};
export type CreateCraft = {
  name: string;
  description?: string | null;
  logo?: string | null;
  memberIcon?: string | null;
  headIcon?: string | null;
  viceHeadIcon?: string | null;
  apprenticeHeadIcon?: string | null;
};
export type Craft = {
  id?: number | null;
  name?: string | null;
  description?: string | null;
  logo?: string | null;
  memberIcon?: string | null;
  headIcon?: string | null;
  viceHeadIcon?: string | null;
  apprenticeHeadIcon?: string | null;
};
export type DocumentCategory = {
  id?: number;
  name: string;
};
export type CreateOrUpdateDocumentCategory = {
  name: string;
};
export type UpdateDocument = {
  name?: string | null;
  recipients?: {
    accessAttributeValueIds: number[];
    accessAttributes: CreateAttributeValueByName[];
  } | null;
  tags?: string[] | null;
};
export type AppliedAccessAttributes = {
  id?: number;
  appliedAccessAttributes: AccessAttributeValueWithAttribute[];
};
export type LocalDateTime = string;
export type DownloadLink = {
  url: string;
  expiresAt: LocalDateTime;
};
export type CreateDocumentResponse = {
  id?: number;
  uploadUrl: string;
};
export type DocumentItem = {
  id?: number;
  folderId?: number | null;
  createdAt?: LocalDateTime | null;
  type: string;
  name: string;
  icon?: string | null;
  categoryId?: number | null;
};
export type PaginatedResponseDocumentItem = {
  data: DocumentItem[];
  meta: PaginationMetadata;
};
export type DocumentSortKey = 'NAME' | 'CREATED_AT';
export type CreateFolder = {
  name: string;
  icon?: string | null;
  tags: string[];
};
export type UpdateFolder = {
  name?: string | null;
  icon?: string | null;
  tags?: string[] | null;
};
export type Recipients = {
  accessAttributeValueIds: number[];
  accessAttributes: CreateAttributeValueByName[];
};
export type CreateDocument = {
  name: string;
  recipients: Recipients;
  tags: string[];
};
export type Document = {
  id?: number;
  groupId?: number;
  name: string;
  tags: string[];
};
export type DocumentGroup = {
  id?: number;
  parentGroupId?: number | null;
  name: string;
  category?: string | null;
  icon?: string | null;
  documents: Document[];
};
export type EmailSignature = {
  id?: number | null;
  associationId?: number;
  name: string;
  signature?: string | null;
  title?: string | null;
  organizationName?: string | null;
  street?: string | null;
  city?: string | null;
  postalCode?: string | null;
  country?: string | null;
  phone?: string | null;
  mobile?: string | null;
  email: string;
  replyTo?: string | null;
  website?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  linkedin?: string | null;
  instagram?: string | null;
  youtube?: string | null;
  representatives?: string | null;
  legal?: string | null;
  logoUrl?: string | null;
  logoWidth?: number | null;
  logoHeight?: number | null;
  createdAt: Instant;
};
export type CreateEmailSignature = {
  name: string;
  email: string;
};
export type UpdateEmailSignature = {
  name?: string | null;
  title?: string | null;
  organizationName?: string | null;
  street?: string | null;
  city?: string | null;
  postalCode?: string | null;
  country?: string | null;
  phone?: string | null;
  mobile?: string | null;
  email?: string | null;
  website?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  linkedin?: string | null;
  instagram?: string | null;
  youtube?: string | null;
  representatives?: string | null;
  legal?: string | null;
  logoUrl?: string | null;
  logoWidth?: number | null;
  logoHeight?: number | null;
  signature?: string | null;
  replyTo?: string | null;
};
export type EmailTemplateType =
  | 'POST'
  | 'SERVICE'
  | 'PASSWORD_RESET_LINK'
  | 'PASSWORD_NEW_LINK'
  | 'MAGIC_LINK';
export type EmailTemplate = {
  id?: number;
  associationId?: number;
  name: string;
  description?: string | null;
  text: string;
  html: string;
  createdAt: LocalDateTime;
  active?: boolean;
  type: EmailTemplateType;
};
export type CreateEmailTemplate = {
  name: string;
  description?: string | null;
  text: string;
  html: string;
  type: EmailTemplateType;
};
export type UpdateEmailTemplate = {
  name?: string | null;
  description?: string | null;
  text?: string | null;
  html?: string | null;
};
export type EventType = {
  id?: number;
  name: string;
};
export type Location = {
  id?: number | null;
  name: string;
  streetAddress: string;
  locality: string;
  postalCode: string;
  lat?: number | null;
  lng?: number | null;
  plusCode?: string | null;
};
export type LocalDate = string;
export type AttendeeStatus =
  | 'ATTENDING'
  | 'NOT_ATTENDING'
  | 'MAYBE_ATTENDING'
  | 'NO_ANSWER';
export type AttendeesInfo = {
  numInvitations?: number;
  numAttending?: number;
  numNotAttending?: number;
  numMaybeAttending?: number;
  numNoAnswer?: number;
};
export type EventSummary = {
  id?: number;
  name: string;
  description?: string | null;
  eventType: EventType;
  onlineMeetingUrl?: string | null;
  startAt: Instant;
  endAt?: Instant | null;
  location?: Location | null;
  postId?: number;
  startAtDay?: LocalDate | null;
  endAtDay?: LocalDate | null;
  responseDeadline?: Instant | null;
  myAttendeeStatus: AttendeeStatus;
  attendeesInfo?: AttendeesInfo | null;
  attendeeStatusAccessAttribute?: AccessAttribute | null;
};
export type PaginatedResponseEventSummary = {
  data: EventSummary[];
  meta: PaginationMetadata;
};
export type Survey = {
  id?: number;
  title?: string | null;
  json: string;
  responseDeadline: Instant;
  createdAt: Instant;
  creator?: EmailUser | null;
  isTemplate?: boolean;
};
export type SurveyResponse = {
  id?: number;
  user: EmailUser;
  json: string;
  createdAt: Instant;
  updatedAt: Instant;
  publishedAt?: Instant | null;
};
export type SurveyWithResponse = {
  survey: Survey;
  response?: SurveyResponse | null;
  token: string;
};
export type AttendResponse = {
  user: EmailUser;
  status: AttendeeStatus;
  surveyWithResponse?: SurveyWithResponse | null;
};
export type AttendWithToken = {
  token: string;
};
export type PaginatedResponseAttendResponse = {
  data: AttendResponse[];
  meta: PaginationMetadata;
};
export type UserSortKey =
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'EMAIL'
  | 'COMPANY'
  | 'GUILD'
  | 'ROLE';
export type AttendRequest = {
  attendeeId?: number | null;
  attend: AttendeeStatus;
};
export type Fruit = {
  id?: number | null;
  name: string;
};
export type PaginatedResponseGuild = {
  data: Guild[];
  meta: PaginationMetadata;
};
export type CreateGuild = {
  name: string;
  logo?: string | null;
  parentGuildId?: number | null;
  craftIds: number[];
  commit?: boolean;
  importMetaData?: ImportMetaData | null;
};
export type CreateMembershipType = {
  name: string;
  importMetaData?: ImportMetaData | null;
};
export type UpdateGuild = {
  id?: number | null;
  name?: string | null;
  logo?: string | null;
  parentGuildId?: string | null;
  craftIds?: number[] | null;
  commit?: boolean;
  importMetaData?: ImportMetaData | null;
};
export type PaginatedResponseUserSummary = {
  data: UserSummary[];
  meta: PaginationMetadata;
};
export type Hello = {
  message: string;
};
export type CreateImageResponse = {
  id: string;
  uploadUrl: string;
  downloadUrl: string;
};
export type CreateImage = {
  name: string;
};
export type UserListInfo = {
  id?: number;
  name: string;
  description?: string | null;
  createdAt: LocalDateTime;
  creator: EmailUser;
  accessAttributeValues: AccessAttributeValue[];
  signature?: EmailSignature | null;
  chatEnabled?: boolean;
  chatChannelId?: string | null;
};
export type PaginatedResponseUserListInfo = {
  data: UserListInfo[];
  meta: PaginationMetadata;
};
export type CreateUserList = {
  name: string;
  description?: string | null;
  recipients: Recipients;
  ephemeral?: boolean;
  signatureId?: number | null;
  chatEnabled?: boolean;
};
export type UpdateUserListInfo = {
  name?: string | null;
  description?: string | null;
  recipients?: {
    accessAttributeValueIds: number[];
    accessAttributes: CreateAttributeValueByName[];
  } | null;
  signatureId?: number | null;
  chatEnabled?: boolean | null;
};
export type PaginatedResponseEmailUser = {
  data: EmailUser[];
  meta: PaginationMetadata;
};
export type Policy = {
  id?: number;
  name: string;
  description?: string | null;
  action: string;
  resourceType: ResourceType;
  rule: string;
};
export type PaginatedResponsePolicy = {
  data: Policy[];
  meta: PaginationMetadata;
};
export type PolicySortKey = 'NAME' | 'ACTION' | 'RESOURCE_TYPE';
export type CreatePolicy = {
  name: string;
  description?: string | null;
  action: string;
  resourceType: ResourceType;
  rule: string;
};
export type OptionalNonNullableResourceType = object;
export type UpdatePolicy = {
  name?: OptionalNonNullableString | null;
  description?: OptionalNullableString | null;
  action?: OptionalNonNullableString | null;
  resourceType?: OptionalNonNullableResourceType | null;
  rule?: OptionalNonNullableString | null;
};
export type Tag = {
  id?: number;
  name: string;
  color: string;
  associationId?: number;
  mandatoryNotification?: boolean;
  active?: boolean;
  createdAt: Instant;
};
export type PostType = 'REGULAR' | 'UPDATE' | 'REMINDER' | 'ARTICLE';
export type EditorType = 'QUILL' | 'BLOCKNOTE';
export type PostSummary = {
  id?: number;
  title: string;
  bodyText: string;
  bodyHtml: string;
  bodyQuill: string;
  author: EmailUser;
  category: DocumentCategory;
  createdAt: LocalDateTime;
  numComments?: number;
  numViews?: number;
  numVotes?: number;
  numAttachments?: number;
  hasEvent?: boolean;
  publishedAt?: LocalDateTime | null;
  imageUrl?: string | null;
  iLiked?: boolean;
  parentPostId?: number | null;
  publishDelayedAt?: Instant | null;
  tags: Tag[];
  teaser?: string | null;
  hasSurvey?: boolean;
  type: PostType;
  feedText?: string | null;
  hasChildren?: boolean;
  organizationName?: string | null;
  editorType: EditorType;
};
export type PaginatedResponsePostSummary = {
  data: PostSummary[];
  meta: PaginationMetadata;
};
export type PostStatus = 'DRAFT' | 'PUBLISHED';
export type EventAttendeeListAccess = 'PUBLIC' | 'PRIVATE' | 'INTERNAL';
export type CreateEvent = {
  name: string;
  description?: string | null;
  eventTypeId?: number;
  onlineMeetingUrl?: string | null;
  startAt: Instant;
  endAt: Instant;
  location?: Location | null;
  startAtDay?: LocalDate | null;
  endAtDay?: LocalDate | null;
  responseDeadline?: Instant | null;
  attendeeListAccess: EventAttendeeListAccess;
};
export type PostSection = {
  id: string;
  content: string;
};
export type CreatePost = {
  title: string;
  bodyText: string;
  bodyHtml: string;
  bodyQuill: string;
  categoryId?: number | null;
  event?: CreateEvent | null;
  attachments: number[];
  recipients: {
    accessAttributeValueIds: number[];
    accessAttributes: CreateAttributeValueByName[];
  };
  imageUrl?: string | null;
  emailTemplateId?: number | null;
  emailSignatureId?: number | null;
  parentId?: number | null;
  tagIds: number[];
  publishDelayedAt?: Instant | null;
  teaser?: string | null;
  type: PostType;
  feedText?: string | null;
  editorType: EditorType;
  sections?: PostSection[] | null;
};
export type PostDetails = {
  id?: number;
  title: string;
  bodyText: string;
  bodyHtml: string;
  bodyQuill: string;
  author: EmailUser;
  category: DocumentCategory;
  createdAt: LocalDateTime;
  numComments?: number;
  numViews?: number;
  numVotes?: number;
  publishedAt?: LocalDateTime | null;
  imageUrl?: string | null;
  event?: EventSummary | null;
  recipients?: {
    accessAttributeValueIds: number[];
    accessAttributes: CreateAttributeValueByName[];
  } | null;
  attributes: AccessAttributeValueWithAttribute[];
  iLiked?: boolean;
  emailTemplate?: EmailTemplate | null;
  signature?: EmailSignature | null;
  parentPostId?: number | null;
  tags: Tag[];
  publishDelayedAt?: Instant | null;
  teaser?: string | null;
  survey?: Survey | null;
  type: PostType;
  feedText?: string | null;
  editorType: EditorType;
  numChildren?: number;
};
export type UpdatePost = {
  title?: string | null;
  bodyText?: string | null;
  bodyHtml?: string | null;
  bodyQuill?: string | null;
  categoryId?: number | null;
  event?: {
    name?: string | null;
    description?: string | null;
    eventTypeId?: number | null;
    onlineMeetingUrl?: string | null;
    startAt?: string | null;
    endAt?: string | null;
    location?: {
      name?: string | null;
      streetAddress?: string | null;
      locality?: string | null;
      postalCode?: string | null;
      lat?: number | null;
      lng?: number | null;
      plusCode?: string | null;
    } | null;
    startAtDay?: string | null;
    endAtDay?: string | null;
    responseDeadline?: string | null;
  } | null;
  recipients?: {
    accessAttributeValueIds: number[];
    accessAttributes: CreateAttributeValueByName[];
  } | null;
  emailTemplateId?: number | null;
  imageUrl?: string | null;
  attachments?: number[] | null;
  emailSignatureId?: number | null;
  tagIds?: number[] | null;
  publishDelayedAt?: string | null;
  teaser?: string | null;
  surveyId?: number | null;
  feedText?: string | null;
  sections?: PostSection[] | null;
};
export type PostSearchRecord = {
  id?: number;
  title: string;
  teaser: string;
  feedText: string;
  content: string;
  sections: PostSection[];
};
export type PaginatedResponsePostSearchRecord = {
  data: PostSearchRecord[];
  meta: PaginationMetadata;
};
export type PostAttachment = {
  id?: number;
  name: string;
  url: string;
  icon: string;
};
export type PaginatedResponsePostAttachment = {
  data: PostAttachment[];
  meta: PaginationMetadata;
};
export type PostNameAndId = {
  id?: number;
  name: string;
  parentId?: number | null;
};
export type PostComment = {
  id?: number;
  postId?: number;
  body: string;
  parentId?: number | null;
  author: EmailUser;
  createdAt: LocalDateTime;
  deletedAt?: LocalDateTime | null;
  iLiked?: boolean;
};
export type PaginatedResponsePostComment = {
  data: PostComment[];
  meta: PaginationMetadata;
};
export type CreateComment = {
  body: string;
  postId?: number;
  parentId?: number | null;
};
export type Action =
  | 'READ'
  | 'WRITE'
  | 'DELETE'
  | 'CREATE'
  | 'UPDATE'
  | 'LIST'
  | 'READ_METADATA'
  | 'READ_DRAFT'
  | 'PUBLISH'
  | 'COMMENT'
  | 'VOTE'
  | 'COMPANY_SET_ADMIN'
  | 'COMPANY_EDIT_MEMBERSHIP'
  | 'USER_UPDATE_ACCESS_ATTRIBUTES'
  | 'JOIN'
  | 'EVENT_READ_ATTENDEES'
  | 'EVENT_ATTEND'
  | 'EVENT_SET_ATTENDEE_STATUS'
  | 'USER_UPDATE_REQUEST'
  | 'COMPANY_UPDATE_REQUEST'
  | 'CREATE_REQUEST'
  | 'GUILD_UPDATE_REQUEST';
export type PostNotificationError = {
  type: string;
  message: string;
  description?: string | null;
  createdAt: LocalDateTime;
};
export type PostNotification = {
  id?: number;
  userId?: number;
  type: string;
  queuedAt: LocalDateTime;
  sentAt?: LocalDateTime | null;
  receivedAt?: LocalDateTime | null;
  readAt?: LocalDateTime | null;
  errors: PostNotificationError[];
};
export type NotificationSettings = {
  tagId?: number | null;
  email?: boolean;
  cloudMessage?: boolean;
  fax?: boolean;
  postal?: boolean;
};
export type PostRecipient = {
  user: EmailUser;
  notifications: PostNotification[];
  notificationSettings?: NotificationSettings | null;
};
export type PaginatedResponsePostRecipient = {
  data: PostRecipient[];
  meta: PaginationMetadata;
};
export type UserEmailFilter = 'ALL' | 'HAS_EMAIL' | 'HAS_NO_EMAIL';
export type EmailUserSortKey =
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'EMAIL'
  | 'COMPANY'
  | 'LOCATION';
export type PostRecipientsSummary = {
  type: string;
  numSent?: number;
  numReceived?: number;
  numRead?: number | null;
};
export type PostRecipientSummaries = {
  total?: PostRecipientsSummary | null;
  email?: PostRecipientsSummary | null;
  cloudMessage?: PostRecipientsSummary | null;
};
export type Vote = {
  vote?: boolean;
};
export type Role = {
  id?: number;
  name: string;
};
export type PaginatedResponseRole = {
  data: Role[];
  meta: PaginationMetadata;
};
export type CreateRole = {
  name: string;
};
export type ImportUserListFilter = {
  guildId?: number | null;
  roleId?: number | null;
};
export type ImportAccess = {
  hash: string;
  filters: ImportUserListFilter[];
};
export type ImportDocument = {
  name: string;
  file: string;
  groupId?: number;
  createdAt: Instant;
  expiresAt?: Instant | null;
  access?: ImportAccess | null;
  associationId?: number | null;
};
export type ImportDocumentsDto = {
  documents: ImportDocument[];
};
export type ImportEvent = {
  address?: string | null;
  locality?: string | null;
  postalCode?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  startAt: Instant;
  endAt: Instant;
};
export type ImportPost = {
  associationId?: number;
  authorId?: number;
  title: string;
  bodyHtml: string;
  bodyText?: string | null;
  bodyQuill?: string | null;
  image?: string | null;
  event?: ImportEvent | null;
  documents: ImportDocument[];
  access: ImportAccess;
  createdAt: Instant;
  expiresAt?: Instant | null;
};
export type ImportDto = {
  posts: ImportPost[];
};
export type PubsubMessage = {
  data: string;
  messageId: string;
  message_id: string;
  publishTime: string;
  publish_time: string;
  attributes: {
    [key: string]: string;
  };
  orderingKey?: string | null;
};
export type MessageWrapper = {
  message: PubsubMessage;
  subscription: string;
};
export type ExternalUserChangeRequest =
  | ({
      type: 'ExternalUserUpdateRequest';
    } & ExternalUserUpdateRequest)
  | ({
      type: 'ExternalUserDeleteRequest';
    } & ExternalUserDeleteRequest)
  | ({
      type: 'ExternalUserCreateRequest';
    } & ExternalUserCreateRequest);
export type CreateSurvey = {
  title?: string | null;
  json: string;
  responseDeadline: Instant;
  isTemplate?: boolean;
};
export type CreateOrUpdateSurveyResponseWithToken = {
  json: string;
  publish?: boolean;
  token: string;
};
export type PaginatedResponseSurvey = {
  data: Survey[];
  meta: PaginationMetadata;
};
export type SurveySortKey = 'TITLE' | 'CREATED_AT';
export type OptionalNonNullableBoolean = object;
export type UpdateSurvey = {
  title?: string | null;
  json?: string | null;
  responseDeadline?: string | null;
  isTemplate?: OptionalNonNullableBoolean | null;
};
export type SurveyResponseListItem = {
  id?: number;
  user: EmailUser;
  json: string;
  createdAt: Instant;
  updatedAt: Instant;
  publishedAt?: Instant | null;
};
export type SurveyResponseList = {
  items: SurveyResponseListItem[];
  totalRecipients?: number;
};
export type CreateSurveyResponse = {
  json: string;
  publish?: boolean;
  userId?: number | null;
};
export type PaginatedResponseSurveyResponseListItem = {
  data: SurveyResponseListItem[];
  meta: PaginationMetadata;
};
export type SurveyResponseSortKey =
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'COMPANY_NAME'
  | 'CREATED_AT'
  | 'UPDATED_AT';
export type GenerateTextResponse = {
  text: string;
};
export type GenerateText = {
  text: string;
};
export type UserFilter = 'ALL' | 'EXTERNAL' | 'INTERNAL';
export type AppliedAttributeValues = {
  accessAttributeValueIds: number[];
  accessAttributes: CreateAttributeValueByName[];
};
export type CreateUser = {
  companyId: number | null;
  gender: Gender;
  firstName: string;
  lastName: string;
  email: string;
  password?: string | null;
  phone?: string | null;
  mobile?: string | null;
  appliedAttributeValues: AppliedAttributeValues;
  notificationSettings: NotificationSettings;
  isExternal?: boolean;
  isCompanyUser?: boolean;
  externalCompanyId?: string | null;
  externalGroupRoles: ExternalGroupRoles[];
  commit?: boolean;
  importMetaData?: ImportMetaData | null;
  external?: boolean;
  companyUser?: boolean;
};
export type UserTagNotificationSetting = {
  tagId?: number;
  name: string;
  color: string;
  mandatoryNotification?: boolean;
  notifyEmail?: boolean;
  notifyCloudMessage?: boolean;
  notifyFax?: boolean;
  notifyPostal?: boolean;
};
export type UserDetails = {
  id?: number;
  gender: Gender;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  mobile?: string | null;
  company?: CompanySummary | null;
  appliedAccessAttributes: AccessAttributeValueWithAttribute[];
  defaultNotificationSettings: NotificationSettings;
  tagNotificationSettings: UserTagNotificationSetting[];
  isExternal?: boolean;
  chatToken?: string | null;
  isCompanyUser?: boolean;
  external?: boolean;
  companyUser?: boolean;
};
export type OptionalNonNullableListExternalGroupRoles = object;
export type UpdateUser = {
  id?: number | null;
  gender?: string | null;
  firstName: string | null;
  lastName: string | null;
  phone?: string | null;
  mobile?: string | null;
  appliedAttributeValues?: {
    accessAttributeValueIds: number[];
    accessAttributes: CreateAttributeValueByName[];
  } | null;
  notificationSettings?: NotificationSettings;
  isCompanyUser?: boolean | null;
  externalGroupRoles?: OptionalNonNullableListExternalGroupRoles | null;
  commit?: boolean;
  importMetaData?: ImportMetaData | null;
};
export type ChatToken = {
  token: string;
};
export type SetCloudMessageToken = {
  token: string;
};
export type ResetPasswordType = 'RESET' | 'NEW_USER';
export type ResetPasswordRequest = {
  continuePath?: string | null;
  type: ResetPasswordType;
};
export type UserTagNotificationSettings = {
  userId?: number;
  notificationSettings: UserTagNotificationSetting[];
};
export type UserTagNotificationUpdate = {
  tagId?: number;
  notifyEmail?: boolean;
  notifyCloudMessage?: boolean;
  notifyFax?: boolean;
  notifyPostal?: boolean;
};
export type RecoverPasswordRequest = {
  email: string;
  tenantId: string;
  continuePath?: string | null;
  type: ResetPasswordType;
};
export type SignInWithEmailLink = {
  tenantId: string;
  email: string;
  continuePath?: string | null;
};
export type TenantInfo = {
  name: string;
  tenantId: string;
};
export type PaginatedResponseExternalUserChangeRequest = {
  data: ExternalUserChangeRequest[];
  meta: PaginationMetadata;
};
export type ExternalUserChangeResponse = object;
export type Version = {
  version: string;
  tag: string;
  head: string;
};
export const {
  useGetApiAccessAttributesQuery,
  usePostApiAccessAttributesMutation,
  useGetApiAccessAttributesMatchingAttributeValuesByTenantIdQuery,
  useGetApiAccessAttributesMatchingAttributeValuesByTenantIdAndIdQuery,
  usePostApiAccessAttributesValuesMutation,
  useGetApiAccessAttributesByIdQuery,
  useDeleteApiAccessAttributesByIdMutation,
  usePatchApiAccessAttributesByIdMutation,
  useGetApiAccessAttributesByIdValuesQuery,
  useGetApiAccessAttributesByIdValuesAndValueIdQuery,
  useDeleteApiAccessAttributesByIdValuesAndValueIdMutation,
  usePatchApiAccessAttributesByIdValuesAndValueIdMutation,
  useGetApiAssociationQuery,
  usePostApiAssociationMutation,
  useGetApiAssociationAdminAccessAttributeValueQuery,
  useGetApiAssociationInfoQuery,
  useGetApiChatDevicesQuery,
  useGetApiChatTokenQuery,
  useGetApiCompaniesQuery,
  usePostApiCompaniesMutation,
  useGetApiCompaniesByExternalIdByExternalIdQuery,
  useGetApiCompaniesMyQuery,
  useGetApiCompaniesMyUsersQuery,
  useGetApiCompaniesUpdateRequestsQuery,
  useGetApiCompaniesUpdateRequestsByExternalIdAndHashQuery,
  usePostApiCompaniesUpdateRequestsByIdMutation,
  useGetApiCompaniesByIdQuery,
  useDeleteApiCompaniesByIdMutation,
  usePatchApiCompaniesByIdMutation,
  useGetApiCompaniesByIdAccessAttributeValuesQuery,
  usePostApiCompaniesByIdSetAdminMutation,
  usePostApiCompaniesByIdSetContentAccessAndUserIdMutation,
  useGetApiCompaniesByIdUsersQuery,
  useGetApiCraftQuery,
  usePostApiCraftMutation,
  useGetApiCraftByIdQuery,
  usePatchApiCraftByIdMutation,
  useGetApiDocumentCategoriesQuery,
  usePostApiDocumentCategoriesMutation,
  usePatchApiDocumentCategoriesByIdMutation,
  usePutApiDocumentFilesByIdMutation,
  useDeleteApiDocumentFilesByIdMutation,
  useGetApiDocumentFilesByIdAccessQuery,
  useGetApiDocumentFilesByIdDownloadMutation,
  useGetApiDocumentFilesByIdReplaceMutation,
  useGetApiDocumentFoldersQuery,
  usePostApiDocumentFoldersMutation,
  usePutApiDocumentFoldersByIdMutation,
  useDeleteApiDocumentFoldersByIdMutation,
  useGetApiDocumentFoldersByIdAccessQuery,
  useGetApiDocumentFoldersByIdContentsQuery,
  usePostApiDocumentFoldersByIdFilesMutation,
  usePostApiDocumentFoldersByIdFoldersMutation,
  useGetApiDocumentFoldersByIdParentsQuery,
  useGetApiEmailSignaturesQuery,
  usePostApiEmailSignaturesMutation,
  useGetApiEmailSignaturesByIdQuery,
  useDeleteApiEmailSignaturesByIdMutation,
  usePatchApiEmailSignaturesByIdMutation,
  useGetApiEmailTemplatesQuery,
  usePostApiEmailTemplatesMutation,
  useGetApiEmailTemplatesByIdQuery,
  usePatchApiEmailTemplatesByIdMutation,
  useGetApiEventsQuery,
  usePostApiEventsAttendMutation,
  useGetApiEventsAttendeeStatusAttributeQuery,
  useGetApiEventsTypesQuery,
  useGetApiEventsByIdAttendeesQuery,
  usePostApiEventsByIdAttendeesMutation,
  useGetApiEventsByIdAttendeesExcelQuery,
  useGetApiEventsByIdIcsQuery,
  useGetApiFruitsQuery,
  useGetApiGuildQuery,
  usePostApiGuildMutation,
  useGetApiGuildAccessAttributeValueQuery,
  useGetApiGuildMembershipTypesQuery,
  usePostApiGuildMembershipTypesMutation,
  useGetApiGuildMembershipTypesByIdQuery,
  useDeleteApiGuildMembershipTypesByIdMutation,
  usePatchApiGuildMembershipTypesByIdMutation,
  useGetApiGuildByIdQuery,
  useDeleteApiGuildByIdMutation,
  usePatchApiGuildByIdMutation,
  useGetApiGuildByIdCompaniesQuery,
  useGetApiGuildByIdMembersQuery,
  useGetApiHelloQuery,
  usePostApiImagesMutation,
  useGetApiListsQuery,
  usePostApiListsMutation,
  useGetApiListsByIdQuery,
  useDeleteApiListsByIdMutation,
  usePatchApiListsByIdMutation,
  useGetApiListsByIdChatUsersQuery,
  useGetApiListsByIdUsersQuery,
  useGetApiPolicyQuery,
  usePostApiPolicyMutation,
  useGetApiPolicyByIdQuery,
  useDeleteApiPolicyByIdMutation,
  usePatchApiPolicyByIdMutation,
  useGetApiPostsQuery,
  usePostApiPostsMutation,
  useGetApiPostsByAttributeValueByAttributeValueIdQuery,
  useGetApiPostsDraftsQuery,
  useGetApiPostsDraftsRootQuery,
  useGetApiPostsDraftsByIdQuery,
  usePatchApiPostsDraftsByIdMutation,
  useGetApiPostsPlannedQuery,
  useGetApiPostsPlannedByIdQuery,
  useGetApiPostsRootQuery,
  useGetApiPostsSearchQuery,
  useGetApiPostsSummaryByIdQuery,
  useGetApiPostsByIdQuery,
  useDeleteApiPostsByIdMutation,
  usePatchApiPostsByIdMutation,
  useGetApiPostsByIdAttachmentsQuery,
  useGetApiPostsByIdBreadcrumbsQuery,
  useGetApiPostsByIdCommentsQuery,
  usePostApiPostsByIdCommentsMutation,
  useGetApiPostsByIdEmailSignatureQuery,
  useGetApiPostsByIdEmailTemplateQuery,
  useGetApiPostsByIdMatchingAccessAttributeValueIdsQuery,
  useGetApiPostsByIdParentsQuery,
  usePostApiPostsByIdPublishMutation,
  useGetApiPostsByIdRecipientsQuery,
  useGetApiPostsByIdRecipientsSummaryQuery,
  useGetApiPostsByIdRecipientsExcelQuery,
  useGetApiPostsByIdResendAndNotificationIdMutation,
  usePostApiPostsByIdVoteMutation,
  useGetApiRolesQuery,
  usePostApiRolesMutation,
  useGetApiRolesByRoleIdQuery,
  usePatchApiRolesByRoleIdMutation,
  useGetApiSchedulerAbacCacheDocumentsByTenantIdQuery,
  useGetApiSchedulerAbacCacheFoldersByTenantIdQuery,
  useGetApiSchedulerAbacCachePostsByTenantIdQuery,
  usePostApiSchedulerAssociationMutation,
  usePostApiSchedulerChatSyncByTenantIdMutation,
  usePostApiSchedulerCompaniesUpdateRequestsByTenantIdMutation,
  useGetApiSchedulerEmailSenderQuery,
  usePostApiSchedulerImportDocumentsMutation,
  usePostApiSchedulerImportPostsMutation,
  useGetApiSchedulerImportAwsPostsQuery,
  useGetApiSchedulerMailgunEventsQuery,
  useGetApiSchedulerMailgunEventsPollByTenantIdQuery,
  usePostApiSchedulerMeilisearchCompaniesByTenantIdMutation,
  usePostApiSchedulerMeilisearchPostTypesByTenantIdMutation,
  usePostApiSchedulerMeilisearchPostsByTenantIdMutation,
  usePostApiSchedulerMeilisearchSetupMutation,
  useGetApiSchedulerPlannedPostsPublishQuery,
  useGetApiSchedulerPlannedPostsPublishByTenantIdAndPostIdQuery,
  usePostApiSchedulerStorageDocumentUploadMutation,
  useGetApiSchedulerTasksTestQuery,
  usePostApiSchedulerTasksTestMutation,
  usePostApiSchedulerUsersUpdateRequestsByTenantIdMutation,
  useGetApiSecuredAdminOnlyQuery,
  useGetApiSecuredPermitAllQuery,
  usePostApiSurveysMutation,
  useGetApiSurveysByTokenByTokenQuery,
  usePostApiSurveysRespondMutation,
  useGetApiSurveysTemplatesQuery,
  useGetApiSurveysByIdQuery,
  useDeleteApiSurveysByIdMutation,
  usePatchApiSurveysByIdMutation,
  useGetApiSurveysByIdAllResponsesQuery,
  useGetApiSurveysByIdResponseQuery,
  usePostApiSurveysByIdResponseMutation,
  usePatchApiSurveysByIdResponseMutation,
  useGetApiSurveysByIdResponseByUserAndUserIdQuery,
  usePatchApiSurveysByIdResponseAndResponseIdMutation,
  useGetApiSurveysByIdResponsesQuery,
  useGetApiTagsQuery,
  usePostApiTagsMutation,
  useDeleteApiTagsByIdMutation,
  usePatchApiTagsByIdMutation,
  usePostApiTextGeneratorFeedMessageMutation,
  usePostApiTextGeneratorTeaserMutation,
  useGetApiUsersQuery,
  usePostApiUsersMutation,
  useGetApiUsersMeQuery,
  usePatchApiUsersMeMutation,
  useGetApiUsersMeChatTokenQuery,
  usePostApiUsersMeCloudMessageTokenMutation,
  usePostApiUsersMeResetPasswordMutation,
  useGetApiUsersMeTagNotificationSettingsQuery,
  usePatchApiUsersMeTagNotificationSettingsMutation,
  usePostApiUsersRecoverPasswordMutation,
  usePostApiUsersSignInWithEmailLinkMutation,
  useGetApiUsersTenantQuery,
  useGetApiUsersUnsubscribeByTokenMutation,
  useGetApiUsersUpdateRequestsQuery,
  usePostApiUsersUpdateRequestsByIdMutation,
  useGetApiUsersByIdQuery,
  useDeleteApiUsersByIdMutation,
  usePatchApiUsersByIdMutation,
  usePostApiUsersByIdResetPasswordMutation,
  useGetApiUsersByIdTagNotificationSettingsQuery,
  usePatchApiUsersByIdTagNotificationSettingsMutation,
  useGetApiVersionQuery
} = injectedRtkApi;
