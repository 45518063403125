import {
  extractCompanyAdmin,
  extractTenantAdmin,
  extractUserCategories,
  extractUserGroupRoles
} from 'src/abac/extract';
import { UserDetails } from 'src/api/deinverband-api';
import { Profile } from 'src/types/user';
import { getGender } from 'src/utils/get-gender';
import { mapCompanySummaryToCompany } from './company-summary-to-company';

export const mapUserDetailsToProfile = (
  user: UserDetails | undefined
): Profile | undefined => {
  if (!user || user.id === undefined || !user.email) return undefined;

  return {
    id: user.id,
    email: user.email,
    gender: getGender(user.gender),
    firstName: user.firstName || undefined,
    lastName: user.lastName || undefined,
    contact: {
      phone: user.phone || undefined,
      mobile: user.mobile || undefined
    },
    company: mapCompanySummaryToCompany(user.company || undefined),
    groupRoles: extractUserGroupRoles(user.appliedAccessAttributes),
    categoryAccess: user.company?.id
      ? extractUserCategories(user.company.id, user.appliedAccessAttributes)
      : [],
    external: !!user.isExternal,
    companyAccount: !!user.isCompanyUser,
    defaultNotifications: user.defaultNotificationSettings,
    tagNotifications: user.tagNotificationSettings,
    companyAdmin: extractCompanyAdmin(
      user.company?.id,
      user.appliedAccessAttributes
    ),
    tenantAdmin: extractTenantAdmin(user.appliedAccessAttributes)
  };
};
