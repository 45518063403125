import { Messaging, getMessaging, isSupported } from '@firebase/messaging';
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import {
  Auth,
  browserLocalPersistence,
  getAuth,
  setPersistence
} from 'firebase/auth';

let firebaseApp: FirebaseApp | null = null;
let firebaseAuth: Auth | null = null;
let firebaseMessaging: Messaging | null = null;

export const firebaseGetApp = (): FirebaseApp => {
  if (firebaseApp === null) throw new Error('Firebase (app) not initialized');
  return firebaseApp;
};

export const firebaseGetAuth = (): Auth => {
  if (firebaseAuth === null) throw new Error('Firebase (auth) not initialized');
  return firebaseAuth;
};

export const firebaseGetMessaging = (): Messaging | null => firebaseMessaging;

export const firebaseInit = async (
  options: FirebaseOptions,
  tenantId?: string
): Promise<void> => {
  firebaseApp = initializeApp(options);
  firebaseAuth = getAuth(firebaseApp);
  firebaseAuth.tenantId = tenantId || null;
  // adjust login persistence
  await setPersistence(firebaseAuth, browserLocalPersistence);
  // get messaging if supported
  if (await isSupported()) firebaseMessaging = getMessaging(firebaseApp);
};
