import { Icon } from '@iconify/react';
import { styled } from '@mui/material/styles';

export const InlineIcon = styled(Icon)(({ theme }) => ({
  fontSize: 'small',
  marginTop: '-0.25em',
  marginRight: '0.5em',
  marginLeft: '0.5em',
  verticalAlign: 'middle'
}));
