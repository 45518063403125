import React, { useEffect } from 'react';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useNavigate } from 'react-router';

// inject navigation logic for deep links
const AppUrlListener: React.FC<any> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // TODO: maybe make this more robust
      const relative = event.url.split('.app').pop();
      if (relative) navigate(relative);
    });
  }, [navigate]);

  return null;
};

export default AppUrlListener;
