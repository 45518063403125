import { Gender, genderData, genders } from 'src/types/user';

export function getGender(gender: string): Gender;
export function getGender(gender: string | undefined): Gender | undefined;
export function getGender(gender: string | undefined): Gender | undefined {
  return gender === 'MALE'
    ? 'male'
    : gender === 'FEMALE'
    ? 'female'
    : gender === 'DIVERSE'
    ? 'diverse'
    : undefined;
}

export function getGenderName(gender: number | Gender): string;
export function getGenderName(
  gender: number | Gender | undefined
): string | undefined;
export function getGenderName(
  gender: number | Gender | undefined
): string | undefined {
  if (typeof gender === 'number') gender = genders[gender];
  // retrieve actual gender name
  return gender && genderData[gender];
}
