import type { FC } from 'react';
import { Icon } from '@iconify/react';
import { Avatar, Box, ButtonBase } from '@mui/material';
import { SimpleSnackbar } from 'src/components/simple-snackbar';
import { useAsyncCallback } from 'src/hooks/use-async-callback';
import { useAuth } from 'src/hooks/use-auth';
import { useNotifications } from 'src/hooks/use-notifications';
import { usePopover } from 'src/hooks/use-popover';
import { useRouter } from 'src/hooks/use-router';
import { icons } from 'src/theme/icons';
import { Issuer } from 'src/utils/auth';
import { AccountPopover } from './account-popover';

export const AccountButton: FC = () => {
  const { user } = useAuth();

  const popover = usePopover<HTMLButtonElement>();
  const router = useRouter();
  const auth = useAuth();
  const notifications = useNotifications();

  const [handleLogout, { error, resetError }] =
    useAsyncCallback(async (): Promise<void> => {
      popover.handleClose();

      switch (auth.issuer) {
        case Issuer.Firebase:
          await auth.signOut();
          break;
      }

      // reset notifications after successful logout
      await notifications.handleLogout?.();
      // this should force login
      router.refresh();
    }, [auth, router, popover, notifications]);

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32
          }}
          src={user?.avatar}
        >
          <Icon icon={icons.user} />
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onLogout={handleLogout}
        onClose={popover.handleClose}
        open={popover.open}
      />
      <SimpleSnackbar
        open={!!error}
        onClose={resetError}
        severity="error"
        disableClickAway
      >
        Abmeldung fehlgeschlagen
      </SimpleSnackbar>
    </>
  );
};
