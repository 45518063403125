import { CompanySummary as ApiCompany } from 'src/api/deinverband-api';
import { Company as DataCompany } from 'src/types/company';

export const mapCompanySummaryToCompany = (
  company: ApiCompany | undefined
): DataCompany | undefined => {
  if (company?.id === undefined) return undefined;

  return {
    id: company.id,
    name: company.name || 'Kein Name',
    address:
      company.city && company.postalCode
        ? {
            type: 'address',
            locality: company.city,
            postalCode: company.postalCode
          }
        : undefined
  };
};
