import { FC } from 'react';
import { Icon } from '@iconify/react';
import { IconButton, Stack, Typography } from '@mui/material';
import { useGetApiAssociationInfoQuery } from 'src/api/deinverband-api';

interface DetailsToastProps {
  title?: string;
  secondary?: string;
  icon?: string;
  onLink?: () => void;
}

export const DetailsToast: FC<DetailsToastProps> = (props) => {
  const {
    title,
    secondary,
    icon = 'fa6-solid:up-right-from-square',
    onLink
  } = props;

  const { data } = useGetApiAssociationInfoQuery();

  return (
    <div>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
      >
        <Stack>
          <Typography
            variant="h6"
            noWrap
          >
            {title ?? data?.name ?? 'innung.app'}
          </Typography>
          {secondary && (
            <Typography
              variant="body2"
              color="text.secondary"
              noWrap
            >
              {secondary}
            </Typography>
          )}
        </Stack>
        {onLink && (
          <IconButton onClick={onLink}>
            <Icon icon={icon} />
          </IconButton>
        )}
      </Stack>
    </div>
  );
};
