import { useContext } from 'react';
import { Capacitor } from '@capacitor/core';
import type { NotificationsContextType as CapacitorNotificationsContextType } from 'src/contexts/notifications/capacitor-context';
import { NotificationsContext as CapacitorNotificationsContext } from 'src/contexts/notifications/capacitor-context';
import type { NotificationsContextType as FirebaseNotificationsContextType } from 'src/contexts/notifications/firebase-context';
import { NotificationsContext as FirebaseNotificationsContext } from 'src/contexts/notifications/firebase-context';

type NotificationContextType =
  | CapacitorNotificationsContextType
  | FirebaseNotificationsContextType;

// select the correct provider
export const useNotifications =
  Capacitor.getPlatform() === 'web'
    ? <T = NotificationContextType>() =>
        useContext(FirebaseNotificationsContext) as T
    : <T = NotificationContextType>() =>
        useContext(CapacitorNotificationsContext) as T;
