import { useCallback, useEffect, useState } from 'react';
import { Preferences } from '@capacitor/preferences';
import { usePostApiUsersMeCloudMessageTokenMutation } from 'src/api/deinverband-api';
import { AuthContextType } from 'src/contexts/auth/firebase-context';
import { useAuth } from './use-auth';

export interface CloudTokenState {
  initialized: boolean;
  requested: boolean;
  failed: boolean;
  token?: string;
  uid?: string;
  date?: number;
}

export const useCloudToken = ({
  onInitialize,
  onRegister,
  onUnregister
}: {
  onInitialize?: () => Promise<void>;
  onRegister: () => Promise<string | undefined>;
  onUnregister: () => Promise<void>;
}) => {
  const auth = useAuth<AuthContextType>();

  const [sendToken] = usePostApiUsersMeCloudMessageTokenMutation();

  const [state, setState] = useState<CloudTokenState>({
    initialized: false,
    requested: false,
    failed: false
  });

  const handleRequest = useCallback(async () => {
    if (!auth.user?.uid || !state.initialized) {
      console.error('Notifications requested before initialization');
      return;
    }

    const uid = auth.user.uid;
    // platform specific permission request and registration
    // notifications must be registered immediately to work on iOS
    // (as this method is called directly from button click)
    return onRegister()
      .then(async (token) => {
        console.log('Notification token', token);

        if (token === undefined) {
          console.log('Permission not granted');
          return;
        }

        if (state.token === token && state.uid === uid) {
          console.log('Token unchanged');
          return;
        }

        // notify backend about new token
        await sendToken({ setCloudMessageToken: { token: token } }).unwrap();

        const date = Date.now();
        // update internal state
        setState((state) => ({ ...state, token, uid, date }));
        // update browser storage
        Preferences.set({
          key: 'notifications.token',
          value: JSON.stringify({ token, uid, date })
        });
      })
      .catch((error) => {
        console.log(error);
        // request failed
        setState((state) => ({
          ...state,
          failed: true
        }));
      });
  }, [sendToken, onRegister, auth, state]);

  const handleLogout = useCallback(async () => {
    // platform specific unregistration
    return (
      onUnregister()
        .then(() => console.log('Notification token removed'))
        .catch(console.log)
        // reset state even when token removal fails
        // this will simply resolve when the next token is requested
        .then(() => {
          // update internal state
          setState((state) => ({
            ...state,
            token: undefined,
            uid: undefined,
            date: undefined
          }));
          // reset browser storage
          Preferences.remove({ key: 'notifications.token' });
        })
    );
  }, [onUnregister]);

  useEffect(
    () => {
      Preferences.get({ key: 'notifications.token' })
        .then((storage) => JSON.parse(storage.value || '{}'))
        .catch(() => undefined)
        .then(async (storage) => {
          // framework specific initialization
          if (onInitialize) await onInitialize();
          // initialization done
          setState({
            initialized: true,
            requested: false,
            failed: false,
            token: storage?.token,
            uid: storage?.uid,
            date: storage?.date
          });
        })
        .catch((error) => {
          console.log(error);
          // initialization failed
          setState((state) => ({
            ...state,
            failed: true
          }));
        });
    },
    // initialization is done only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (!state.initialized || state.failed || !auth.user?.uid) return;

      // request notifications exactly once
      if (!state.requested) {
        setState((state) => ({ ...state, requested: true }));
        // register notifications and request the notification token
        // this needs to be done everytime the page reloads
        handleRequest();
      }
    },
    // trigger only when login could have changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.initialized, state.failed, auth.user?.uid]
  );

  return { state, handleRequest, handleLogout };
};
