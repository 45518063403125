import React, { ReactElement } from 'react';
import {
  Box,
  CircularProgress,
  Skeleton,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { BoxProps } from '@mui/system';
import { Error } from './error';

type LoaderProps<T> = {
  requires?: T | null;
  requiresMapped?: T[];
  error?: boolean;
  children?: (required: T) => ReactElement;
  tableSkeleton?: boolean;
} & Omit<BoxProps, 'children'>;

export function Loader<T>(props: LoaderProps<T>) {
  const {
    requires,
    requiresMapped,
    error,
    children,
    tableSkeleton,
    minHeight = 200,
    ...other
  } = props;

  if (error) return <Error />;

  // render multiple elements
  if (requiresMapped !== undefined)
    return <>{children && requiresMapped.map(children)}</>;

  // render single element
  if (requires !== undefined && requires !== null)
    return children?.(requires) || null;

  return (
    <Stack>
      {tableSkeleton && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Skeleton animation="wave" />
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight={minHeight}
        {...other}
      >
        <CircularProgress />
      </Box>
    </Stack>
  );
}
