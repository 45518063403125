import { PaletteColor } from '@mui/material';
import { createApi } from '@reduxjs/toolkit/query/react';
import { appConfig } from 'src/config';
import { capacitorBaseQuery } from './capacitor-adapter';

/// Provides information about current tenant
export interface TenantData {
  tenantId: string;
}

/// Actual tenant response from server
export interface TenantResponse {
  name?: string;
}

/// Provides style for current tenant
export interface TenantStyle {
  appName?: string;
  palette?: {
    primaryColor: PaletteColor;
  };
  strings?: {
    group: string;
    groups: string;
  };
}

/// Provides API keys for the current tenant
export interface TenantKeys {
  apiKey: string;
  appId: string;
  authDomain: string;
  messagingSenderId: string;
  projectId: string;
  storageBucket: string;
  vapidKey: string;
}

export const tenantApi = createApi({
  reducerPath: 'tenantApi',
  baseQuery: capacitorBaseQuery({
    baseUrl: appConfig.baseUrl || ''
  }),
  endpoints: (build) => ({
    getTenant: build.query<TenantData | null, void>({
      query: () => ({ url: '/t/tenant.json' }),
      // avoid problems with file format on remote
      extraOptions: { responseType: 'json' },
      transformResponse: (response: TenantResponse) => {
        if (!response.name?.length) throw new Error('Invalid tenant response');

        return {
          tenantId: response.name
        };
      }
    }),
    getStyle: build.query<TenantStyle, void>({
      query: () => ({ url: '/t/style.json' }),
      // avoid problems with file format on remote
      extraOptions: { responseType: 'json' },
      transformResponse: (response: TenantStyle) => {
        return {
          appName: response.appName,
          palette: response.palette,
          strings: {
            // TODO: improve fallback mechanism
            group: response.strings?.group || 'Innung',
            groups: response.strings?.groups || 'Innungen'
          }
        };
      }
    }),
    getKeys: build.query<TenantKeys, void>({
      query: () => ({ url: '/static/keys.json' }),
      // avoid problems with file format on remote
      extraOptions: { responseType: 'json' }
    })
  })
});

export const { useGetTenantQuery, useGetStyleQuery, useGetKeysQuery } =
  tenantApi;
