import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Link,
  Stack
} from '@mui/material';
import { StackProps } from '@mui/system';
import { paths } from 'src/paths';

export type CustomLayoutProps = {
  // inject properties
  boxProps?: BoxProps;
  stackProps?: StackProps;
  // non-scrolling view
  disableScroll?: boolean;
  disableFooter?: boolean;
  disableSpacing?: boolean;
} & ContainerProps;

export const CustomLayout: FC<CustomLayoutProps> = (props) => {
  const {
    boxProps: { sx: boxSx, ...boxProps } = { sx: {} },
    stackProps: { sx: stackSx, ...stackProps } = { sx: {} },
    disableScroll,
    disableFooter,
    disableSpacing,
    children,
    sx: containerSx,
    ...containerProps
  } = props;

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pt: disableFooter ? 1 : 2,
        pb: disableFooter ? 2 : 8,
        overflow: disableScroll ? 'hidden' : undefined,
        ...boxSx
      }}
      {...boxProps}
    >
      <Container
        sx={{
          height: disableScroll ? '100%' : undefined,
          ...containerSx
        }}
        {...containerProps}
      >
        <Stack
          spacing={disableSpacing ? undefined : 4}
          sx={{
            height: disableScroll ? '100%' : undefined,
            ...stackSx
          }}
          {...stackProps}
        >
          {children}
          {!disableFooter && (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Link
                href={paths.privacy}
                fontSize="0.7em"
                color="text.secondary"
              >
                Impressum & Datenschutz
              </Link>
              <Link
                href={paths.terms}
                fontSize="0.7em"
                color="text.secondary"
              >
                Nutzungsbedingungen
              </Link>
            </Stack>
          )}
        </Stack>
      </Container>
    </Box>
  );
};
