import type { FC } from 'react';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { RouterLink } from 'src/components/router-link';
import { useMe } from 'src/hooks/use-me';
import { paths } from 'src/paths';
import { icons } from 'src/theme/icons';
import { getFullName } from 'src/utils/get-full-name';

interface AccountPopoverProps {
  anchorEl: null | Element;
  onLogout?: () => void;
  onClose?: () => void;
  open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onLogout, onClose, open, ...other } = props;

  const me = useMe();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 300 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">{getFullName(me)}</Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {me?.email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        <ListItemButton
          component={RouterLink}
          href={paths.company.user}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <Icon icon="fa6-solid:building" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Mein Betrieb</Typography>}
          />
        </ListItemButton>
        <ListItemButton
          component={RouterLink}
          href={paths.account}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <Icon icon={icons.settings} />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Mein Profil</Typography>}
          />
        </ListItemButton>
      </Box>
      <Divider sx={{ my: '0 !important' }} />
      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center'
        }}
      >
        <Button
          color="inherit"
          onClick={onLogout}
          size="small"
        >
          Logout
        </Button>
      </Box>
    </Popover>
  );
};
