import { useContext } from 'react';
import type { AuthContextType as FirebaseAuthContextType } from 'src/contexts/auth/firebase-context';
import { AuthContext } from 'src/contexts/auth/firebase-context';

type AuthContextType =
  | FirebaseAuthContextType
  | {
      issuer: never;
      user: null;
      isAuthenticated: false;
    };

export const useAuth = <T = AuthContextType>() => useContext(AuthContext) as T;
