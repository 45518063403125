import { AccessAttributeValueWithAttribute } from 'src/api/deinverband-api';
import { CategoryOrId } from 'src/types/company';
import { Signature } from 'src/types/email';
import { Attendance } from 'src/types/occasion';
import { RolesGroupsMatrix, TargetingEditorState } from 'src/types/targeting';
import { GroupOrAll, GroupRole, MemberOrId } from 'src/types/user';
import { AnyAttributeValue, AttributeName, AttributeTypes } from './attributes';

// check if the attribute is of given type
const of = (value: AnyAttributeValue, name: AttributeName) =>
  ('attribute' in value && value.attribute?.name === name) ||
  // (attribute.attributeName === undefined || attribute.attributeName === name) &&
  value.name === name;

// check if the attributes are of given type
const only = (values: AnyAttributeValue[], names: AttributeName[]) =>
  values.every((value) => names.some((name) => of(value, name)));

export const extractAttributeValues = <T extends keyof AttributeTypes>(
  name: T,
  values: AnyAttributeValue[]
): AttributeTypes[T][] =>
  values
    // attribute values without populated attribute are not filtered
    .filter((value) => of(value, name))
    .map((value) => JSON.parse(value.value) as AttributeTypes[T]);

export const extractUserGroupRoles = (
  values: AnyAttributeValue[]
): GroupRole[] =>
  extractAttributeValues('GroupRoles', values)
    .filter((value) => value.groupId !== null && value.roleId !== null)
    .map((value) => ({
      group: { id: value.groupId as number },
      role: { id: value.roleId as number }
    }));

export const extractUserCategories = (
  companyId: number,
  values: AnyAttributeValue[]
): CategoryOrId[] =>
  extractAttributeValues('CompanyCategoryAccess', values)
    .filter(
      (value) => value.companyId === companyId && value.categoryId !== null
    )
    .map((value) => ({ id: value.categoryId as number }));

export const extractCompanyAdmin = (
  companyId: number | undefined,
  values: AnyAttributeValue[]
): boolean =>
  extractAttributeValues('CompanyAdmin', values).some(
    (value) => value.companyId === companyId
  );

export const extractTenantAdmin = (
  values: AccessAttributeValueWithAttribute[]
): boolean => values.some((value) => value.attribute.name === 'Admin');

export const extractTargetingRolesGroupsMatrix = (
  values: AnyAttributeValue[]
): RolesGroupsMatrix[] => {
  const mapped = extractAttributeValues('GroupRoles', values);

  // select unique groups
  const groups = new Set(mapped.map((value) => value.groupId));
  // join by group
  const grouped = [...groups].map((group) => ({
    group,
    roles: [
      ...new Set(
        mapped
          .filter((value) => value.groupId === group)
          .map((value) => value.roleId)
      )
    ].sort()
  }));

  // select unique role sets
  const sets = new Map(
    grouped.map((value) => [
      // simple hash to identify distinct sets
      value.roles.join('.'),
      value.roles
    ])
  );

  // join by role sets
  return [...sets].map(([hash, roles]) => ({
    groups: grouped
      .filter((value) => value.roles.join('.') === hash)
      .map(({ group }) => ({ id: group })),
    roles: [...roles].map((role) => ({ id: role }))
  }));
};

export const extractTargetingLocations = (
  attributeName: 'GroupRoles' | 'CompanyGroupCategory',
  values: AnyAttributeValue[]
): string[] =>
  [
    ...new Set(
      extractAttributeValues(attributeName, values)
        .map((value) => value.location)
        // null means no location filter
        .filter((value) => value !== null)
    )
  ] as string[];

export const extractTargetingMembers = (
  values: AnyAttributeValue[]
): MemberOrId[] =>
  [
    ...new Set(
      extractAttributeValues('CustomAccess', values).map(
        (value) => value.userId
      )
    )
  ].map((id) => ({ id }));

export const extractTargetingCategories = (
  values: AnyAttributeValue[]
): CategoryOrId[] =>
  [
    ...new Set(
      extractAttributeValues('CompanyGroupCategory', values).map(
        (value) => value.categoryId
      )
    )
  ].map((id) => ({ id }));

export const extractTargetingGroups = (
  values: AnyAttributeValue[]
): GroupOrAll[] =>
  [
    ...new Set(
      extractAttributeValues('CompanyGroupCategory', values).map(
        (value) => value.groupId
      )
    )
  ].map((id) => ({ id }));

export const extractTargetingAttendances = (
  values: AnyAttributeValue[]
): Attendance[] => [
  ...new Set(
    extractAttributeValues('AttendeeStatus', values)
      .flatMap((value) => value.statuses as Attendance[])
  )
];

export const extractTargetingAttendanceEventId = (
  values: AnyAttributeValue[]
): number | undefined => {
  const eventIds = [...new Set(extractAttributeValues('AttendeeStatus', values).map(
    (value) => value.eventId
  ))]
  return eventIds.length === 1 ? eventIds[0] : undefined;
}

export const extractTargetingEditorState = (
  signature: Signature | undefined,
  values: AnyAttributeValue[]
): TargetingEditorState | undefined => {
  // The targeting editor state is recognized by the attributes
  // being of a specific type. There must be no mixed state (yet),
  // but some tags can be ignored.
  const allowed = ['Creator'] as const;

  if (only(values, ['GroupRoles', 'CustomAccess', ...allowed])) {
    const targets = extractTargetingRolesGroupsMatrix(values);
    const locations = extractTargetingLocations('GroupRoles', values);
    const members = extractTargetingMembers(values);
    return {
      method: 'roles',
      targets: targets.length ? targets : [{ groups: [], roles: [] }],
      locations: locations.length ? locations : null,
      members: members.length ? members : null,
      signature
    };
  }

  if (only(values, ['CompanyGroupCategory', 'CustomAccess', ...allowed])) {
    const locations = extractTargetingLocations('CompanyGroupCategory', values);
    const members = extractTargetingMembers(values);
    return {
      method: 'companies',
      categories: extractTargetingCategories(values),
      groups: extractTargetingGroups(values),
      locations: locations.length ? locations : null,
      members: members.length ? members : null,
      signature
    };
  }

  if (only(values, ['AttendeeStatus', ...allowed])) {
    const eventId = extractTargetingAttendanceEventId(values);
    if (eventId === undefined) {
      console.error(`Invalid targeting state: missing eventId`);
      return undefined;
    }
    return {
      method: 'attendees',
      attendances: extractTargetingAttendances(values),
      eventId
    };
  }

  console.error('Invalid targeting state');
  // reset targeting state on post
  return undefined;
};
