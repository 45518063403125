const gitDirty: boolean = import.meta.env.VITE_IS_DIRTY;
const gitShortCommitHash: string = import.meta.env.VITE_SHORT_COMMIT_SHA || 'unknown';
const appVersion: string = import.meta.env.VITE_APP_VERSION || 'unknown';

interface VersionData {
  version: string;
  commitHash: string;
}

export default {
  version: appVersion + (gitDirty ? '-dirty' : ''),
  commitHash: gitShortCommitHash
} as VersionData;
