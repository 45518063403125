import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/guards/guest-guard';
import { IssuerGuard } from 'src/guards/issuer-guard';
import { Layout as AuthLayout } from 'src/layouts/auth/classic-layout';
import { Issuer } from 'src/utils/auth';

// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const FirebaseStartPage = lazy(() => import('src/pages/auth/firebase/start'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const FirebaseLoginPage = lazy(() => import('src/pages/auth/firebase/login'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const FirebaseActionsPage = lazy(() => import('src/pages/auth/firebase/actions'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const FirebaseRecoverPage = lazy(() => import('src/pages/auth/firebase/recover'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const MagicLoginPage = lazy(() => import('src/pages/auth/firebase/magic'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const UnsubscribePage = lazy(() => import('src/pages/auth/unsubscribe'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const AttendancePage = lazy(() => import('src/pages/auth/attendance'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const SurveyPage = lazy(() => import('src/pages/auth/survey'));

export const authRoutes: RouteObject[] = [
  {
    path: 'auth',
    element: (
      <IssuerGuard issuer={Issuer.Firebase}>
        <GuestGuard>
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        </GuestGuard>
      </IssuerGuard>
    ),
    children: [
      {
        path: 'start',
        element: <FirebaseStartPage />
      },
      {
        path: 'create',
        element: <FirebaseActionsPage mode="create" />
      },
      {
        path: 'reset',
        element: <FirebaseActionsPage mode="reset" />
      },
      {
        path: 'login',
        element: <FirebaseLoginPage />
      },
      {
        path: 'recover',
        element: <FirebaseRecoverPage />
      },
      {
        path: 'redirect',
        element: <MagicLoginPage />
      }
    ]
  },
  {
    path: 'unsubscribe',
    element: (
      <AuthLayout>
        <UnsubscribePage />
      </AuthLayout>
    )
  },
  {
    path: 'attendance',
    element: (
      <AuthLayout>
        <AttendancePage />
      </AuthLayout>
    )
  },
  {
    path: 'survey',
    element: (
      <AuthLayout>
        <SurveyPage />
      </AuthLayout>
    )
  }
];
