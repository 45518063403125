import type { FC } from 'react';
import { Icon } from '@iconify/react';
import { Stack, Typography } from '@mui/material';

interface ErrorProps {
  message?: string;
  fullPage?: boolean;
}

export const Error: FC<ErrorProps> = (props) => {
  const { message = 'Es ist ein Fehler aufgetreten', fullPage } = props;

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      minHeight={200}
    >
      <Icon
        icon="fa6-solid:bug"
        style={{ fontSize: fullPage ? '96px' : '24px', opacity: 0.8 }}
      />
      <Typography variant={fullPage ? 'body1' : 'body2'}>{message}</Typography>
    </Stack>
  );
};
